export const boundaries = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: 0,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.7759612017706825, 50.636566501073354],
            [9.7759651200337316, 50.636606935494655],
            [9.7759821779012004, 50.63678320599638],
            [9.7760587565909827, 50.636943998454257],
            [9.7762917779980878, 50.637515737259363],
            [9.7761475522203689, 50.637520259332256],
            [9.7758798235860258, 50.637528787243944],
            [9.775593578853961, 50.637537887447863],
            [9.7756143498803141, 50.637649409038879],
            [9.7755594928243017, 50.637948322156205],
            [9.7754604447573055, 50.63804544265146],
            [9.7755061725214869, 50.638093171122847],
            [9.7753942263676663, 50.638110337729429],
            [9.7753282029383062, 50.638118331360587],
            [9.7752554055171732, 50.638127179412919],
            [9.7751840189795001, 50.638135829176072],
            [9.7744326313584171, 50.638227322201672],
            [9.7742540323523297, 50.638061891650786],
            [9.7741437844832983, 50.637645293719444],
            [9.7742156239103934, 50.637067730358886],
            [9.7741432478805255, 50.636447031333816],
            [9.7740512062565568, 50.636307771191127],
            [9.773709677756127, 50.636067150455823],
            [9.773609539283294, 50.636046150656526],
            [9.7734791545336712, 50.636120014222854],
            [9.7732742089944882, 50.636095123256013],
            [9.7731367939142153, 50.636188719257973],
            [9.7718549576674807, 50.635651751324694],
            [9.7713414192085253, 50.635600230308711],
            [9.7704314566745989, 50.635267700538037],
            [9.7695187532413765, 50.63485311860326],
            [9.768830402061182, 50.634689633109559],
            [9.7677650721383102, 50.634413658724561],
            [9.7667110504262222, 50.634387863631858],
            [9.766568689579751, 50.634290900050267],
            [9.7664273491290139, 50.634194909844048],
            [9.7659135223235793, 50.633965058189979],
            [9.7658405912427142, 50.633924492483409],
            [9.7652350805320047, 50.633564658789822],
            [9.7644626948357214, 50.633268392823716],
            [9.7641121365270571, 50.633312429779693],
            [9.7637076660653559, 50.633605508659059],
            [9.7633980720563471, 50.634052180338458],
            [9.7620805703495233, 50.635146826015351],
            [9.7614173423270252, 50.635115602599939],
            [9.7612984518033201, 50.635106307027662],
            [9.7605429821620611, 50.635047775301928],
            [9.7604326027473025, 50.635039232683894],
            [9.7598799954926747, 50.635043647128803],
            [9.7592782662277866, 50.635042992112481],
            [9.7591378958620432, 50.635059995576448],
            [9.7584583608857329, 50.635142634775917],
            [9.7582563435772602, 50.635167143071499],
            [9.757785654013226, 50.635184846049121],
            [9.7571963095691068, 50.635207023446647],
            [9.7571237756755451, 50.635214599414013],
            [9.756446158281916, 50.635285793016791],
            [9.7563580978696418, 50.635311896405256],
            [9.7562978766610016, 50.635329815073504],
            [9.7558128196409015, 50.635473733202979],
            [9.7555653917089025, 50.635528146033238],
            [9.7554784803879002, 50.635349879433747],
            [9.755445552635182, 50.635282347010929],
            [9.755327480794616, 50.635020564447181],
            [9.7550708668647061, 50.634479432930995],
            [9.7552469305934917, 50.633859900154434],
            [9.7555741441150712, 50.63318184838198],
            [9.7556562161960976, 50.633011766964493],
            [9.7558615678979397, 50.63258639565381],
            [9.7559705771902774, 50.631612441915912],
            [9.7560797523492582, 50.630997289510034],
            [9.7561076614970901, 50.630312238362812],
            [9.7561145674491847, 50.630302031203925],
            [9.7561478354293261, 50.630252550426171],
            [9.7565702397316336, 50.629625660574419],
            [9.7565264206770888, 50.629599118296682],
            [9.756287780414187, 50.62945460850564],
            [9.7558403496810762, 50.629366509357475],
            [9.755513412260246, 50.629302142796654],
            [9.7552054759224429, 50.629223487833798],
            [9.7550566934400678, 50.629190106939028],
            [9.7545433067836438, 50.629134545018275],
            [9.754423666900621, 50.629121569079167],
            [9.7538282481359175, 50.629193375584705],
            [9.753354287131998, 50.629250463301545],
            [9.7531180600637803, 50.629282828183484],
            [9.75237443089698, 50.629384714179565],
            [9.7517121864740002, 50.629396362977246],
            [9.7516125805124769, 50.629407248825835],
            [9.7510167820837843, 50.629472577263797],
            [9.7507882073101424, 50.629452925240457],
            [9.7502583259140785, 50.62940734327583],
            [9.7499173009520614, 50.629381299919601],
            [9.7498558763688585, 50.629376578093286],
            [9.749721023940598, 50.629366294269261],
            [9.7494112853026795, 50.629342665147853],
            [9.7484310041966218, 50.629344109609811],
            [9.7475456448338385, 50.629403006112305],
            [9.7468592228864512, 50.629448668900523],
            [9.7464868303279495, 50.629771178034339],
            [9.7461878063462741, 50.630103819124969],
            [9.746058798462224, 50.630177013680857],
            [9.7458047171152877, 50.630321029944909],
            [9.745085187169348, 50.630580941800041],
            [9.7446540990533848, 50.630865248366938],
            [9.7439929360022894, 50.631322181553863],
            [9.743894726021221, 50.631386948235715],
            [9.7433289287314651, 50.63176006344915],
            [9.7426984451172398, 50.632136672875461],
            [9.7421204241030281, 50.632523997383885],
            [9.7417935769530395, 50.632806073413605],
            [9.741768340986944, 50.632827898725296],
            [9.7417551919898724, 50.632836966653571],
            [9.7415649263534903, 50.63296841745511],
            [9.7415571317480385, 50.632976291159096],
            [9.7409100009274958, 50.632738391396025],
            [9.7407398754668399, 50.633026815259285],
            [9.7392994340738053, 50.6324545880953],
            [9.7379286991492471, 50.631856450903058],
            [9.7374879530196807, 50.631566745999756],
            [9.7370425605460404, 50.631277050830164],
            [9.7368928926431959, 50.631178478526898],
            [9.736180077832131, 50.63063472189296],
            [9.7359179981924235, 50.630415234244637],
            [9.7353825056973182, 50.62984840916878],
            [9.7350633243858606, 50.629158215178521],
            [9.7348903052191087, 50.62863036138576],
            [9.734909409367102, 50.628505370037288],
            [9.7347719719173611, 50.628148351631062],
            [9.7345665296858925, 50.627898385418021],
            [9.7343682534367328, 50.62721066460599],
            [9.7341617949869832, 50.626495574609052],
            [9.733933402431111, 50.625704305843648],
            [9.7336711753554077, 50.624800600461953],
            [9.7337461745667966, 50.624443510827703],
            [9.7332917905727054, 50.623440775875125],
            [9.7331776560509962, 50.622933205937812],
            [9.7329354938030139, 50.622363425746698],
            [9.7336431324300552, 50.622340625906723],
            [9.7344669822149719, 50.622684867341242],
            [9.7346639848450245, 50.622539868554618],
            [9.7358315460027409, 50.622276184645052],
            [9.7367153874935948, 50.622344038098191],
            [9.737053971196957, 50.622370486187876],
            [9.7381565130295922, 50.622000215748606],
            [9.7388556159888289, 50.621402518994451],
            [9.7389763635144728, 50.618539809272619],
            [9.7390644265209687, 50.61851615605319],
            [9.7391277283611064, 50.61849912599083],
            [9.7386846578041126, 50.61802640059247],
            [9.7378470581302157, 50.617338563345179],
            [9.737277460349711, 50.615761994209727],
            [9.7374412754068462, 50.614579875091366],
            [9.738832315468839, 50.613604108447106],
            [9.7392416986842907, 50.613016779790335],
            [9.739470613351056, 50.612569468926274],
            [9.7398502842506627, 50.612059614542282],
            [9.740702848440197, 50.61105929222294],
            [9.7406491221075253, 50.61071155009661],
            [9.7414164294845254, 50.610699016249278],
            [9.7420105376109127, 50.610486554480048],
            [9.7421940811768799, 50.610038771523818],
            [9.7427575803135813, 50.610026546771017],
            [9.742898751626722, 50.609598997807936],
            [9.7436847494621581, 50.609202569730108],
            [9.7441263639572959, 50.60894273166646],
            [9.7440478046272823, 50.608762990640408],
            [9.7448553025441011, 50.608513313058481],
            [9.7450986807176569, 50.608493428492508],
            [9.7452109108451985, 50.608400475833953],
            [9.7455933546918949, 50.608276853734935],
            [9.7461933753381107, 50.608217919416603],
            [9.7462926496152598, 50.608251349964256],
            [9.7465897609573471, 50.608669188494055],
            [9.7467727860791022, 50.608926589491197],
            [9.7483083170560612, 50.608920378839542],
            [9.7485289577627601, 50.608927325135689],
            [9.7487563600529334, 50.608969373197731],
            [9.7498335008303325, 50.609303505581934],
            [9.7502538990841057, 50.608911382068946],
            [9.7507621901044672, 50.609336726168259],
            [9.7511758914131406, 50.609401391938079],
            [9.7512646848299607, 50.609415289529203],
            [9.7513886150240463, 50.609434626189596],
            [9.7513965227252886, 50.609637382084642],
            [9.7515414250155654, 50.609811824884005],
            [9.7517528067405408, 50.610009041141971],
            [9.752180363165472, 50.610163051865058],
            [9.7525095166606182, 50.610594670910672],
            [9.7531516023866196, 50.61054971938534],
            [9.753456983901577, 50.610545316601751],
            [9.7536549369358596, 50.610486056692345],
            [9.7537384178573383, 50.610405242829572],
            [9.7536867095998883, 50.610264077486207],
            [9.7534304221372796, 50.610051246078172],
            [9.7532598878776593, 50.609909508948142],
            [9.7529989536531492, 50.609767456571539],
            [9.7523408193121597, 50.609595063003724],
            [9.7529314729398084, 50.609258649599596],
            [9.7524364947721267, 50.608696234658431],
            [9.7520011685814083, 50.608329755358703],
            [9.7519751304176658, 50.607974761705393],
            [9.7525400341514015, 50.608333119636079],
            [9.7526243581503991, 50.60838587794597],
            [9.7535047520587881, 50.608658771160123],
            [9.7541263208170133, 50.608339940308291],
            [9.7548117043322069, 50.607992840538778],
            [9.7555771777240174, 50.60758463868838],
            [9.7550241027967921, 50.606994921087818],
            [9.7546959349605089, 50.606775831243368],
            [9.7544362219425818, 50.606255085888876],
            [9.7547430507740955, 50.606097317202142],
            [9.754801465555806, 50.606092442111759],
            [9.7548557881958029, 50.606087953237825],
            [9.7549628649436961, 50.606078985578719],
            [9.7549955935884274, 50.606076255363853],
            [9.7551451235420661, 50.606063783819721],
            [9.755382298042889, 50.606044088809078],
            [9.7559615989856638, 50.606566985551275],
            [9.7565866172289919, 50.607131311228251],
            [9.7572548401217372, 50.606807304154515],
            [9.7577533232036657, 50.607189657975759],
            [9.7582573267171089, 50.607562377916125],
            [9.7584774601624726, 50.6080558080887],
            [9.7586330454768291, 50.608040774546005],
            [9.758667182738531, 50.608037683392986],
            [9.7587442142993233, 50.608032055565744],
            [9.7587154527765581, 50.607894879820741],
            [9.7588862113294184, 50.607778527970119],
            [9.7588918855944371, 50.607551429153347],
            [9.7588461376377342, 50.607159522026691],
            [9.7588607667143812, 50.606769559831569],
            [9.7586211848469002, 50.606525433260337],
            [9.7583711759761229, 50.606414060668335],
            [9.758059633706079, 50.606271710463069],
            [9.7577140876511059, 50.605990976424422],
            [9.7582468786816801, 50.605733198379127],
            [9.7584843568719641, 50.605618301370264],
            [9.7589616581534688, 50.605493199187322],
            [9.7591098910725105, 50.605381850053163],
            [9.7591869384776544, 50.60532390799758],
            [9.7599682003009764, 50.604941978274105],
            [9.7602562527900041, 50.604828267550026],
            [9.7604989614003266, 50.604732469933175],
            [9.7611734455268859, 50.604467458030037],
            [9.7613005016894228, 50.604417461603553],
            [9.7613572051718336, 50.604395156457052],
            [9.7618622714809806, 50.604196682643462],
            [9.7618579069518656, 50.604075167174969],
            [9.762875988547842, 50.603629786109103],
            [9.762904205980254, 50.603617370477878],
            [9.763388808675769, 50.60340399624446],
            [9.7641088404468661, 50.603063655864801],
            [9.7646734985765349, 50.602820603930965],
            [9.7650796914600146, 50.602807136061216],
            [9.7653746930586358, 50.602801052375355],
            [9.7655319550298696, 50.602751203581676],
            [9.7656332631679899, 50.60267383419496],
            [9.7656639404563403, 50.602630213058461],
            [9.7656756650409271, 50.602613408513214],
            [9.7657488420890406, 50.602508443697985],
            [9.765996084120049, 50.602298129382419],
            [9.7664345675057582, 50.601882247314315],
            [9.7670186407961452, 50.601278264014418],
            [9.7674736702817189, 50.600768765899986],
            [9.7676164305042956, 50.600608914118936],
            [9.7682268409304811, 50.600024068574143],
            [9.7682818128017281, 50.599990529845698],
            [9.768322513820328, 50.599965718759947],
            [9.7684486602859817, 50.599888830559024],
            [9.7691421768270157, 50.599849179912027],
            [9.7701366662827862, 50.599654560129686],
            [9.7704341063534592, 50.59941307538886],
            [9.7710673091377558, 50.599239515502916],
            [9.7715740457729545, 50.599100649560555],
            [9.7719731876121454, 50.598650454636207],
            [9.7719977106560982, 50.598622421948207],
            [9.7730410531415242, 50.598030238244043],
            [9.7741181218697566, 50.597734176602415],
            [9.7741568218984956, 50.597726365065384],
            [9.7749477193392291, 50.597564146598813],
            [9.7757202208211069, 50.597433242947808],
            [9.7757393647191453, 50.597429707011216],
            [9.7755084388409905, 50.598071566313358],
            [9.775422403163617, 50.598094793118548],
            [9.7752032869607515, 50.598537371932807],
            [9.7749397176560624, 50.598904468632341],
            [9.7748110090046172, 50.599261683512395],
            [9.7747097386985704, 50.599673089123435],
            [9.7746302682124586, 50.600113667745454],
            [9.7750326812478452, 50.60035686741417],
            [9.7759263532625553, 50.600206180521411],
            [9.7758417186665536, 50.600616504986689],
            [9.776477343733708, 50.60055859812212],
            [9.7768115920473875, 50.600494612887019],
            [9.7767814030704692, 50.60030881518842],
            [9.776741029241558, 50.60012920081698],
            [9.7766948784488967, 50.599934255428202],
            [9.7766950151577081, 50.599830957701258],
            [9.7766720794156292, 50.599708046571756],
            [9.7765882104928945, 50.599307559350052],
            [9.7765097540894974, 50.598762765741256],
            [9.7765053410778542, 50.598735014909991],
            [9.7765032661392972, 50.598327255344017],
            [9.7764610236410867, 50.598146394217657],
            [9.7764518910488487, 50.598088565328865],
            [9.7764416519637205, 50.59802445750919],
            [9.7764392915830101, 50.598009814190604],
            [9.7764879839044774, 50.598009039971323],
            [9.776949126924686, 50.598000839075326],
            [9.7770091980518465, 50.598003765924439],
            [9.7771299509618164, 50.597978058025333],
            [9.7781325254652636, 50.597667149572978],
            [9.7780144785103822, 50.597386385289781],
            [9.7778802705555101, 50.596948849932147],
            [9.7778002471314025, 50.596687697658233],
            [9.7779821596649228, 50.596661850283496],
            [9.7780517674666747, 50.596652841763749],
            [9.7783793178795584, 50.596962414787889],
            [9.7788361566498168, 50.597556191696036],
            [9.7794669813836812, 50.597979423220167],
            [9.7795074124627366, 50.598032824599898],
            [9.7798708677113275, 50.598513151429579],
            [9.7801672267121749, 50.598712265842963],
            [9.7807680746464403, 50.599318731337888],
            [9.7809842386973465, 50.599596325906951],
            [9.7806939251144964, 50.60007833323936],
            [9.7806467782167203, 50.600422057633928],
            [9.7808397340179116, 50.600611071249652],
            [9.7816747164636073, 50.600916351205278],
            [9.7821384195616723, 50.601019161680959],
            [9.782308384230495, 50.601056772459614],
            [9.7832218112761993, 50.600907551689232],
            [9.7838183835253059, 50.600796591115291],
            [9.7842969800611641, 50.600972291264455],
            [9.7848095433930808, 50.601158624506802],
            [9.7845274053864753, 50.60163678209387],
            [9.7838418398101314, 50.602218837527602],
            [9.783350345869561, 50.602821158867805],
            [9.7829719434487306, 50.603455651388217],
            [9.7826976840908788, 50.604138078570962],
            [9.7823844114883443, 50.604798238875127],
            [9.7821422639301741, 50.60539834030449],
            [9.7821590899448179, 50.605474751103969],
            [9.782169790410876, 50.605523494751139],
            [9.7823251681689651, 50.605847162568232],
            [9.782439295236939, 50.606084699859416],
            [9.7825523436435535, 50.606320184817974],
            [9.7827212761506708, 50.606930700361659],
            [9.7825103741103376, 50.607588176304539],
            [9.781918644479596, 50.608145019656774],
            [9.7810577070727263, 50.608594891515409],
            [9.7812112790851113, 50.609082448092728],
            [9.7813055458186628, 50.609374250021851],
            [9.7813159568130779, 50.609406367104974],
            [9.7813606159316198, 50.609544500953362],
            [9.7813052387784918, 50.60976529732806],
            [9.7811982436425247, 50.610190748830114],
            [9.7816958596835715, 50.610414751781093],
            [9.7824664352840571, 50.610945172812656],
            [9.7833095246117665, 50.611344788794767],
            [9.7843094387461704, 50.611543548635773],
            [9.784674845244103, 50.611521851497443],
            [9.785398639503903, 50.611478860827425],
            [9.7863368806723265, 50.611190682918995],
            [9.7870998029449776, 50.610725795187385],
            [9.7875873623984955, 50.610168550778432],
            [9.7884253194356177, 50.609949004945584],
            [9.7887524987433832, 50.609863269784924],
            [9.7897286458385189, 50.609562226134763],
            [9.7907499080949254, 50.609265436631688],
            [9.7915003996482248, 50.608980550528251],
            [9.7920703768353849, 50.608606450641481],
            [9.7928380771559489, 50.608194543747956],
            [9.7937821327238908, 50.60802161364375],
            [9.7938476053991295, 50.60831111069276],
            [9.7940752956564872, 50.608689578742855],
            [9.7943831762408351, 50.609178584475082],
            [9.7944486790254857, 50.609737249587937],
            [9.7944707250362608, 50.61028594965039],
            [9.7944411745996121, 50.610897019109849],
            [9.7943945275478299, 50.611293598674621],
            [9.7944193041256664, 50.611375853047115],
            [9.794443133296598, 50.611485768178682],
            [9.7944864070671631, 50.611686094941071],
            [9.795183526236416, 50.611728706399653],
            [9.7960497723840252, 50.611779709033151],
            [9.7960577303277905, 50.61151008138048],
            [9.7973390035446712, 50.611467801469381],
            [9.7982639917258805, 50.611544960705956],
            [9.7993691771839533, 50.611521356787193],
            [9.7999914397854138, 50.611667767786727],
            [9.7999136038374903, 50.611714374773435],
            [9.7997664682634031, 50.61180258368929],
            [9.799411494641264, 50.612015111791322],
            [9.7992124169748767, 50.612455448182658],
            [9.7988792514283727, 50.612762659047249],
            [9.7991777614406974, 50.613094495861361],
            [9.7988995923173654, 50.613395294985288],
            [9.7984887278412636, 50.613737121929489],
            [9.7988974146035659, 50.613817471927867],
            [9.7993978605324834, 50.61391014122578],
            [9.7994521944644628, 50.613913536576959],
            [9.7994130425841632, 50.614118716844885],
            [9.7991529604579082, 50.614467848127561],
            [9.7994675328597367, 50.614534074720176],
            [9.7997497535489941, 50.61459623276042],
            [9.8001449909629557, 50.614607269669676],
            [9.800330304805879, 50.614486970900302],
            [9.8004077155438125, 50.614519174593305],
            [9.8011251613642596, 50.614817010742051],
            [9.8015070100624779, 50.614522489894163],
            [9.8024462668953412, 50.615009826733832],
            [9.8029846933513571, 50.615347087739089],
            [9.8035221366246503, 50.615271103394861],
            [9.8038930495481278, 50.614882976260169],
            [9.8042900318737782, 50.614946399843596],
            [9.8043998339025844, 50.614963979047801],
            [9.8045183878175273, 50.614980868184318],
            [9.8050838057869765, 50.615086725185229],
            [9.8057111106001198, 50.615200964875136],
            [9.805851344369934, 50.615221749694634],
            [9.8062860733465893, 50.615286056527452],
            [9.8069610993364602, 50.615364543730848],
            [9.8072658614449235, 50.615400006302274],
            [9.807344664937931, 50.615409163981418],
            [9.8075138154068, 50.61542884708718],
            [9.808081380036775, 50.61551903538362],
            [9.8087489894349051, 50.615625424828927],
            [9.8099938077087234, 50.615790365056363],
            [9.8101692402445231, 50.615827537511308],
            [9.811148883243952, 50.616035390378663],
            [9.8120606298630904, 50.616606101088728],
            [9.8126139220864026, 50.617159511191382],
            [9.8126325783943358, 50.617560498833242],
            [9.8125675245532111, 50.617607969371875],
            [9.8120357656362582, 50.617995896252552],
            [9.8112686009174297, 50.618477065498922],
            [9.8101080763601498, 50.619000427861515],
            [9.8093410308291009, 50.619387675758944],
            [9.8085722304556349, 50.619829951515037],
            [9.8078147362059873, 50.620219254366589],
            [9.8077605467892539, 50.620245386779594],
            [9.8075202562972166, 50.620368362586184],
            [9.8066871131992688, 50.620911292590513],
            [9.8058339949980979, 50.621613337541284],
            [9.8050566522707179, 50.621954276849372],
            [9.8036287991571047, 50.622865888090779],
            [9.8031219274853267, 50.623178076618338],
            [9.8030716309212309, 50.623209009368232],
            [9.8024894382772665, 50.623567480918744],
            [9.8015612347395997, 50.62429757547212],
            [9.8010054503406145, 50.624835525000513],
            [9.8009272214351153, 50.624886011307595],
            [9.7999536897866175, 50.625372309546044],
            [9.7992553075419178, 50.625721031266757],
            [9.7986160990635351, 50.625997253292311],
            [9.7985080644504929, 50.626052025182609],
            [9.7984436191462816, 50.626085193264345],
            [9.7977011664797775, 50.626465180321645],
            [9.7973475874657368, 50.626654345321505],
            [9.7969373173930574, 50.626874006398587],
            [9.796694299048756, 50.627063429360291],
            [9.7964367351270383, 50.627269040150118],
            [9.7959553591176896, 50.627653202567224],
            [9.7954445978520663, 50.628060990971477],
            [9.7948904085131794, 50.628478066614548],
            [9.794581466876668, 50.628728396378669],
            [9.7941615400647173, 50.629232722547187],
            [9.7939071464845071, 50.629580911850795],
            [9.7933934876766493, 50.63004648336846],
            [9.792991725312751, 50.630422536785908],
            [9.7925403013977927, 50.630749985372098],
            [9.7926915234179024, 50.630841623021226],
            [9.7928358738249202, 50.630929035442655],
            [9.7916570481170293, 50.632168548747451],
            [9.790908638492585, 50.633109161595605],
            [9.7905209911386137, 50.633835450826645],
            [9.7903736536557719, 50.63411153536822],
            [9.7900479956963515, 50.63504356322634],
            [9.7894892959852413, 50.635038707534108],
            [9.787937476977266, 50.635476398993426],
            [9.7859816179027028, 50.63575122143385],
            [9.785125849349555, 50.636006912575709],
            [9.7841266842176058, 50.636305344998334],
            [9.7828690729238854, 50.636349135570967],
            [9.7814499119145353, 50.636404113476061],
            [9.7806669319685504, 50.636462439858775],
            [9.7798102303884562, 50.636517261376532],
            [9.7790422113460913, 50.636473133803399],
            [9.7787753680425382, 50.636457830418202],
            [9.777807840255937, 50.636392742739702],
            [9.7777164288282865, 50.636386580991555],
            [9.7773534868399405, 50.636364658178572],
            [9.7761282631169255, 50.636290663428511],
            [9.7760157711794715, 50.636283858346488],
            [9.7759612017706825, 50.636566501073354],
          ],
        ],
      },
      properties: {
        FID: 0,
        OBJECTID: 4,
        gemarkungs: "Dammersbach",
        FIRST_geme: "Hünfeld",
        shape_Leng: 21888.574385,
        shape_Area: 13847153.622500001,
      },
    },
    {
      type: "Feature",
      id: 1,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.7838502184479452, 50.696992131906221],
            [9.7838010148934398, 50.696995556385218],
            [9.7837598021175953, 50.696998423503338],
            [9.7834545102494168, 50.69701964005818],
            [9.7827010524664271, 50.697072014289994],
            [9.7826741593225659, 50.697081961398659],
            [9.7819777008769293, 50.697312857649592],
            [9.7812847567654124, 50.697595463657258],
            [9.7811774328414831, 50.697646958131571],
            [9.7810581541205668, 50.697704189286107],
            [9.7809736151865323, 50.697744757132881],
            [9.780625399044439, 50.697935693430985],
            [9.7804141920425334, 50.698055088470504],
            [9.7800470221379232, 50.698248380203417],
            [9.7799282390956144, 50.698310912820325],
            [9.779545455618079, 50.698512419220485],
            [9.7794017128064361, 50.698588095289246],
            [9.7793853170184608, 50.698596721403348],
            [9.779070876335858, 50.698779261420917],
            [9.7788601758493705, 50.698890376545776],
            [9.7786709723975456, 50.69898201246658],
            [9.7783544518182559, 50.699126478516895],
            [9.7773365483358869, 50.69955087541706],
            [9.776070011341579, 50.70007892787725],
            [9.776046814680452, 50.700142294991231],
            [9.7759710568857194, 50.700060790961935],
            [9.7759014652126588, 50.699988238969908],
            [9.7755253723590609, 50.699596204064186],
            [9.7752903804107731, 50.699351238517316],
            [9.7752095671275789, 50.69926697973996],
            [9.7749774888830068, 50.69902505184362],
            [9.7748278830959041, 50.698869206153262],
            [9.7747811314915491, 50.698820504333888],
            [9.7746916048811716, 50.698727238030621],
            [9.7745598943401397, 50.69859001474935],
            [9.7741852546182706, 50.698199728473966],
            [9.774073731118758, 50.698083540397207],
            [9.7739106033079537, 50.697913592147479],
            [9.7738465644881547, 50.697846811613445],
            [9.7734659233106438, 50.697449907925673],
            [9.773296328830348, 50.69727305901732],
            [9.7729776008261755, 50.696940700377255],
            [9.7727809262572762, 50.696735602985967],
            [9.7726755163360757, 50.696625686207625],
            [9.7726422536581747, 50.696549698883956],
            [9.7725996496263079, 50.696452387795262],
            [9.774254185726031, 50.695772069535067],
            [9.774701821708085, 50.695587991822578],
            [9.7748293389953851, 50.695534795279997],
            [9.7753469837355933, 50.695318782740308],
            [9.7760782600009648, 50.694920475889617],
            [9.7763531449836645, 50.694770753396028],
            [9.7764463070654113, 50.694720005170346],
            [9.7767057043138905, 50.69450023853333],
            [9.7770172375736681, 50.694236300243709],
            [9.7780537620277634, 50.693140992041613],
            [9.7780902192741532, 50.693102464907852],
            [9.7782062326935026, 50.69312561222182],
            [9.7783803894768191, 50.693139063290964],
            [9.7786689686478496, 50.693040198935648],
            [9.778858250847815, 50.692974417622601],
            [9.7792393920216565, 50.692820731706512],
            [9.779265976198209, 50.692811893601004],
            [9.7793042613169359, 50.692799164174183],
            [9.7800581167788643, 50.692548456357819],
            [9.7804429192781335, 50.692420471353678],
            [9.7804690180989038, 50.692411420386328],
            [9.7807474042955, 50.6923148404044],
            [9.7807766087404584, 50.692253221643846],
            [9.7805798095812015, 50.692012471253285],
            [9.7804544737729397, 50.691859150042028],
            [9.7800566793613015, 50.691372531820178],
            [9.7798116911329505, 50.691141797185018],
            [9.7794036560604098, 50.690757497245222],
            [9.7791976351849641, 50.690585702616609],
            [9.778926771483345, 50.690359851478419],
            [9.7783769838869716, 50.689901402310518],
            [9.7779035476281813, 50.689506616110613],
            [9.777864946835253, 50.689474435434477],
            [9.7791452450004286, 50.689053675007578],
            [9.7803006011049476, 50.688673954225962],
            [9.7803666386714276, 50.688653205622586],
            [9.7816241978750131, 50.688298831547613],
            [9.7829995493907518, 50.687913786412608],
            [9.7843825416391912, 50.687529006304906],
            [9.7843471101468289, 50.687492642603466],
            [9.7839193505252577, 50.687076942973675],
            [9.7837253222651341, 50.686890848422713],
            [9.7835951563360908, 50.68677538813035],
            [9.7833620255301632, 50.686568565814476],
            [9.7832241835711322, 50.686452796965476],
            [9.7831587730591174, 50.686397856633661],
            [9.783022263919138, 50.686281305178881],
            [9.7829662447246051, 50.686233487179351],
            [9.7825371229694191, 50.685892758501382],
            [9.7823924340204744, 50.685777870993753],
            [9.7820820964323882, 50.685547435678131],
            [9.7819931175930446, 50.685481348426947],
            [9.7816476567727104, 50.685234339378127],
            [9.7818074557646639, 50.685142787584482],
            [9.7819186153562327, 50.685123668955036],
            [9.7824632818333104, 50.685019344641006],
            [9.7826376496035916, 50.68498594255437],
            [9.7828805676151003, 50.684939417456974],
            [9.7828491451602719, 50.684777140205362],
            [9.7828112538898502, 50.684676122780964],
            [9.7827577737378615, 50.684566378751825],
            [9.7826680394486072, 50.684441212059873],
            [9.7826375563789298, 50.684399157907286],
            [9.782519987412849, 50.684264303504051],
            [9.782348063803008, 50.684111692608163],
            [9.7821603423109931, 50.683952181798809],
            [9.7820004590483638, 50.683787150984728],
            [9.782058437602652, 50.683745294562925],
            [9.7819559393263162, 50.683509453255887],
            [9.7819084220010915, 50.683212332404658],
            [9.7820324692137142, 50.682766365984627],
            [9.7823232959218203, 50.682290205258035],
            [9.7825238753659658, 50.682217201374812],
            [9.782703142355972, 50.682118152184962],
            [9.7831297078343074, 50.681822577719551],
            [9.7832190289749903, 50.681783702685252],
            [9.7832639846124714, 50.6817641372552],
            [9.7833136055576055, 50.68174252598827],
            [9.7833649691825517, 50.681720165549045],
            [9.7844428605156963, 50.681174334072416],
            [9.7844672131056534, 50.681161993439652],
            [9.7845187198109347, 50.681149982650908],
            [9.7846175875873875, 50.681126915206633],
            [9.7847075257942215, 50.681089545718464],
            [9.7850715225881064, 50.680938311794826],
            [9.7855630846461104, 50.680734074710927],
            [9.7855858738445285, 50.680722230009664],
            [9.7857119580228495, 50.680766894545712],
            [9.785922749917539, 50.680746704705825],
            [9.7860313430170827, 50.680781414492522],
            [9.786154419870412, 50.68077397447091],
            [9.7862646683010013, 50.680773896322791],
            [9.7863072309409915, 50.680776433056089],
            [9.7864432967282671, 50.680675547100549],
            [9.7865415850049722, 50.680602686794238],
            [9.7868299743451779, 50.680388879484539],
            [9.7865752912713599, 50.68030744766908],
            [9.7866286791135515, 50.680248874642842],
            [9.7866364069029075, 50.680240377910529],
            [9.7866688648763436, 50.680213404195804],
            [9.7869439463878738, 50.679982680328862],
            [9.7872046065170544, 50.67976406806234],
            [9.7874096962738939, 50.679592055477507],
            [9.7875892976561865, 50.679441465597861],
            [9.7881979826841476, 50.678931107896972],
            [9.7882143135319222, 50.678917417783303],
            [9.7883178123365191, 50.67887293011438],
            [9.7885961405982904, 50.678753272869109],
            [9.7891490535851897, 50.678456407467252],
            [9.7902076148570281, 50.677888026125061],
            [9.7903868065013953, 50.67760525306069],
            [9.7905942752499033, 50.677277861654098],
            [9.790874691540969, 50.676835320992495],
            [9.7910249214064677, 50.676598239835442],
            [9.7910296463993731, 50.676514751156994],
            [9.7910085405531433, 50.676471610269978],
            [9.7908913609318944, 50.676232152929103],
            [9.7909393292827094, 50.676075714991669],
            [9.7912901207845486, 50.675970020467027],
            [9.791347181659475, 50.675905790551525],
            [9.7913022143525108, 50.675688452125129],
            [9.7913294566744202, 50.675543549065367],
            [9.7914251891537116, 50.675559644350884],
            [9.7914852828616254, 50.675434267183775],
            [9.7922566462246916, 50.675688146799892],
            [9.7933641352989262, 50.676053416548683],
            [9.7934253010399157, 50.676074161123296],
            [9.793741241208739, 50.675591648731334],
            [9.7938373655198134, 50.675434584833333],
            [9.7940701916974717, 50.675475455621289],
            [9.7947208530657761, 50.675589047339834],
            [9.7949825073148737, 50.675560193525428],
            [9.79543702906291, 50.675508205605624],
            [9.7965916105898785, 50.675297308454141],
            [9.7969493804501102, 50.675272154963864],
            [9.7980381461125923, 50.675196060467947],
            [9.7981080031698244, 50.675192632380451],
            [9.7990193914065049, 50.675145109399757],
            [9.7991215332299024, 50.675140146373423],
            [9.799397678653671, 50.675125680275357],
            [9.7998590560493994, 50.675101201140805],
            [9.8000959132474765, 50.675105079280392],
            [9.8009041922568407, 50.675118134864647],
            [9.800974292508748, 50.675112383147848],
            [9.8018026232197251, 50.675045498250448],
            [9.8018728819746936, 50.675039924765748],
            [9.8020474769673616, 50.674999567161521],
            [9.802061034120225, 50.674969598537388],
            [9.8026863122662533, 50.674639759021808],
            [9.8027311195522362, 50.674604898646052],
            [9.8035062592077811, 50.673997895914312],
            [9.8035508791668082, 50.67396291060377],
            [9.8035968824884172, 50.673926890515567],
            [9.8036804954581758, 50.67386141922308],
            [9.8049840451332795, 50.673801459092488],
            [9.8060389569272068, 50.67375445459686],
            [9.8061089666669972, 50.673762576887022],
            [9.8075907289862077, 50.673953154950858],
            [9.8079957660512314, 50.674005466970783],
            [9.808099436618507, 50.674018849543991],
            [9.8082028522196669, 50.674032215806719],
            [9.8106353270147526, 50.674166642439332],
            [9.8114973123534597, 50.674214756065915],
            [9.8124038368715922, 50.674170174914366],
            [9.813168005936264, 50.674132542458935],
            [9.8133298500089836, 50.674123265370248],
            [9.8134998592202543, 50.674170435233926],
            [9.8145862450191217, 50.674470036728856],
            [9.8163119370259722, 50.67495148208652],
            [9.8170224520946388, 50.675032271649251],
            [9.8175475242979608, 50.675091856845135],
            [9.8183161376396821, 50.67513337144922],
            [9.8183588796344239, 50.675135832260509],
            [9.8191058999903014, 50.675136853070875],
            [9.8203297306551551, 50.675118389403579],
            [9.8208864278670465, 50.675109946356045],
            [9.8211756199457998, 50.675124114942676],
            [9.8218479367111851, 50.675178353529894],
            [9.8223095852529472, 50.675164332585759],
            [9.8227224384102989, 50.675137587157813],
            [9.8229525350720017, 50.675122976580802],
            [9.8230228917599121, 50.675117470587466],
            [9.8238262323237322, 50.675179468904574],
            [9.8251811708478165, 50.674989660596545],
            [9.8253254445352667, 50.675001751139241],
            [9.8264806399316953, 50.674751846502829],
            [9.8274145284915235, 50.67451141565526],
            [9.8277356911225642, 50.674454843392873],
            [9.8279072703490495, 50.67439728242163],
            [9.8284613755971417, 50.674273501915366],
            [9.8284297366748543, 50.674187832851892],
            [9.8291722387975504, 50.674058536880651],
            [9.8295578094687208, 50.674070497108332],
            [9.8298769879658892, 50.674165774069252],
            [9.8301912293743055, 50.674133534910752],
            [9.8303792309251907, 50.67402918764779],
            [9.830408430189971, 50.673536044846927],
            [9.8310876251290029, 50.673336096473982],
            [9.8317262543591042, 50.673200240308844],
            [9.832170822715586, 50.67307630639305],
            [9.8326580500693161, 50.672954341298855],
            [9.8331138446707111, 50.672698159596735],
            [9.833519412071988, 50.672755424351848],
            [9.8340237112472533, 50.672833744760801],
            [9.8337431706087841, 50.673180362097888],
            [9.8333759120296964, 50.673550837224155],
            [9.8328631015974928, 50.673618120647816],
            [9.8322355599868541, 50.673696041663092],
            [9.8320147984997703, 50.673908328260588],
            [9.8317986844575049, 50.674117829344105],
            [9.8315124438759351, 50.674350655049686],
            [9.8312295540470895, 50.674569022070074],
            [9.8309853763321602, 50.675029038175438],
            [9.8310407294713631, 50.675415422577075],
            [9.831049536929676, 50.67569297050747],
            [9.8310561081669441, 50.675899982811686],
            [9.8307601340642599, 50.676244481419886],
            [9.8308527707028812, 50.676431481934991],
            [9.8308775788097709, 50.676481837806264],
            [9.8310261462494477, 50.676774397177972],
            [9.831261598430137, 50.677031407193944],
            [9.8315144517970499, 50.677295046471755],
            [9.8316844703399973, 50.677565283741878],
            [9.8318471560080258, 50.67783934123495],
            [9.8320196602367833, 50.678067427189205],
            [9.8322811558890546, 50.678373396960495],
            [9.8324615685223442, 50.678595445311196],
            [9.8327577677934261, 50.678949756309855],
            [9.8326786939076491, 50.678942820922821],
            [9.8325896018249281, 50.67898004163159],
            [9.8321742812790234, 50.67958196259918],
            [9.8320954026200251, 50.679677367838465],
            [9.8318499654520988, 50.67997425798967],
            [9.8317836548232389, 50.680054464766997],
            [9.831684310218634, 50.680189271002035],
            [9.8315129476674059, 50.680421816220374],
            [9.8315573131562513, 50.680644773416375],
            [9.8316364302019714, 50.68104240000077],
            [9.8316919031591858, 50.681357952591561],
            [9.8318042315249805, 50.681664394018327],
            [9.8319292329484682, 50.68200542257037],
            [9.8319628320526125, 50.682079601310598],
            [9.8320366587336778, 50.682242570325201],
            [9.8319158150308503, 50.682462587643073],
            [9.8316316855559887, 50.682873714853073],
            [9.8315365882495875, 50.683257997281487],
            [9.8316397802727042, 50.683595116726643],
            [9.8316488365942032, 50.683864802428772],
            [9.8318249513243359, 50.684226222528139],
            [9.8318649020539581, 50.684296875585503],
            [9.8320624514085448, 50.684646262348842],
            [9.8324271609000959, 50.684955127956286],
            [9.8326029172841736, 50.685130957258629],
            [9.8327625743587461, 50.685293690341325],
            [9.8330412619696101, 50.685603051643341],
            [9.832670961483748, 50.685803394431836],
            [9.8322808221975411, 50.685949163219817],
            [9.8318731596644717, 50.686116801243379],
            [9.8313353116642936, 50.686325988552255],
            [9.8308862092580132, 50.686583517609591],
            [9.8303956710570422, 50.686813254436245],
            [9.8302424377783346, 50.687044004564825],
            [9.8300857773886072, 50.687282791799483],
            [9.8299975282215222, 50.68736157086132],
            [9.8296510500163023, 50.687505446055781],
            [9.8294931851559877, 50.687571014612608],
            [9.8291600131028769, 50.687706969588199],
            [9.8291101720483844, 50.687727306934924],
            [9.8296433731680732, 50.688047024371961],
            [9.8299502006953627, 50.688275353032886],
            [9.8300494723709004, 50.688338155923553],
            [9.830061512769964, 50.688345777329388],
            [9.8302690622814861, 50.688477083308484],
            [9.8302974378680243, 50.688490209066551],
            [9.8305039187891072, 50.688585774395243],
            [9.8306130545485164, 50.688636284810073],
            [9.8306850505572037, 50.688669604060884],
            [9.8311024062327181, 50.688811122317574],
            [9.8314654714589498, 50.688975023640594],
            [9.8315444600615631, 50.6890202354097],
            [9.8318725713778221, 50.68941930434039],
            [9.8318688489746968, 50.68948820058224],
            [9.831334394523525, 50.68979304176159],
            [9.8317664297030483, 50.689856131458519],
            [9.8324572063067457, 50.689944072751629],
            [9.833200960198754, 50.690043879985659],
            [9.8332924782656193, 50.690056940369125],
            [9.8339782370392168, 50.690140214095514],
            [9.8344146301391788, 50.690193199454868],
            [9.8351442375578539, 50.690285190572538],
            [9.8356250644515288, 50.690332879826656],
            [9.8361011743848916, 50.690321434880119],
            [9.8369222358808948, 50.690276089684339],
            [9.8377774938966791, 50.690222444044032],
            [9.8382983686195526, 50.690149074161972],
            [9.838592577139794, 50.690096866001539],
            [9.8391390213273002, 50.68999990817332],
            [9.8392235138340318, 50.689984910822901],
            [9.8398198872197256, 50.689890270280031],
            [9.8402121115436785, 50.68982583155536],
            [9.8403686697563355, 50.690029063779676],
            [9.840633537424651, 50.690447971362232],
            [9.8407567919852195, 50.690523182634109],
            [9.8407024299168437, 50.690609675325945],
            [9.8405342348622735, 50.690877256950557],
            [9.8402189715468271, 50.691126032573386],
            [9.8399106687571827, 50.691321589561305],
            [9.8391608426161881, 50.691671356316071],
            [9.8385655423441403, 50.691873579931411],
            [9.8380004761589106, 50.691999393034173],
            [9.8380165885450577, 50.692086834669283],
            [9.837523371717916, 50.692182514395874],
            [9.8374470687561111, 50.692197316765096],
            [9.8369528819183873, 50.692217134694502],
            [9.8368775527681489, 50.692213475722568],
            [9.8365886674282947, 50.692199658441396],
            [9.8358880269146329, 50.692231367442503],
            [9.8352602116281975, 50.692349019706157],
            [9.8343924422003646, 50.692291122153769],
            [9.8337569521582129, 50.692127838825009],
            [9.8328682132985268, 50.692148671179105],
            [9.8327065535840816, 50.692152461110247],
            [9.831311338051842, 50.69240870629266],
            [9.8302649851623389, 50.692574704172053],
            [9.8303940911300085, 50.692657187119728],
            [9.8304168125859679, 50.692671486261851],
            [9.830466877271645, 50.692702839486252],
            [9.8303345161460225, 50.692770403733377],
            [9.8303044767843719, 50.692814756142361],
            [9.8303190159866904, 50.692931770524282],
            [9.8306555107303577, 50.693218530303731],
            [9.8308326267646144, 50.693273574243726],
            [9.830956845663863, 50.693352548091589],
            [9.8311650689835428, 50.693652766160689],
            [9.831011306497663, 50.693634239344945],
            [9.830861040824642, 50.693615687402641],
            [9.8308022216164321, 50.693631430975039],
            [9.8306925701231993, 50.693711909812158],
            [9.8304471683404273, 50.693756402821627],
            [9.8304995679902465, 50.693793234215498],
            [9.830872870722235, 50.694065710761734],
            [9.8309591065699298, 50.694142410263069],
            [9.8311840136009323, 50.694273662762036],
            [9.8315280549965731, 50.69445948091635],
            [9.8317983786630752, 50.694605507765843],
            [9.8319647168610533, 50.695365115007171],
            [9.8320197377871494, 50.695616359875267],
            [9.8323437239814506, 50.696540270279826],
            [9.8314930488156538, 50.696624500688827],
            [9.8313308475897845, 50.696205272430085],
            [9.8304867322047205, 50.696365656735985],
            [9.8302851404444436, 50.695381298852176],
            [9.8295242593627279, 50.695148154324386],
            [9.829515474067934, 50.695427112644758],
            [9.8294959301018139, 50.695836242515476],
            [9.8294552920757354, 50.696226304074436],
            [9.8294514768844454, 50.696262870414458],
            [9.8294283555372246, 50.696441126397154],
            [9.8294091870226215, 50.696589020331373],
            [9.8294036271938179, 50.69663184033049],
            [9.8294088360478398, 50.69678828417355],
            [9.8294165254696964, 50.697018733021707],
            [9.8294302984753763, 50.697143729809063],
            [9.8294646046824141, 50.697455062541394],
            [9.829504133048605, 50.697604067632277],
            [9.8295335149135745, 50.697714870046802],
            [9.8295419886488222, 50.697781844716211],
            [9.8295766467231545, 50.698080143801647],
            [9.8295850028487557, 50.698150878439129],
            [9.8295881665729858, 50.698177709480277],
            [9.8295892311689439, 50.698186686064197],
            [9.8295687151028055, 50.698185968721923],
            [9.829545889532378, 50.698185168886049],
            [9.8295307716494431, 50.698175006284615],
            [9.829522653944057, 50.698169955938745],
            [9.8294561314410611, 50.698083276639714],
            [9.8293943900920837, 50.698057321029403],
            [9.8292403561785253, 50.698041042078678],
            [9.8290492452137759, 50.698002687624545],
            [9.8289391746727794, 50.697960537065107],
            [9.8287466118841795, 50.697903243865419],
            [9.8286507814889692, 50.697850361888975],
            [9.8284354347755283, 50.697825920244448],
            [9.8281717259761212, 50.697776964717796],
            [9.8280882138182832, 50.69770871539874],
            [9.8279155020763831, 50.697664931418899],
            [9.8277993624075766, 50.697685721002479],
            [9.8277342268718488, 50.697680832595481],
            [9.8276089008138658, 50.697670436010306],
            [9.8275373849120218, 50.697661842645907],
            [9.8274155558538414, 50.697627274412035],
            [9.8271503501091146, 50.697590494939242],
            [9.8270721336688993, 50.697548710140104],
            [9.8270582676709797, 50.697529176464215],
            [9.8268990391914404, 50.697463747723937],
            [9.8268363624748591, 50.69742875927718],
            [9.8267100569492829, 50.697407702794813],
            [9.8266476635494122, 50.697383180267806],
            [9.8264792055021744, 50.697259019168314],
            [9.8263347710983737, 50.697232020431265],
            [9.8262610152296528, 50.697233694358083],
            [9.8260460080279941, 50.697315401078356],
            [9.8257970590707799, 50.697373695855298],
            [9.825687965164807, 50.697406268412266],
            [9.8255648536527733, 50.697455001910519],
            [9.8254532626686721, 50.697467950885809],
            [9.8252768752205899, 50.697449315897948],
            [9.8251147581927967, 50.697490249202232],
            [9.8249990273872641, 50.697506140825546],
            [9.8249168470986206, 50.697533701854127],
            [9.8248607662996044, 50.697551770236338],
            [9.8247708653109775, 50.697591238766748],
            [9.8247213715054684, 50.697618352553732],
            [9.8246501048758716, 50.697642508714821],
            [9.8245521409018881, 50.69769698972484],
            [9.8245188787635414, 50.69772034638595],
            [9.824480749484124, 50.6977536119417],
            [9.8244109553583563, 50.697801202686769],
            [9.8240654362073148, 50.697866975483613],
            [9.8239535744559863, 50.697889601563794],
            [9.8238533949103566, 50.697909833735551],
            [9.8237806328122979, 50.698032960563879],
            [9.8235605598874329, 50.698299651601275],
            [9.8234836463519635, 50.698378237509338],
            [9.8234503326712268, 50.698427008866936],
            [9.8233433983689746, 50.698491768429491],
            [9.8232928774695925, 50.698567074153061],
            [9.822829092003543, 50.698647680782514],
            [9.8226787630913694, 50.698673185156508],
            [9.8224551352107135, 50.698861829618068],
            [9.8221720885286175, 50.699070641679057],
            [9.8219049312875963, 50.699344505363356],
            [9.8217004751025776, 50.699436201934077],
            [9.8215623461990607, 50.699476124809586],
            [9.8214166241092347, 50.699506954993588],
            [9.8211548622753853, 50.699488519604287],
            [9.8209457849199495, 50.699433254401725],
            [9.8204976752659849, 50.699362044911084],
            [9.8203662066076252, 50.699222119493378],
            [9.8201610753036874, 50.698905061564162],
            [9.8199584844632373, 50.69864501994077],
            [9.8199168748934902, 50.698520334825588],
            [9.8198231848592936, 50.698458959017152],
            [9.8198123886581641, 50.698422046123071],
            [9.819686088780891, 50.698184399994794],
            [9.8196065209412957, 50.69811349988953],
            [9.8195948528881569, 50.698078544616891],
            [9.8195229270601878, 50.697996007548767],
            [9.8194866189227721, 50.697950361761094],
            [9.8193914993797282, 50.697923052708653],
            [9.8193221391831909, 50.697868268326651],
            [9.8192454266236169, 50.697813274772763],
            [9.8190587443989958, 50.697720554212495],
            [9.8189970116181513, 50.697681723850806],
            [9.8189324917703438, 50.697664163847641],
            [9.8188948195522929, 50.697588112626555],
            [9.8185505702047884, 50.697487217087811],
            [9.8184013645424919, 50.697441311453517],
            [9.818301789496541, 50.697410111780101],
            [9.8180296677597418, 50.697365167362292],
            [9.8177313091377769, 50.697387819006018],
            [9.8175999008408557, 50.697375170071211],
            [9.8173673900584184, 50.697387583376823],
            [9.8172395571674738, 50.697381860670554],
            [9.8172089666744942, 50.697391302094154],
            [9.8172429668624446, 50.697454213629221],
            [9.8172802886770025, 50.697477675901865],
            [9.8172803688035408, 50.697547462174832],
            [9.8171139019034488, 50.697657095505782],
            [9.8170191645218914, 50.697708714705833],
            [9.8167651098249031, 50.697747726646185],
            [9.8166883311555466, 50.697720538693204],
            [9.8162751518731799, 50.697694420726087],
            [9.8162222263228678, 50.697676661127034],
            [9.8161184331215914, 50.69764222461383],
            [9.8158016314313166, 50.697591157690212],
            [9.8155246481162948, 50.697527077052634],
            [9.8154262438456144, 50.697480821199925],
            [9.8151903031901355, 50.697395093941658],
            [9.8148354449586712, 50.697315521438362],
            [9.8147383169099882, 50.697313439421308],
            [9.8146779991979969, 50.697310281700126],
            [9.8145642578755528, 50.697326760586719],
            [9.8144985729180281, 50.697347265276235],
            [9.8144038034965408, 50.697377541832417],
            [9.8143157207064355, 50.697390028121617],
            [9.8142339918563568, 50.697395761075263],
            [9.8141470285654293, 50.697369047279977],
            [9.8140783226195829, 50.697312393637546],
            [9.8132794253599371, 50.696942227258113],
            [9.8130945754824399, 50.696872704691515],
            [9.8130126983875847, 50.69686162963162],
            [9.812545251619321, 50.696798393488045],
            [9.8124161377869328, 50.696762655297633],
            [9.8122494108259399, 50.696572451941051],
            [9.8121199282524501, 50.696257047068521],
            [9.8121513146756829, 50.696108387494043],
            [9.8121820316827808, 50.696053791914814],
            [9.8122595326851823, 50.696005885005597],
            [9.8124010567164053, 50.695997884136553],
            [9.812590457294764, 50.695917217471511],
            [9.8126190179917501, 50.695877988110794],
            [9.8126309403632934, 50.695805905845965],
            [9.8125972168606879, 50.69572826025744],
            [9.8125389626258741, 50.695627241582585],
            [9.8124454354862696, 50.695533420423125],
            [9.8123329766408336, 50.69534688583731],
            [9.8122219106542499, 50.695143389346157],
            [9.8120084359407276, 50.694883373739671],
            [9.8118884324009183, 50.694787794033516],
            [9.8118429341860551, 50.694723468143472],
            [9.8117907833720164, 50.694683038399575],
            [9.8112624278693819, 50.694586559928744],
            [9.8110490223254914, 50.694579681283777],
            [9.8107701306927471, 50.694608816652405],
            [9.8104298122199314, 50.694566127456724],
            [9.8102117007159642, 50.694552355270602],
            [9.8100662113374124, 50.694477050518643],
            [9.8097078571108867, 50.694353815992336],
            [9.8093339476177537, 50.694258738001466],
            [9.809241230095699, 50.694232006543515],
            [9.8091456499122227, 50.694185366061483],
            [9.8090648273487382, 50.694166951498907],
            [9.8088396546484145, 50.694170564551825],
            [9.808605362168926, 50.694496887667512],
            [9.808506317442772, 50.694489202040323],
            [9.8084198995882943, 50.694483829932722],
            [9.8083769652064525, 50.694378089449799],
            [9.8081479727265144, 50.694338272753967],
            [9.8081086906814274, 50.694364292466027],
            [9.8080819801469037, 50.694357274117749],
            [9.8079981714203068, 50.694333159873722],
            [9.8079647451675935, 50.694315162446586],
            [9.807878714637992, 50.694268850436906],
            [9.8077743988628061, 50.694248529371492],
            [9.807643603962994, 50.69425773632075],
            [9.8075165845774208, 50.694269138276951],
            [9.8074910163676599, 50.694271743543283],
            [9.8074534258772221, 50.694283326304898],
            [9.8073533509960047, 50.694282400689978],
            [9.8072072274829658, 50.694237475628931],
            [9.8069041012139593, 50.694174490857371],
            [9.8067105877370953, 50.694147609705183],
            [9.8065835207711309, 50.694124036616785],
            [9.8065661093325946, 50.69412424702206],
            [9.8065487351807938, 50.694134061850164],
            [9.8063894607836009, 50.694188295287269],
            [9.8062643527580846, 50.694194745417796],
            [9.806123917634185, 50.694150373204565],
            [9.805357396326615, 50.693965763132347],
            [9.8050704373047406, 50.693896636491644],
            [9.8045572788427133, 50.69379249544285],
            [9.8039536622751022, 50.693670009490702],
            [9.8039196347203994, 50.693641565014168],
            [9.8038106262779969, 50.693361406734269],
            [9.8036818107015087, 50.693310692227605],
            [9.8034428030291583, 50.6932385330337],
            [9.8031732375108334, 50.693183491021536],
            [9.8031390566350964, 50.693162601618418],
            [9.8030558145715219, 50.693210371156901],
            [9.8030050838411444, 50.693206134128133],
            [9.8029583488885113, 50.69321203181196],
            [9.8029114623176685, 50.693226465019791],
            [9.8028931480906056, 50.693256709220016],
            [9.8028982822520838, 50.693307817895565],
            [9.8029017557795193, 50.6933288289461],
            [9.8029403129420238, 50.693422775594591],
            [9.8029177184483292, 50.693482618753187],
            [9.8028655226331232, 50.69352776414047],
            [9.8027519483003793, 50.693707186220848],
            [9.8027253015016562, 50.693701344310412],
            [9.802400115075832, 50.693602868897273],
            [9.8022491610771656, 50.693557395319708],
            [9.8020610375117414, 50.693506700472099],
            [9.8019577447010171, 50.693478866883716],
            [9.8018497320446638, 50.693457369867467],
            [9.8005980469180862, 50.693205342725385],
            [9.7999691876047716, 50.693077721560691],
            [9.7995215195646015, 50.692987048298292],
            [9.7988648866131136, 50.692861859960992],
            [9.7986535984680376, 50.692821516600013],
            [9.7985835158533341, 50.692934105317867],
            [9.7985238987643015, 50.69295597137323],
            [9.7983128237846326, 50.693033292275992],
            [9.797932631000732, 50.693172564224774],
            [9.7974152278629649, 50.693375311959599],
            [9.7971587577371206, 50.693484390315739],
            [9.7969475340370593, 50.693574929670412],
            [9.796890585134264, 50.693601444085679],
            [9.7966417052196846, 50.693715757375884],
            [9.7964008648170591, 50.69384838820033],
            [9.796208923349969, 50.693954064827452],
            [9.7957996210711329, 50.694187884662497],
            [9.7957317792458785, 50.694226739517838],
            [9.7954347731479796, 50.694378187652653],
            [9.7953578056316584, 50.694421655088732],
            [9.7952435840687588, 50.694483398886611],
            [9.7943298421640392, 50.694977282032255],
            [9.7941076338090003, 50.695110617460578],
            [9.7937693782236899, 50.695386582553809],
            [9.7935992375314083, 50.695497772057912],
            [9.7933280429264435, 50.695675254042236],
            [9.7932820266920615, 50.695709705160695],
            [9.7929300803982997, 50.695980401023853],
            [9.792594093490731, 50.696268254122103],
            [9.7925635499582402, 50.69628829166264],
            [9.792229312627283, 50.69652309025301],
            [9.7920055109714497, 50.69666181033476],
            [9.791856571238899, 50.696765370916516],
            [9.7917867760759094, 50.696809722526574],
            [9.7913350996646944, 50.697097098617377],
            [9.7912970587717822, 50.697120990812664],
            [9.7912530912527895, 50.697148619402995],
            [9.7909797497732551, 50.697340976061909],
            [9.7906251541559577, 50.697578561086338],
            [9.7903336418439633, 50.697764240630505],
            [9.790180699534563, 50.697860074088119],
            [9.7900656444762593, 50.69792704340427],
            [9.7897613786142657, 50.698094695755842],
            [9.7893419099895809, 50.698325827560069],
            [9.7892350850367471, 50.698384691462046],
            [9.7890762672998513, 50.698406576705793],
            [9.7889218429736076, 50.698427856439473],
            [9.7888224051639074, 50.698397035389959],
            [9.7879805539867739, 50.697270322270171],
            [9.7868098199069653, 50.697430411869256],
            [9.7866954600517886, 50.697446058331032],
            [9.7864896825766614, 50.697474201420007],
            [9.7864723645645704, 50.69771374250211],
            [9.7859676131267932, 50.697668682754291],
            [9.7858488085023065, 50.697635318785352],
            [9.7853690685650463, 50.697500642116403],
            [9.7850427670358169, 50.697517112101245],
            [9.7845610952678985, 50.697541407771745],
            [9.7843969538524274, 50.697549688881821],
            [9.7843758228281956, 50.697552789807382],
            [9.7843065819719666, 50.697562950280428],
            [9.7842263837752252, 50.697338058921247],
            [9.7841125563287807, 50.697018830891231],
            [9.7840266244298633, 50.696979866054058],
            [9.7838502184479452, 50.696992131906221],
          ],
        ],
      },
      properties: {
        FID: 1,
        OBJECTID: 7,
        gemarkungs: "Großenbach",
        FIRST_geme: "Hünfeld",
        shape_Leng: 17421.9928674,
        shape_Area: 8485949.2492699996,
      },
    },
    {
      type: "Feature",
      id: 2,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.7685760480315658, 50.694404602668435],
            [9.7680894094937649, 50.694522985207378],
            [9.7680824415181089, 50.694581819225263],
            [9.7674284238137474, 50.694624356491865],
            [9.7662364931918439, 50.694952298803337],
            [9.7657461644678616, 50.695065623653676],
            [9.7649048648087469, 50.695258249097499],
            [9.7651634954424882, 50.695535248855421],
            [9.7655194622725592, 50.695775078783683],
            [9.7654237856635717, 50.695807893454592],
            [9.7654415594265753, 50.695822066831496],
            [9.7657007860489795, 50.696028321517957],
            [9.7656221803053125, 50.696207828054888],
            [9.7655938383289822, 50.696275084949171],
            [9.765585459045516, 50.696299619276232],
            [9.7655803786400543, 50.69631805258259],
            [9.765519299370597, 50.696540071362463],
            [9.7656979008312792, 50.696947689997309],
            [9.7656355009548985, 50.696984252220538],
            [9.7650601007910502, 50.697320802627438],
            [9.7646494290548294, 50.697560924635297],
            [9.7645996418905767, 50.697563409547335],
            [9.7645576349359473, 50.697565429727405],
            [9.7643541043441751, 50.697574966152601],
            [9.764267980541506, 50.697579002166961],
            [9.7635545399628079, 50.697214761348555],
            [9.7625148312491721, 50.69669128709883],
            [9.7624362275746677, 50.696655154234968],
            [9.7612774639306625, 50.69607124034026],
            [9.760886203813504, 50.695897916223274],
            [9.7605963712996857, 50.695807106490506],
            [9.7603337121193015, 50.695748763857317],
            [9.7600753546391221, 50.69571036632081],
            [9.7599674637324867, 50.695699027945174],
            [9.7597953486237774, 50.695680742717506],
            [9.7586321368869573, 50.69567244563941],
            [9.7578681965001977, 50.69563873529502],
            [9.7576182147916395, 50.695540014923225],
            [9.7573339689086254, 50.695389383470371],
            [9.757097234640975, 50.695243568843409],
            [9.7567148128797623, 50.694940582385634],
            [9.7566182576276663, 50.694893536559519],
            [9.7560298422778242, 50.69388388271387],
            [9.7558671463655742, 50.693782515039835],
            [9.7557667680703357, 50.693736131807455],
            [9.7557468017617541, 50.693646959487438],
            [9.755432959481471, 50.693484320579991],
            [9.7552031207851719, 50.693359609244176],
            [9.7551594671976609, 50.69333822759927],
            [9.7551328787300893, 50.693295952271896],
            [9.7546426238762258, 50.69304297578222],
            [9.7545918236084077, 50.692955576663316],
            [9.7544759399201482, 50.692948583670749],
            [9.7543665542976647, 50.692897419226966],
            [9.7542308406203624, 50.692821900767953],
            [9.7540671780858226, 50.69273306426274],
            [9.7538434623969259, 50.692614497909837],
            [9.7538255310409294, 50.692570763369304],
            [9.7535499364238998, 50.692417404764683],
            [9.7532798087422865, 50.692272895392854],
            [9.753249014876614, 50.692257851026021],
            [9.7529757521944873, 50.692124341818804],
            [9.7528743338037227, 50.692078916086302],
            [9.75270874475925, 50.692004730931494],
            [9.752208496174827, 50.691821901191155],
            [9.7517902881976237, 50.691697049533289],
            [9.7517259909969791, 50.691677148707917],
            [9.7508404018609358, 50.691387746744105],
            [9.7508127578655568, 50.691365064264104],
            [9.7507834639675899, 50.691341016455155],
            [9.7507991796360809, 50.691278970553498],
            [9.7504650724023385, 50.691209752522518],
            [9.751215861344555, 50.690591531055908],
            [9.7509106299768327, 50.690484464976009],
            [9.7505053467749452, 50.690342618364724],
            [9.7504484816975854, 50.690298576384762],
            [9.7500892618120023, 50.690019529324658],
            [9.7496635403925733, 50.689677714114445],
            [9.7493847619372609, 50.689481648747893],
            [9.748770532718364, 50.688952006339576],
            [9.7487389679505565, 50.688904311666356],
            [9.7488416262888986, 50.688816661642498],
            [9.7485685038322138, 50.688732612418818],
            [9.7485049669209207, 50.688705879147491],
            [9.7482989411562038, 50.688537663363249],
            [9.7478344055707478, 50.688359145109622],
            [9.7475547895337336, 50.688275980527571],
            [9.7469480554643741, 50.688134037364783],
            [9.7455190892635173, 50.687849100881749],
            [9.7450111912353918, 50.687723062849138],
            [9.7445580334018498, 50.687586807501432],
            [9.7444681513192268, 50.687541057954554],
            [9.7444050817247945, 50.687525713766597],
            [9.7437527620202005, 50.687269625464054],
            [9.7436944984408473, 50.687244834416155],
            [9.7437320901985061, 50.687206733480004],
            [9.744279899982585, 50.686751152099845],
            [9.7447228093555189, 50.686382779277132],
            [9.7447704733522631, 50.686355063669374],
            [9.7452465532603565, 50.686152661032189],
            [9.7451296011801638, 50.6860582972934],
            [9.7457525601545942, 50.685760372744717],
            [9.7459649108383068, 50.685651222239258],
            [9.7460652228304454, 50.685588014984226],
            [9.7462514348349778, 50.685470496760367],
            [9.7466147701385442, 50.685223107092526],
            [9.7470061856548558, 50.684977433874991],
            [9.7473409131202597, 50.684872271865096],
            [9.7476533047067502, 50.684814529087511],
            [9.7472547537626504, 50.68434241789587],
            [9.7472305292007455, 50.684313624274999],
            [9.7470230527579691, 50.684343165603103],
            [9.746700167434426, 50.684486993298826],
            [9.7465475371171451, 50.684397517953343],
            [9.746512684922056, 50.684359088653345],
            [9.7464005410602947, 50.6842357554495],
            [9.746306189708708, 50.684137309146593],
            [9.7462687006051674, 50.684096819240658],
            [9.7460089884978451, 50.684095873693437],
            [9.7450305916072999, 50.68410775210004],
            [9.744772603584904, 50.683825847116559],
            [9.7447024240960598, 50.68370978938605],
            [9.7442965009319575, 50.683910033278785],
            [9.7438835378463455, 50.684113783029815],
            [9.7436369620796359, 50.68423553167824],
            [9.7434362012543012, 50.684334540730141],
            [9.742909906738852, 50.68402591528961],
            [9.7425524619822781, 50.683542221696378],
            [9.7424759198560551, 50.683454054915622],
            [9.742450669844624, 50.683432722148019],
            [9.7422897582869137, 50.683325442601728],
            [9.7420270181556674, 50.683146587211802],
            [9.7419134660768627, 50.683087415715711],
            [9.7418936306618917, 50.683077289787171],
            [9.7418175928959325, 50.683038042038604],
            [9.7417154297544393, 50.682945235472303],
            [9.7416689789699049, 50.682879197646479],
            [9.7416439427307502, 50.682806728352318],
            [9.7416609416167415, 50.682734800980334],
            [9.7416435795221954, 50.682653478571268],
            [9.7416149263660508, 50.682519465246116],
            [9.7415567444685056, 50.682272191311633],
            [9.7415259896970294, 50.682232925068156],
            [9.7409459815586121, 50.681937537578762],
            [9.7407949304197547, 50.681820372769344],
            [9.7405714394879634, 50.681611713168515],
            [9.740477806050821, 50.681513698242718],
            [9.7403494158006865, 50.681379229978781],
            [9.7402621664027738, 50.681282073642684],
            [9.7401331202843071, 50.681161620617466],
            [9.7400918582546332, 50.681136342093424],
            [9.7398490420051189, 50.680987311417645],
            [9.7396390968786353, 50.680869071013831],
            [9.7397929530385223, 50.680737397279614],
            [9.7398987705473736, 50.680720196225494],
            [9.7397013294005532, 50.68064996288777],
            [9.7395110801616465, 50.680578883197704],
            [9.7391986407436928, 50.680433305566986],
            [9.7388867075168406, 50.680260348765287],
            [9.7383997756010245, 50.679913493316242],
            [9.7373151253513708, 50.679143253030155],
            [9.7371694608721437, 50.679039907863505],
            [9.7369645624022088, 50.678894066732369],
            [9.7367157239979569, 50.678717008984236],
            [9.7364041214614634, 50.678495129730187],
            [9.7363958060253939, 50.678489246790996],
            [9.7359104539814503, 50.678119780153594],
            [9.7358432743253793, 50.678068700547641],
            [9.7357580887932897, 50.678084445709445],
            [9.7357582047251228, 50.677868267769561],
            [9.7357099489852992, 50.67778278676105],
            [9.7356997670288461, 50.677765233458231],
            [9.7355429247938226, 50.677498623302746],
            [9.7354486658814494, 50.677405572470214],
            [9.735363441968433, 50.677321880382245],
            [9.7354287958424326, 50.677312384967614],
            [9.735677934944599, 50.676815973876543],
            [9.7359137436100127, 50.676434956497808],
            [9.7360769837143835, 50.676225743356028],
            [9.7364408903267208, 50.675821531877439],
            [9.7365911242869299, 50.675631618519382],
            [9.736667942757526, 50.675513835392522],
            [9.736749496195527, 50.675373980074319],
            [9.7368278621570266, 50.675219495005052],
            [9.7369052556451088, 50.675011974430788],
            [9.7369435782991864, 50.674803900445795],
            [9.7369359269844971, 50.674668664868044],
            [9.7369049978514433, 50.674536346350621],
            [9.7368676420006999, 50.674481661353965],
            [9.7367814209076542, 50.674359404949158],
            [9.7367157497607497, 50.674294107109077],
            [9.7368567539514395, 50.674238204666608],
            [9.7370636077184063, 50.674514740143309],
            [9.7371492427739756, 50.674580541047561],
            [9.7372733863706156, 50.674630009562065],
            [9.7373912252675243, 50.674630253354607],
            [9.7396270489301173, 50.673739605286357],
            [9.739630953440324, 50.673681871449148],
            [9.739678883525583, 50.673706829049756],
            [9.7397570312517452, 50.673746334623594],
            [9.7397884084209618, 50.673681591615576],
            [9.7401948330671679, 50.673286334225509],
            [9.740211685604903, 50.673269958584839],
            [9.7402720126734117, 50.673211218870051],
            [9.7404748793481186, 50.673013861480385],
            [9.7408334814613013, 50.672665013509956],
            [9.7410632164788762, 50.67244148507362],
            [9.7411505567018697, 50.672435713688166],
            [9.7419882178003476, 50.672984452351308],
            [9.742338994072119, 50.673214188186741],
            [9.7426398951649755, 50.673411262865493],
            [9.7428366807676934, 50.673634397040772],
            [9.7429536329330499, 50.673604621707369],
            [9.7429186301624515, 50.673798026759798],
            [9.7428889624874486, 50.67396183729489],
            [9.7427860047725243, 50.674134100827864],
            [9.7428296423926941, 50.674164930039964],
            [9.7433150865676303, 50.674088657889854],
            [9.743311038977911, 50.67383237882391],
            [9.743307414315332, 50.673791950733587],
            [9.7432612434094388, 50.673269131374099],
            [9.7433439651867229, 50.672587345341029],
            [9.7433261470292631, 50.672480665253389],
            [9.7433733363150647, 50.672289322676221],
            [9.7433917405360866, 50.672289744882285],
            [9.7434407142870416, 50.672292040525704],
            [9.7436765848929259, 50.672358308197168],
            [9.7436887976933289, 50.67229917221438],
            [9.7444629615439222, 50.671741420576716],
            [9.7446647267605613, 50.671510833111768],
            [9.7448047614759616, 50.671519093366385],
            [9.7457473614848578, 50.671791233428912],
            [9.74623235131439, 50.671946103084522],
            [9.7465038833329025, 50.672056760762906],
            [9.7468585000893828, 50.672268930801188],
            [9.7475165294326569, 50.672639079528452],
            [9.7476340026647197, 50.672652247459808],
            [9.747917385984902, 50.672582027388565],
            [9.7482665725094204, 50.672495601198065],
            [9.7497411703938184, 50.672130391616406],
            [9.7504659540085168, 50.671950666253295],
            [9.7524417053941566, 50.671460822751442],
            [9.7524576183111709, 50.671412534661627],
            [9.7523805255936562, 50.67136331814968],
            [9.7524418010826928, 50.671325169289751],
            [9.7512792572706246, 50.670789805506189],
            [9.7511272251391343, 50.66999685104274],
            [9.7510982499518217, 50.669849163195352],
            [9.7510748016858617, 50.669727681771377],
            [9.7510039148367547, 50.669363807519716],
            [9.7508967963765816, 50.668810590734068],
            [9.7508700370155719, 50.668675748752932],
            [9.75039223930189, 50.666605145757991],
            [9.7503597787270717, 50.666466662116292],
            [9.7503523070645457, 50.666432904901889],
            [9.7502563165928962, 50.666150840608623],
            [9.7496827270851814, 50.665614132084457],
            [9.7496432360214822, 50.665576902573136],
            [9.7492379395020787, 50.665199061747934],
            [9.7489134114991156, 50.664892978628536],
            [9.7489026915639787, 50.664886212703884],
            [9.748841863722399, 50.664851638109816],
            [9.7481765490637926, 50.664472694072835],
            [9.7482318089652367, 50.664444559413141],
            [9.7484547353203403, 50.664330587472087],
            [9.7496235247582277, 50.663733798150737],
            [9.7503593325670916, 50.66335716844916],
            [9.7510907716818576, 50.662983260159635],
            [9.7520019481737865, 50.662517176693562],
            [9.7528160718223234, 50.662350969884159],
            [9.7528715002588484, 50.66233193300787],
            [9.7533332689197572, 50.662349786280402],
            [9.7530304509957748, 50.661529605121181],
            [9.7532414583991862, 50.66149291634067],
            [9.7535079960536901, 50.661441002441229],
            [9.7536604462272578, 50.661439099378875],
            [9.7540014529441397, 50.661469682796138],
            [9.7542502868765091, 50.661506806267965],
            [9.7543865263458969, 50.661558993209532],
            [9.7544244030198417, 50.661597877341478],
            [9.7545005435863157, 50.661660948210198],
            [9.7545226861959815, 50.661683890342914],
            [9.7545459880297134, 50.661717364969419],
            [9.755073395510065, 50.662761798083253],
            [9.7551378030656597, 50.662889767352219],
            [9.7551680263716367, 50.662948917483867],
            [9.7552552347526778, 50.663114303434128],
            [9.755466938005533, 50.663577419808746],
            [9.7556606305119242, 50.66385259629169],
            [9.7559101999388975, 50.664100340590871],
            [9.7561883707483048, 50.664317402928127],
            [9.7563640122589685, 50.664430314604594],
            [9.7567934013972284, 50.664635728114469],
            [9.7570488099567108, 50.664732076943551],
            [9.7572336006493803, 50.664988683749684],
            [9.7573755850994282, 50.665181653960488],
            [9.7574660138875213, 50.665290531174605],
            [9.7575893439409729, 50.665387242528176],
            [9.7578029537017361, 50.665469175550328],
            [9.7580203309318474, 50.665511738548567],
            [9.758055817900285, 50.665518702274539],
            [9.7585183361006163, 50.665549390423841],
            [9.7584189580102407, 50.665327860827112],
            [9.7583219616033219, 50.665104795796431],
            [9.7582895450850913, 50.665005569196182],
            [9.7580096719417337, 50.66496397975574],
            [9.7579559076537912, 50.664875512841938],
            [9.7579282153138198, 50.664829728825289],
            [9.7578712947518085, 50.664735913472391],
            [9.7578463379997302, 50.664694806083325],
            [9.7581890062112588, 50.664743451754191],
            [9.7582540965910525, 50.664752345220805],
            [9.7587955667748929, 50.664826297338308],
            [9.7589692363243223, 50.664807602835857],
            [9.7590537270175322, 50.664749847008821],
            [9.7591071203109276, 50.664650205616638],
            [9.7592478421048323, 50.664687258143346],
            [9.7594052934050186, 50.664692122841195],
            [9.7594321061344882, 50.664692065040192],
            [9.7595454606831691, 50.664612339300994],
            [9.7595500304893701, 50.664329896855136],
            [9.7595543512394745, 50.664069732113802],
            [9.7595550496576653, 50.664010408577667],
            [9.7595419403944312, 50.663945958986176],
            [9.759484166324409, 50.663661656516709],
            [9.7594742080842831, 50.663612717594965],
            [9.7595009187434059, 50.663463400698866],
            [9.759562543989281, 50.663119028070902],
            [9.7596494296135106, 50.663087165521297],
            [9.7599780315913875, 50.662637531697406],
            [9.7602719496390549, 50.662528838711886],
            [9.7604172915678458, 50.662389728296802],
            [9.7604550501887086, 50.66239029131323],
            [9.7606895567389405, 50.662594407922022],
            [9.7618864565278489, 50.662231691770273],
            [9.7616107646910937, 50.662098766912429],
            [9.7614022094374988, 50.661946508137909],
            [9.761305222805813, 50.661776109442592],
            [9.7612710355819896, 50.661725125596156],
            [9.7613867431425572, 50.661689170425802],
            [9.7617218835124397, 50.661440666249653],
            [9.7623144786581104, 50.661034580565236],
            [9.7624328636901065, 50.660964037044828],
            [9.7629523937958886, 50.660695635008317],
            [9.7635675542028455, 50.660452972128368],
            [9.763716732963621, 50.660413584758082],
            [9.7648928064221447, 50.660019983391052],
            [9.7649941091444763, 50.659986079668833],
            [9.7650689148006666, 50.659961055208953],
            [9.7654752727596694, 50.659856952748932],
            [9.7658667229776146, 50.659781940861151],
            [9.7659776284842241, 50.65976613940672],
            [9.7662775169138456, 50.659726324673223],
            [9.7673989016848797, 50.659627251534189],
            [9.7676824306683301, 50.659604663926388],
            [9.7679836776426363, 50.659551139225144],
            [9.7680259570245287, 50.659543621092112],
            [9.7682287912334793, 50.659507588502805],
            [9.7685340975560528, 50.659419393863878],
            [9.7687167566586606, 50.659354823187705],
            [9.7688275893760075, 50.659315619224934],
            [9.7692467532601466, 50.659229801164066],
            [9.7695675606357497, 50.659123335250314],
            [9.7698795536954535, 50.659107811825663],
            [9.7703167391521504, 50.659079121482598],
            [9.7705483846647656, 50.659046275842726],
            [9.7713694972873455, 50.659027587965113],
            [9.7714362741311653, 50.659037847854279],
            [9.7721982084937675, 50.659009005830022],
            [9.7724523803976719, 50.659661225098532],
            [9.772477754875144, 50.659726320470945],
            [9.7725676086254705, 50.659696047339217],
            [9.7727257912425252, 50.65964294605655],
            [9.7732241293003685, 50.65944802293361],
            [9.7732665908925203, 50.659552457883919],
            [9.7733089010195471, 50.659622674743275],
            [9.7733391087560744, 50.659666693801093],
            [9.7733861699553479, 50.659679016935677],
            [9.7734042044024338, 50.659702000754635],
            [9.7734128597878147, 50.659709551551565],
            [9.7735258066679584, 50.659856074381771],
            [9.773698376731339, 50.660110326380142],
            [9.7737684283025281, 50.660233643529054],
            [9.7738142403599362, 50.660354153829417],
            [9.7738773571167368, 50.660549615331163],
            [9.7738906984773344, 50.660582288918313],
            [9.7737807080997552, 50.660628767585898],
            [9.7748821227294371, 50.661347388575422],
            [9.7748281139997335, 50.661366111745295],
            [9.77453329541434, 50.661468336471771],
            [9.7741191870874715, 50.661643694049253],
            [9.7738270343164402, 50.661745611487923],
            [9.7737003900897932, 50.661771552289771],
            [9.7735807260246297, 50.66179607066443],
            [9.7740309072519214, 50.662187297871242],
            [9.774037251761591, 50.662192813521585],
            [9.7741911705428279, 50.662333469776748],
            [9.7743387431604134, 50.662491686714326],
            [9.7744793345968404, 50.662595307271985],
            [9.7745449763848153, 50.662643731006995],
            [9.7746009767314384, 50.662684907327957],
            [9.7746804112455177, 50.662743473556596],
            [9.7748374334808634, 50.662859260158548],
            [9.7748752031799508, 50.662887112741743],
            [9.7759337459368165, 50.663667638983448],
            [9.7759704710993045, 50.663694706764893],
            [9.7767225193566034, 50.664249216965231],
            [9.776769358404735, 50.664283762369223],
            [9.7773573249302483, 50.664502837073535],
            [9.7776345414175143, 50.664606288887164],
            [9.7776862799312276, 50.664625575774856],
            [9.7778156121778199, 50.664673788490795],
            [9.7780577338026688, 50.664765773383373],
            [9.7783143094396845, 50.664871825509529],
            [9.7785881258074809, 50.664988454788961],
            [9.7788916836938125, 50.665117744913587],
            [9.7789178330708797, 50.665128892567367],
            [9.7790295768360611, 50.665189757981061],
            [9.7790532915651962, 50.665202558637347],
            [9.7791172998471243, 50.665161175808144],
            [9.7798879917667847, 50.664662736109051],
            [9.7799490361341626, 50.664625311663613],
            [9.7799917585255223, 50.664599107395965],
            [9.7803606511160108, 50.664736329083979],
            [9.7809814937822317, 50.664967216265971],
            [9.7812040984647783, 50.665049945526341],
            [9.7816377798866991, 50.665211253291858],
            [9.7818388885192249, 50.665286031641671],
            [9.7821912431119209, 50.66568510505703],
            [9.7814097472883024, 50.666275560651876],
            [9.7812436782497389, 50.666472374574774],
            [9.7812542433643763, 50.666547100036276],
            [9.7813521320514933, 50.66662365926426],
            [9.7825173260647436, 50.666758338758108],
            [9.7836365205369979, 50.666931914643577],
            [9.784123158229562, 50.666981557482515],
            [9.7842565656889366, 50.666955928605233],
            [9.7843246504542662, 50.666982692798527],
            [9.7842985720583631, 50.667044741379115],
            [9.7847044993354704, 50.66748837655738],
            [9.785365223928201, 50.66821145640197],
            [9.7860096887998562, 50.66891719534653],
            [9.7860270563837357, 50.668936098791427],
            [9.7860344533140413, 50.66894433162286],
            [9.7861311115354557, 50.668960002318613],
            [9.7860646391391821, 50.669084259750001],
            [9.7868761755977047, 50.668785632881992],
            [9.787239705025172, 50.66865314558143],
            [9.7872699122485241, 50.668695155526393],
            [9.7878084818726343, 50.669443454050104],
            [9.7882576369326628, 50.670067817265547],
            [9.788399444272331, 50.670134378883304],
            [9.7885989821557278, 50.670180225146581],
            [9.791288407396868, 50.67079821046044],
            [9.7916514959457519, 50.670881647649665],
            [9.7917824335527612, 50.670911523868668],
            [9.7936588558124669, 50.671344193535205],
            [9.7946225300386427, 50.671552033759788],
            [9.7946581245748483, 50.671623448724311],
            [9.79400870429183, 50.672205974163823],
            [9.7938951399528733, 50.672307804094757],
            [9.7938121681072712, 50.672382562937962],
            [9.7935373554400744, 50.672903304607885],
            [9.7934112534561049, 50.673043143631908],
            [9.7926427950292076, 50.673690619821421],
            [9.7919259308539548, 50.674296083901815],
            [9.7913294566744202, 50.675543549065367],
            [9.7913022143525108, 50.675688452125129],
            [9.791347181659475, 50.675905790551525],
            [9.7912901207845486, 50.675970020467027],
            [9.7909393292827094, 50.676075714991669],
            [9.7908913609318944, 50.676232152929103],
            [9.7910085405531433, 50.676471610269978],
            [9.7910296463993731, 50.676514751156994],
            [9.7910249214064677, 50.676598239835442],
            [9.790874691540969, 50.676835320992495],
            [9.7905942752499033, 50.677277861654098],
            [9.7903868065013953, 50.67760525306069],
            [9.7902076148570281, 50.677888026125061],
            [9.7891490535851897, 50.678456407467252],
            [9.7885961405982904, 50.678753272869109],
            [9.7883178123365191, 50.67887293011438],
            [9.7882143135319222, 50.678917417783303],
            [9.7881979826841476, 50.678931107896972],
            [9.7875892976561865, 50.679441465597861],
            [9.7874096962738939, 50.679592055477507],
            [9.7872046065170544, 50.67976406806234],
            [9.7869439463878738, 50.679982680328862],
            [9.7866688648763436, 50.680213404195804],
            [9.7866364069029075, 50.680240377910529],
            [9.7866286791135515, 50.680248874642842],
            [9.7865752912713599, 50.68030744766908],
            [9.7868299743451779, 50.680388879484539],
            [9.7865415850049722, 50.680602686794238],
            [9.7864432967282671, 50.680675547100549],
            [9.7863072309409915, 50.680776433056089],
            [9.7862646683010013, 50.680773896322791],
            [9.786154419870412, 50.68077397447091],
            [9.7860313430170827, 50.680781414492522],
            [9.785922749917539, 50.680746704705825],
            [9.7857119580228495, 50.680766894545712],
            [9.7855858738445285, 50.680722230009664],
            [9.7855630846461104, 50.680734074710927],
            [9.7850715225881064, 50.680938311794826],
            [9.7847075257942215, 50.681089545718464],
            [9.7846175875873875, 50.681126915206633],
            [9.7845187198109347, 50.681149982650908],
            [9.7844672131056534, 50.681161993439652],
            [9.7844428605156963, 50.681174334072416],
            [9.7833649691825517, 50.681720165549045],
            [9.7833136055576055, 50.68174252598827],
            [9.7832639846124714, 50.6817641372552],
            [9.7832190289749903, 50.681783702685252],
            [9.7831297078343074, 50.681822577719551],
            [9.782703142355972, 50.682118152184962],
            [9.7825238753659658, 50.682217201374812],
            [9.7823232959218203, 50.682290205258035],
            [9.7820324692137142, 50.682766365984627],
            [9.7819084220010915, 50.683212332404658],
            [9.7819559393263162, 50.683509453255887],
            [9.782058437602652, 50.683745294562925],
            [9.7820004590483638, 50.683787150984728],
            [9.7821603423109931, 50.683952181798809],
            [9.782348063803008, 50.684111692608163],
            [9.782519987412849, 50.684264303504051],
            [9.7826375563789298, 50.684399157907286],
            [9.7826680394486072, 50.684441212059873],
            [9.7827577737378615, 50.684566378751825],
            [9.7828112538898502, 50.684676122780964],
            [9.7828491451602719, 50.684777140205362],
            [9.7828805676151003, 50.684939417456974],
            [9.7826376496035916, 50.68498594255437],
            [9.7824632818333104, 50.685019344641006],
            [9.7819186153562327, 50.685123668955036],
            [9.7818074557646639, 50.685142787584482],
            [9.7816476567727104, 50.685234339378127],
            [9.7819931175930446, 50.685481348426947],
            [9.7820820964323882, 50.685547435678131],
            [9.7823924340204744, 50.685777870993753],
            [9.7825371229694191, 50.685892758501382],
            [9.7829662447246051, 50.686233487179351],
            [9.783022263919138, 50.686281305178881],
            [9.7831587730591174, 50.686397856633661],
            [9.7832241835711322, 50.686452796965476],
            [9.7833620255301632, 50.686568565814476],
            [9.7835951563360908, 50.68677538813035],
            [9.7837253222651341, 50.686890848422713],
            [9.7839193505252577, 50.687076942973675],
            [9.7843471101468289, 50.687492642603466],
            [9.7843825416391912, 50.687529006304906],
            [9.7829995493907518, 50.687913786412608],
            [9.7816241978750131, 50.688298831547613],
            [9.7803666386714276, 50.688653205622586],
            [9.7803006011049476, 50.688673954225962],
            [9.7791452450004286, 50.689053675007578],
            [9.777864946835253, 50.689474435434477],
            [9.7779035476281813, 50.689506616110613],
            [9.7783769838869716, 50.689901402310518],
            [9.778926771483345, 50.690359851478419],
            [9.7791976351849641, 50.690585702616609],
            [9.7794036560604098, 50.690757497245222],
            [9.7798116911329505, 50.691141797185018],
            [9.7800566793613015, 50.691372531820178],
            [9.7804544737729397, 50.691859150042028],
            [9.7805798095812015, 50.692012471253285],
            [9.7807766087404584, 50.692253221643846],
            [9.7807474042955, 50.6923148404044],
            [9.7804690180989038, 50.692411420386328],
            [9.7804429192781335, 50.692420471353678],
            [9.7800581167788643, 50.692548456357819],
            [9.7793042613169359, 50.692799164174183],
            [9.779265976198209, 50.692811893601004],
            [9.7792393920216565, 50.692820731706512],
            [9.778858250847815, 50.692974417622601],
            [9.7786689686478496, 50.693040198935648],
            [9.7783803894768191, 50.693139063290964],
            [9.7782062326935026, 50.69312561222182],
            [9.7780902192741532, 50.693102464907852],
            [9.7780537620277634, 50.693140992041613],
            [9.7770172375736681, 50.694236300243709],
            [9.7767057043138905, 50.69450023853333],
            [9.7764463070654113, 50.694720005170346],
            [9.7763531449836645, 50.694770753396028],
            [9.7760782600009648, 50.694920475889617],
            [9.7753469837355933, 50.695318782740308],
            [9.7748293389953851, 50.695534795279997],
            [9.774701821708085, 50.695587991822578],
            [9.774254185726031, 50.695772069535067],
            [9.7725996496263079, 50.696452387795262],
            [9.7726422536581747, 50.696549698883956],
            [9.7726755163360757, 50.696625686207625],
            [9.7725855767389707, 50.696650698667625],
            [9.7721954676471103, 50.695780293209715],
            [9.7719750093359838, 50.695341143831257],
            [9.7718712986052854, 50.695207635063582],
            [9.771784526442282, 50.695095939316282],
            [9.7717371416455752, 50.69505223164095],
            [9.7715648263162471, 50.694893294522423],
            [9.7710526610140445, 50.694399345065989],
            [9.7702982091406074, 50.694400519822274],
            [9.7699004479972356, 50.694401223010864],
            [9.7698400054355954, 50.694401262466855],
            [9.7687718660625276, 50.694403122579743],
            [9.7685760480315658, 50.694404602668435],
          ],
        ],
      },
      properties: {
        FID: 2,
        OBJECTID: 14,
        gemarkungs: "Hünfeld",
        FIRST_geme: "Hünfeld",
        shape_Leng: 17406.043665000001,
        shape_Area: 10112811.1117,
      },
    },
    {
      type: "Feature",
      id: 3,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.8223510685612663, 50.729830625155451],
            [9.8221717763401166, 50.729909526974453],
            [9.8220448769817104, 50.730035102063717],
            [9.8220286106156021, 50.73022293874034],
            [9.8218577330459222, 50.730399014150194],
            [9.8217145919838114, 50.730546385708855],
            [9.821557283835455, 50.730708533646819],
            [9.8213943934432155, 50.730876449294605],
            [9.8208711452242223, 50.731091004352457],
            [9.8207664370028542, 50.730994049415131],
            [9.8203805431379703, 50.730563495580292],
            [9.820031388544388, 50.730085342395618],
            [9.8197795169836066, 50.729826601365644],
            [9.8188643749855959, 50.730041123231658],
            [9.8186775890881872, 50.729730311324516],
            [9.818565832192073, 50.729561809822592],
            [9.8184975111305466, 50.729496127197059],
            [9.8183615052327973, 50.729381727340957],
            [9.8181753553925972, 50.729284415356851],
            [9.8180705012938514, 50.729259071077536],
            [9.8180213748562934, 50.729244316326508],
            [9.8178446727461317, 50.72916058905971],
            [9.8177078428297282, 50.729080098211263],
            [9.8176139644579745, 50.728905445028722],
            [9.8176137183201462, 50.728835138688737],
            [9.8176205178166853, 50.728754458861822],
            [9.8176473695718993, 50.7285738419693],
            [9.8176002812649088, 50.728449833540516],
            [9.8175300103399206, 50.728376151161299],
            [9.8173076226783103, 50.72814287334603],
            [9.8171400138084675, 50.7279877929452],
            [9.8169645193365191, 50.727794726729982],
            [9.816918789973025, 50.727755225673548],
            [9.8167607282300047, 50.727618585638673],
            [9.816554352564772, 50.727465655502179],
            [9.8164155163915368, 50.727278285157126],
            [9.81630656903946, 50.727131228317283],
            [9.816013119752256, 50.726721506182294],
            [9.815984964847269, 50.7266822234971],
            [9.8157160033734758, 50.726731013498537],
            [9.8154317396806814, 50.726812464930788],
            [9.8152650723718065, 50.72688733788825],
            [9.8148860939600731, 50.726201232318722],
            [9.8145123841945345, 50.725487407880493],
            [9.8132777785222238, 50.725947138097965],
            [9.8132196730783043, 50.725886471209478],
            [9.8128899845778399, 50.725542023095684],
            [9.8127915119821907, 50.725531926857748],
            [9.8127104123097997, 50.725523633967732],
            [9.8126649941766537, 50.725518986343623],
            [9.8125466091432045, 50.725506879333444],
            [9.8120210004328676, 50.72595833467097],
            [9.8082380529454589, 50.725108304276979],
            [9.8082891776071968, 50.7250230639725],
            [9.8081382676859938, 50.724996258403657],
            [9.8080361056753258, 50.72500217346191],
            [9.8079081991645136, 50.724993176393959],
            [9.8074623925467623, 50.724979978088655],
            [9.8071719775005324, 50.724992232086734],
            [9.8065709084684496, 50.724951156327521],
            [9.8062531853984396, 50.724923406767445],
            [9.805838351695872, 50.724857396412659],
            [9.8057379258170645, 50.724841453212903],
            [9.8056298447003005, 50.724750056735274],
            [9.8043911756767272, 50.724553584135087],
            [9.8046290579935516, 50.723323889550819],
            [9.8046504789354572, 50.723268461110116],
            [9.8042085453799874, 50.723213180907685],
            [9.8042134706577144, 50.722753554336897],
            [9.804198509302287, 50.72264011958471],
            [9.8041573028063542, 50.722326760263051],
            [9.8035115471515155, 50.722330528837951],
            [9.8029119434458387, 50.722334875333935],
            [9.8023971777216943, 50.72237439150544],
            [9.8017815252165477, 50.722419599380864],
            [9.8012880641458864, 50.722464881604779],
            [9.800709787558775, 50.722517048405848],
            [9.8004908350116882, 50.722534532604982],
            [9.8001410942604856, 50.722604597381775],
            [9.8004649058766731, 50.722192585978767],
            [9.8003780471330497, 50.721943893423635],
            [9.8006088968906013, 50.721665338845924],
            [9.8006546026103223, 50.721476826282199],
            [9.800699493147814, 50.721250476304228],
            [9.8008257601389719, 50.720794099292789],
            [9.8008653293859425, 50.72056399068024],
            [9.8008992182353865, 50.720367168580957],
            [9.80096890137429, 50.719942413075223],
            [9.8010766026457237, 50.719290653042037],
            [9.8012906050442954, 50.717998572307856],
            [9.8011037732388502, 50.717730215759772],
            [9.8009906949066732, 50.717567704763084],
            [9.8008678867053618, 50.717391959678331],
            [9.8007873444972962, 50.717276609684312],
            [9.8006916890127123, 50.717139699020464],
            [9.7998592784021721, 50.716644352518792],
            [9.7995593077521725, 50.716448408734237],
            [9.7989888705213382, 50.716077621341007],
            [9.7989320708069307, 50.716042784557516],
            [9.7986644955104296, 50.715878874766801],
            [9.7985519058700223, 50.715802709905141],
            [9.7983553887434969, 50.715669860521231],
            [9.799160147180709, 50.715259144314288],
            [9.7988977771924084, 50.715026258152413],
            [9.7988791822055763, 50.714998965564995],
            [9.798757927771943, 50.71487126677065],
            [9.7985890949136998, 50.714726087390204],
            [9.7974116956084618, 50.714037683525262],
            [9.7966975065605748, 50.713620060596483],
            [9.796050180280103, 50.71331165680963],
            [9.7961104732730853, 50.713284625491532],
            [9.7958465573495896, 50.713157133323733],
            [9.7956224734609219, 50.713056456003038],
            [9.7955305432193889, 50.713018556822199],
            [9.7949920365421086, 50.712688891277629],
            [9.7949755342805869, 50.712675433192445],
            [9.7946622717755556, 50.712420293783005],
            [9.7942747702151891, 50.712160434328801],
            [9.7938083330837848, 50.711897801603655],
            [9.7935878521655848, 50.711837088231896],
            [9.7935499959025112, 50.710703124929147],
            [9.7935379519487729, 50.710342097219112],
            [9.7935392719875942, 50.71031961442975],
            [9.7934790225823356, 50.710323082168408],
            [9.7934889557352385, 50.709880373228643],
            [9.7934996185208902, 50.709404546619169],
            [9.7935063583471127, 50.709326413480696],
            [9.7935228377125423, 50.709135584204851],
            [9.7935425021448381, 50.708907753526333],
            [9.793598012622887, 50.708264664242634],
            [9.7936317007137426, 50.70787428628325],
            [9.7936495774606751, 50.707667295778535],
            [9.793655582765048, 50.707597693088566],
            [9.7936623592513872, 50.707507499888067],
            [9.7936764521575448, 50.707319672492503],
            [9.7937219361474082, 50.706713720887834],
            [9.7937224113006636, 50.706658823620096],
            [9.7937258677261525, 50.706264649406286],
            [9.7937277727081558, 50.706108227999614],
            [9.793730689116769, 50.706009193830724],
            [9.7937350533462588, 50.705860867472943],
            [9.7937435052871376, 50.70562584652275],
            [9.7937457685836051, 50.705605560606273],
            [9.793750490157036, 50.705563116875162],
            [9.7937746406080652, 50.705404007923356],
            [9.7938144666277402, 50.70514165005185],
            [9.7938588542096259, 50.704807280099295],
            [9.7939206054639154, 50.704342211640274],
            [9.7939430942377861, 50.704172825795276],
            [9.7939541883888523, 50.704089302999094],
            [9.794018286539135, 50.703606501979095],
            [9.7940919444926742, 50.703051726693836],
            [9.7941328234188081, 50.702743766255637],
            [9.7941618931826255, 50.702567527688593],
            [9.7942401108291772, 50.702470633417832],
            [9.7932392274172706, 50.702740506201195],
            [9.792617463144035, 50.702896485350813],
            [9.7924184073941696, 50.702949072432567],
            [9.7923713087199289, 50.702802121074662],
            [9.7915416695453565, 50.701741900314666],
            [9.7913474952590906, 50.701359049985399],
            [9.7911865862816541, 50.701041767307252],
            [9.7910898609604402, 50.700851076854804],
            [9.7910815270358551, 50.700834640004139],
            [9.7909795588158435, 50.70063358897216],
            [9.7908455161180523, 50.70036928191049],
            [9.7906647690282718, 50.700012887019547],
            [9.7906259716277386, 50.699936366780314],
            [9.7904712240830598, 50.69963123596186],
            [9.7902959016710529, 50.699285532492553],
            [9.7902420795728577, 50.699179400658529],
            [9.7901863535050442, 50.699069495594415],
            [9.7901261028626188, 50.698950699979505],
            [9.7898800057580111, 50.698465398460961],
            [9.7897875193928705, 50.698424440833627],
            [9.7892036094843196, 50.698439636776769],
            [9.7892350850367471, 50.698384691462046],
            [9.7893419099895809, 50.698325827560069],
            [9.7897613786142657, 50.698094695755842],
            [9.7900656444762593, 50.69792704340427],
            [9.790180699534563, 50.697860074088119],
            [9.7903336418439633, 50.697764240630505],
            [9.7906251541559577, 50.697578561086338],
            [9.7909797497732551, 50.697340976061909],
            [9.7912530912527895, 50.697148619402995],
            [9.7912970587717822, 50.697120990812664],
            [9.7913350996646944, 50.697097098617377],
            [9.7917867760759094, 50.696809722526574],
            [9.791856571238899, 50.696765370916516],
            [9.7920055109714497, 50.69666181033476],
            [9.792229312627283, 50.69652309025301],
            [9.7925635499582402, 50.69628829166264],
            [9.792594093490731, 50.696268254122103],
            [9.7929300803982997, 50.695980401023853],
            [9.7932820266920615, 50.695709705160695],
            [9.7933280429264435, 50.695675254042236],
            [9.7935992375314083, 50.695497772057912],
            [9.7937693782236899, 50.695386582553809],
            [9.7941076338090003, 50.695110617460578],
            [9.7943298421640392, 50.694977282032255],
            [9.7952435840687588, 50.694483398886611],
            [9.7953578056316584, 50.694421655088732],
            [9.7954347731479796, 50.694378187652653],
            [9.7957317792458785, 50.694226739517838],
            [9.7957996210711329, 50.694187884662497],
            [9.796208923349969, 50.693954064827452],
            [9.7964008648170591, 50.69384838820033],
            [9.7966417052196846, 50.693715757375884],
            [9.796890585134264, 50.693601444085679],
            [9.7969475340370593, 50.693574929670412],
            [9.7971587577371206, 50.693484390315739],
            [9.7974152278629649, 50.693375311959599],
            [9.797932631000732, 50.693172564224774],
            [9.7983128237846326, 50.693033292275992],
            [9.7985238987643015, 50.69295597137323],
            [9.7985835158533341, 50.692934105317867],
            [9.7986535984680376, 50.692821516600013],
            [9.7988648866131136, 50.692861859960992],
            [9.7995215195646015, 50.692987048298292],
            [9.7999691876047716, 50.693077721560691],
            [9.8005980469180862, 50.693205342725385],
            [9.8018497320446638, 50.693457369867467],
            [9.8019577447010171, 50.693478866883716],
            [9.8020610375117414, 50.693506700472099],
            [9.8022491610771656, 50.693557395319708],
            [9.802400115075832, 50.693602868897273],
            [9.8027253015016562, 50.693701344310412],
            [9.8027519483003793, 50.693707186220848],
            [9.8028655226331232, 50.69352776414047],
            [9.8029177184483292, 50.693482618753187],
            [9.8029403129420238, 50.693422775594591],
            [9.8029017557795193, 50.6933288289461],
            [9.8028982822520838, 50.693307817895565],
            [9.8028931480906056, 50.693256709220016],
            [9.8029114623176685, 50.693226465019791],
            [9.8029583488885113, 50.69321203181196],
            [9.8030050838411444, 50.693206134128133],
            [9.8030558145715219, 50.693210371156901],
            [9.8031390566350964, 50.693162601618418],
            [9.8031732375108334, 50.693183491021536],
            [9.8034428030291583, 50.6932385330337],
            [9.8036818107015087, 50.693310692227605],
            [9.8038106262779969, 50.693361406734269],
            [9.8039196347203994, 50.693641565014168],
            [9.8039536622751022, 50.693670009490702],
            [9.8045572788427133, 50.69379249544285],
            [9.8050704373047406, 50.693896636491644],
            [9.805357396326615, 50.693965763132347],
            [9.806123917634185, 50.694150373204565],
            [9.8062643527580846, 50.694194745417796],
            [9.8063894607836009, 50.694188295287269],
            [9.8065487351807938, 50.694134061850164],
            [9.8065661093325946, 50.69412424702206],
            [9.8065835207711309, 50.694124036616785],
            [9.8067105877370953, 50.694147609705183],
            [9.8069041012139593, 50.694174490857371],
            [9.8072072274829658, 50.694237475628931],
            [9.8073533509960047, 50.694282400689978],
            [9.8074534258772221, 50.694283326304898],
            [9.8074910163676599, 50.694271743543283],
            [9.8075165845774208, 50.694269138276951],
            [9.807643603962994, 50.69425773632075],
            [9.8077743988628061, 50.694248529371492],
            [9.807878714637992, 50.694268850436906],
            [9.8079647451675935, 50.694315162446586],
            [9.8079981714203068, 50.694333159873722],
            [9.8080819801469037, 50.694357274117749],
            [9.8081086906814274, 50.694364292466027],
            [9.8081479727265144, 50.694338272753967],
            [9.8083769652064525, 50.694378089449799],
            [9.8084198995882943, 50.694483829932722],
            [9.808506317442772, 50.694489202040323],
            [9.808605362168926, 50.694496887667512],
            [9.8088396546484145, 50.694170564551825],
            [9.8090648273487382, 50.694166951498907],
            [9.8091456499122227, 50.694185366061483],
            [9.809241230095699, 50.694232006543515],
            [9.8093339476177537, 50.694258738001466],
            [9.8097078571108867, 50.694353815992336],
            [9.8100662113374124, 50.694477050518643],
            [9.8102117007159642, 50.694552355270602],
            [9.8104298122199314, 50.694566127456724],
            [9.8107701306927471, 50.694608816652405],
            [9.8110490223254914, 50.694579681283777],
            [9.8112624278693819, 50.694586559928744],
            [9.8117907833720164, 50.694683038399575],
            [9.8118429341860551, 50.694723468143472],
            [9.8118884324009183, 50.694787794033516],
            [9.8120084359407276, 50.694883373739671],
            [9.8122219106542499, 50.695143389346157],
            [9.8123329766408336, 50.69534688583731],
            [9.8124454354862696, 50.695533420423125],
            [9.8125389626258741, 50.695627241582585],
            [9.8125972168606879, 50.69572826025744],
            [9.8126309403632934, 50.695805905845965],
            [9.8126190179917501, 50.695877988110794],
            [9.812590457294764, 50.695917217471511],
            [9.8124010567164053, 50.695997884136553],
            [9.8122595326851823, 50.696005885005597],
            [9.8121820316827808, 50.696053791914814],
            [9.8121513146756829, 50.696108387494043],
            [9.8121199282524501, 50.696257047068521],
            [9.8122494108259399, 50.696572451941051],
            [9.8124161377869328, 50.696762655297633],
            [9.812545251619321, 50.696798393488045],
            [9.8130126983875847, 50.69686162963162],
            [9.8130945754824399, 50.696872704691515],
            [9.8132794253599371, 50.696942227258113],
            [9.8140783226195829, 50.697312393637546],
            [9.8141470285654293, 50.697369047279977],
            [9.8142339918563568, 50.697395761075263],
            [9.8143157207064355, 50.697390028121617],
            [9.8144038034965408, 50.697377541832417],
            [9.8144985729180281, 50.697347265276235],
            [9.8145642578755528, 50.697326760586719],
            [9.8146779991979969, 50.697310281700126],
            [9.8147383169099882, 50.697313439421308],
            [9.8148354449586712, 50.697315521438362],
            [9.8151903031901355, 50.697395093941658],
            [9.8154262438456144, 50.697480821199925],
            [9.8155246481162948, 50.697527077052634],
            [9.8158016314313166, 50.697591157690212],
            [9.8161184331215914, 50.69764222461383],
            [9.8162222263228678, 50.697676661127034],
            [9.8162751518731799, 50.697694420726087],
            [9.8166883311555466, 50.697720538693204],
            [9.8167651098249031, 50.697747726646185],
            [9.8170191645218914, 50.697708714705833],
            [9.8171139019034488, 50.697657095505782],
            [9.8172803688035408, 50.697547462174832],
            [9.8172802886770025, 50.697477675901865],
            [9.8172429668624446, 50.697454213629221],
            [9.8172089666744942, 50.697391302094154],
            [9.8172395571674738, 50.697381860670554],
            [9.8173673900584184, 50.697387583376823],
            [9.8175999008408557, 50.697375170071211],
            [9.8177313091377769, 50.697387819006018],
            [9.8180296677597418, 50.697365167362292],
            [9.818301789496541, 50.697410111780101],
            [9.8184013645424919, 50.697441311453517],
            [9.8185505702047884, 50.697487217087811],
            [9.8188948195522929, 50.697588112626555],
            [9.8189324917703438, 50.697664163847641],
            [9.8189970116181513, 50.697681723850806],
            [9.8190587443989958, 50.697720554212495],
            [9.8192454266236169, 50.697813274772763],
            [9.8193221391831909, 50.697868268326651],
            [9.8193914993797282, 50.697923052708653],
            [9.8194866189227721, 50.697950361761094],
            [9.8195229270601878, 50.697996007548767],
            [9.8195948528881569, 50.698078544616891],
            [9.8196065209412957, 50.69811349988953],
            [9.819686088780891, 50.698184399994794],
            [9.8198123886581641, 50.698422046123071],
            [9.8198231848592936, 50.698458959017152],
            [9.8199168748934902, 50.698520334825588],
            [9.8199584844632373, 50.69864501994077],
            [9.8201610753036874, 50.698905061564162],
            [9.8203662066076252, 50.699222119493378],
            [9.8204976752659849, 50.699362044911084],
            [9.8209457849199495, 50.699433254401725],
            [9.8211548622753853, 50.699488519604287],
            [9.8214166241092347, 50.699506954993588],
            [9.8215623461990607, 50.699476124809586],
            [9.8217004751025776, 50.699436201934077],
            [9.8219049312875963, 50.699344505363356],
            [9.8221720885286175, 50.699070641679057],
            [9.8224551352107135, 50.698861829618068],
            [9.8226787630913694, 50.698673185156508],
            [9.822829092003543, 50.698647680782514],
            [9.8232928774695925, 50.698567074153061],
            [9.8233433983689746, 50.698491768429491],
            [9.8234503326712268, 50.698427008866936],
            [9.8234836463519635, 50.698378237509338],
            [9.8235605598874329, 50.698299651601275],
            [9.8237806328122979, 50.698032960563879],
            [9.8238533949103566, 50.697909833735551],
            [9.8239535744559863, 50.697889601563794],
            [9.8240654362073148, 50.697866975483613],
            [9.8244109553583563, 50.697801202686769],
            [9.824480749484124, 50.6977536119417],
            [9.8245188787635414, 50.69772034638595],
            [9.8245521409018881, 50.69769698972484],
            [9.8246501048758716, 50.697642508714821],
            [9.8247213715054684, 50.697618352553732],
            [9.8247708653109775, 50.697591238766748],
            [9.8248607662996044, 50.697551770236338],
            [9.8249168470986206, 50.697533701854127],
            [9.8249990273872641, 50.697506140825546],
            [9.8251147581927967, 50.697490249202232],
            [9.8252768752205899, 50.697449315897948],
            [9.8254532626686721, 50.697467950885809],
            [9.8255648536527733, 50.697455001910519],
            [9.825687965164807, 50.697406268412266],
            [9.8257970590707799, 50.697373695855298],
            [9.8260460080279941, 50.697315401078356],
            [9.8262610152296528, 50.697233694358083],
            [9.8263347710983737, 50.697232020431265],
            [9.8264792055021744, 50.697259019168314],
            [9.8266476635494122, 50.697383180267806],
            [9.8267100569492829, 50.697407702794813],
            [9.8268363624748591, 50.69742875927718],
            [9.8268990391914404, 50.697463747723937],
            [9.8270582676709797, 50.697529176464215],
            [9.8270721336688993, 50.697548710140104],
            [9.8271503501091146, 50.697590494939242],
            [9.8274155558538414, 50.697627274412035],
            [9.8275373849120218, 50.697661842645907],
            [9.8276089008138658, 50.697670436010306],
            [9.8277342268718488, 50.697680832595481],
            [9.8277993624075766, 50.697685721002479],
            [9.8279155020763831, 50.697664931418899],
            [9.8280882138182832, 50.69770871539874],
            [9.8281717259761212, 50.697776964717796],
            [9.8284354347755283, 50.697825920244448],
            [9.8286507814889692, 50.697850361888975],
            [9.8287466118841795, 50.697903243865419],
            [9.8289391746727794, 50.697960537065107],
            [9.8290492452137759, 50.698002687624545],
            [9.8292403561785253, 50.698041042078678],
            [9.8293943900920837, 50.698057321029403],
            [9.8294561314410611, 50.698083276639714],
            [9.829522653944057, 50.698169955938745],
            [9.8295307716494431, 50.698175006284615],
            [9.829545889532378, 50.698185168886049],
            [9.8295687151028055, 50.698185968721923],
            [9.8295892311689439, 50.698186686064197],
            [9.8295881665729858, 50.698177709480277],
            [9.8295850028487557, 50.698150878439129],
            [9.8295766467231545, 50.698080143801647],
            [9.8295419886488222, 50.697781844716211],
            [9.8295335149135745, 50.697714870046802],
            [9.829504133048605, 50.697604067632277],
            [9.8294646046824141, 50.697455062541394],
            [9.8294302984753763, 50.697143729809063],
            [9.8294165254696964, 50.697018733021707],
            [9.8294088360478398, 50.69678828417355],
            [9.8294036271938179, 50.69663184033049],
            [9.8294091870226215, 50.696589020331373],
            [9.8294283555372246, 50.696441126397154],
            [9.8294514768844454, 50.696262870414458],
            [9.8294552920757354, 50.696226304074436],
            [9.8294959301018139, 50.695836242515476],
            [9.829515474067934, 50.695427112644758],
            [9.8295242593627279, 50.695148154324386],
            [9.8302851404444436, 50.695381298852176],
            [9.8304867322047205, 50.696365656735985],
            [9.8313308475897845, 50.696205272430085],
            [9.8314930488156538, 50.696624500688827],
            [9.8323437239814506, 50.696540270279826],
            [9.8320197377871494, 50.695616359875267],
            [9.8319647168610533, 50.695365115007171],
            [9.8317983786630752, 50.694605507765843],
            [9.8315280549965731, 50.69445948091635],
            [9.8311840136009323, 50.694273662762036],
            [9.8309591065699298, 50.694142410263069],
            [9.830872870722235, 50.694065710761734],
            [9.8304995679902465, 50.693793234215498],
            [9.8304471683404273, 50.693756402821627],
            [9.8306925701231993, 50.693711909812158],
            [9.8308022216164321, 50.693631430975039],
            [9.830861040824642, 50.693615687402641],
            [9.831011306497663, 50.693634239344945],
            [9.8311650689835428, 50.693652766160689],
            [9.830956845663863, 50.693352548091589],
            [9.8308326267646144, 50.693273574243726],
            [9.8306555107303577, 50.693218530303731],
            [9.8303190159866904, 50.692931770524282],
            [9.8303044767843719, 50.692814756142361],
            [9.8303345161460225, 50.692770403733377],
            [9.830466877271645, 50.692702839486252],
            [9.8304168125859679, 50.692671486261851],
            [9.8303940911300085, 50.692657187119728],
            [9.8302649851623389, 50.692574704172053],
            [9.831311338051842, 50.69240870629266],
            [9.8327065535840816, 50.692152461110247],
            [9.8328682132985268, 50.692148671179105],
            [9.8337569521582129, 50.692127838825009],
            [9.8343924422003646, 50.692291122153769],
            [9.8352602116281975, 50.692349019706157],
            [9.8358880269146329, 50.692231367442503],
            [9.8365886674282947, 50.692199658441396],
            [9.8368775527681489, 50.692213475722568],
            [9.8369528819183873, 50.692217134694502],
            [9.8374470687561111, 50.692197316765096],
            [9.837523371717916, 50.692182514395874],
            [9.8380165885450577, 50.692086834669283],
            [9.8381745775214107, 50.692277505012818],
            [9.8383224341268605, 50.692249938903579],
            [9.8385401931943708, 50.692484677071441],
            [9.838683860400419, 50.692638982456643],
            [9.8387609195134047, 50.692731660155545],
            [9.838856715821203, 50.692857990289227],
            [9.8389223554233141, 50.692944625711242],
            [9.8390003891296995, 50.693074420021532],
            [9.8390659977229351, 50.693282292441886],
            [9.8391329374644609, 50.69390277960354],
            [9.8391561750345247, 50.693984701924457],
            [9.8392725200932887, 50.694209466831154],
            [9.8393926743076374, 50.694495276758929],
            [9.8394375093205255, 50.694612359648509],
            [9.8396979187991302, 50.695167547589925],
            [9.8399648904773134, 50.695144067578845],
            [9.8402611523095977, 50.695110042685954],
            [9.8406168668196194, 50.695041953896137],
            [9.8407998915681958, 50.695359126775124],
            [9.8408562082926299, 50.695498861010812],
            [9.8411389259269715, 50.695781724726281],
            [9.8412055908862186, 50.695739200639942],
            [9.8412712372013154, 50.695699894425204],
            [9.8413058769378168, 50.695689185382953],
            [9.8413666367640573, 50.695670373824555],
            [9.8414483072424481, 50.695645107088723],
            [9.8416754342548067, 50.695574786600254],
            [9.8420075512248317, 50.695432589421586],
            [9.8421687149176922, 50.69535623336057],
            [9.8425744343358055, 50.695195480485566],
            [9.8425985618003793, 50.695223175902726],
            [9.8427586634376336, 50.695406846034984],
            [9.8429979690685396, 50.695610888272412],
            [9.8431057921996565, 50.695680497970919],
            [9.8431694053145211, 50.69573873624239],
            [9.8431476801003832, 50.695782672099099],
            [9.843113289437504, 50.695853849915395],
            [9.8430950149565515, 50.695963114116964],
            [9.8430288805645798, 50.695983818036112],
            [9.8430609803373308, 50.696013883854157],
            [9.8430851186270889, 50.696036614864418],
            [9.8432092524058508, 50.696153500465265],
            [9.8432542827059173, 50.696201890693494],
            [9.8432996984890124, 50.696271771773482],
            [9.8433193754401032, 50.696358377467824],
            [9.842856935171497, 50.696670511219082],
            [9.8424099100298363, 50.696849945535838],
            [9.8419530136987081, 50.697038154800317],
            [9.8410750841911643, 50.697385719908553],
            [9.8402910868762419, 50.697685495131665],
            [9.8397404418834693, 50.697895406386799],
            [9.8395537566957856, 50.697966600899981],
            [9.8390904032332642, 50.698120329620401],
            [9.8392527525549678, 50.698223076852749],
            [9.8392827454866758, 50.698285884965806],
            [9.8394862314596132, 50.698559223670301],
            [9.8395634836239996, 50.698604973088898],
            [9.8396588145349284, 50.69862514160463],
            [9.839740067118985, 50.698657012629937],
            [9.8397908646479433, 50.698743754415588],
            [9.839814616827935, 50.69882098740468],
            [9.8398531816003914, 50.698832481613124],
            [9.8400055204878196, 50.698913635399443],
            [9.8401227294727001, 50.698962334055565],
            [9.8402098647954759, 50.698998470079857],
            [9.8407685223703592, 50.699218650576313],
            [9.8407986293213288, 50.699121388275834],
            [9.8408318000672583, 50.699014130455751],
            [9.8407377024809097, 50.698818299657049],
            [9.8413037270334005, 50.698920749000948],
            [9.8416936561505324, 50.698896449596219],
            [9.8417694388395081, 50.699159743651258],
            [9.8418284671113963, 50.699365089825108],
            [9.8419127029338629, 50.69966464608526],
            [9.8419473715051584, 50.699822252573973],
            [9.8419966217060288, 50.700037372879748],
            [9.842070826624191, 50.70006450882719],
            [9.8422590933984306, 50.700149339142719],
            [9.8425712954206102, 50.700291198363949],
            [9.8427267385296382, 50.700383846392192],
            [9.8428298400550478, 50.700413021246604],
            [9.8429897345339832, 50.700482200577113],
            [9.8433041465081264, 50.700484117345567],
            [9.8433427381006044, 50.70048435077625],
            [9.8434003636348457, 50.700484707289405],
            [9.8435978193071723, 50.700869939291046],
            [9.8437987293746154, 50.701265200284453],
            [9.8441611207738671, 50.701173121732232],
            [9.8444602453620664, 50.701097067517651],
            [9.8446694644805497, 50.701435717865358],
            [9.8447800287819387, 50.701671976262894],
            [9.8441749203783093, 50.701798162973901],
            [9.8443292605022403, 50.70211517805641],
            [9.8444286746589125, 50.702329853009722],
            [9.8448375845508682, 50.702250754278431],
            [9.8451519999940338, 50.702189965794524],
            [9.8452795834948255, 50.70248335072872],
            [9.845236640326851, 50.702762611733718],
            [9.8451173689030451, 50.703111286642965],
            [9.8453714169433084, 50.703156640723201],
            [9.8460482761348445, 50.703263920010286],
            [9.8457687539577297, 50.703635962556504],
            [9.8461431120807514, 50.703798036505333],
            [9.8465323145330323, 50.704037207672279],
            [9.8462091553926943, 50.704304384188355],
            [9.8461863223539456, 50.704600757347414],
            [9.8460574178818003, 50.705318968624155],
            [9.8456528647212433, 50.705595178759509],
            [9.8458056277818233, 50.705847677334113],
            [9.8458962797464284, 50.706250650097907],
            [9.8462384662195745, 50.706510946494326],
            [9.8465560588898207, 50.706648699979787],
            [9.8468577831894546, 50.7067844185108],
            [9.8473333908577807, 50.706923000415991],
            [9.847694050874864, 50.706998331093025],
            [9.8480483277881667, 50.707072259192515],
            [9.8483251565188681, 50.706879671296122],
            [9.8486767500226016, 50.706673957066386],
            [9.8491323264283359, 50.706877850816625],
            [9.8487582647209742, 50.707094162048165],
            [9.8484669795306452, 50.707262592668712],
            [9.8485089943743187, 50.707299950182033],
            [9.848957352494212, 50.707699066435758],
            [9.8490046675142793, 50.707708335545504],
            [9.8493925256543484, 50.707784281279658],
            [9.849767531181806, 50.707689764361611],
            [9.8500617664834085, 50.707693195029101],
            [9.8500992329822914, 50.708089016032659],
            [9.8502862565285714, 50.708502613920693],
            [9.8503869669963748, 50.708700555943992],
            [9.8503828717316146, 50.708787333708699],
            [9.8503793522639818, 50.708866013440122],
            [9.8502768052550831, 50.709128722758798],
            [9.8501701438350331, 50.709258768113074],
            [9.8501571182954812, 50.709274682044182],
            [9.850136424445715, 50.709397544421492],
            [9.850122876148836, 50.70947774516398],
            [9.8505397215308772, 50.709543115613855],
            [9.8505313916004997, 50.709489784080702],
            [9.8507113080489592, 50.709519569646879],
            [9.8516888601416692, 50.70968164727725],
            [9.8517263869500358, 50.70973603345967],
            [9.8515494171701388, 50.709713404464736],
            [9.8511011304604885, 50.709656082203821],
            [9.8509887677730656, 50.709712893771204],
            [9.8509245479535892, 50.710067107888875],
            [9.8508988076374777, 50.710239712333042],
            [9.850939546258342, 50.710923712315399],
            [9.8509412519373551, 50.710963161779553],
            [9.8509584194865898, 50.711378627606436],
            [9.8509754964910492, 50.711548131616951],
            [9.8510331548669541, 50.711759966888309],
            [9.8511467027650976, 50.711985765145727],
            [9.8513476450810913, 50.712236645513464],
            [9.8515265041662516, 50.712395084447699],
            [9.8518133982734746, 50.712599839883644],
            [9.8525530733872202, 50.713084379516886],
            [9.8528547423610462, 50.713275462685338],
            [9.8531331127220092, 50.713478217793948],
            [9.8533407063970433, 50.713705205215902],
            [9.8535111328338374, 50.713943813710692],
            [9.8536070310544019, 50.714131976545204],
            [9.8536894810090079, 50.714390725999642],
            [9.8537032166116649, 50.714616623014876],
            [9.8537121641727055, 50.715029630353769],
            [9.8537121010554731, 50.715127521129105],
            [9.8537029688374229, 50.715301632206291],
            [9.8536483351260511, 50.715605784474803],
            [9.853618557715599, 50.715859869779784],
            [9.8535912191979858, 50.716092272742337],
            [9.8535851974362725, 50.716227879375282],
            [9.8535752154448737, 50.716448823891504],
            [9.8535959431485836, 50.716607338039381],
            [9.8537228838880324, 50.717045012807027],
            [9.8538274038396025, 50.717520164125759],
            [9.8538905444838054, 50.717931218550213],
            [9.8538816650484105, 50.718059201923303],
            [9.853670109596969, 50.718113612775092],
            [9.8536306681687975, 50.718600762830789],
            [9.8535180638435733, 50.719333115611192],
            [9.8534054806362867, 50.720020799148237],
            [9.853267974213459, 50.72060545973239],
            [9.8532175466498906, 50.720776608308746],
            [9.8530667639137306, 50.721247485735056],
            [9.8529253177950178, 50.721772406272002],
            [9.8528052527946137, 50.722109564565912],
            [9.8526937490326834, 50.722622682571419],
            [9.8526296291536752, 50.723016169096049],
            [9.8526072637290749, 50.723016431573477],
            [9.8519038368979874, 50.723087223193886],
            [9.8511593081249202, 50.723157213463928],
            [9.8508394425180263, 50.723518112995137],
            [9.8505368309677834, 50.723855692484086],
            [9.8505943589177338, 50.724213711736034],
            [9.8506857569977804, 50.724487685906617],
            [9.8501576086926406, 50.724896201661906],
            [9.8499835083293892, 50.725102622088365],
            [9.8499568355760925, 50.725134247490992],
            [9.8498705475757884, 50.725236552714172],
            [9.8497360324427525, 50.725494007948491],
            [9.8495095210095105, 50.725609592369203],
            [9.8491951643157822, 50.725771321366089],
            [9.8485590514802066, 50.726088661359974],
            [9.8479782642036628, 50.726404021692105],
            [9.8475270384791411, 50.726486882590137],
            [9.8471541318884235, 50.726642691459539],
            [9.8465300258809414, 50.72663235873209],
            [9.8459010347071221, 50.726674101276487],
            [9.8453351963911668, 50.726757866062208],
            [9.8451170626239719, 50.726515500054177],
            [9.8448292192872451, 50.726038153968119],
            [9.8439415792973559, 50.726129576192427],
            [9.8433560036158472, 50.725885603182562],
            [9.8427232046574229, 50.725624647818947],
            [9.8425594874809743, 50.725922181201383],
            [9.8421299765110621, 50.72566281108363],
            [9.8416041697660699, 50.725478543553614],
            [9.8410746045581678, 50.725175915371601],
            [9.8404343225182327, 50.72496267410186],
            [9.8397412923743843, 50.724779945173324],
            [9.8389884233667235, 50.72461151911385],
            [9.8381933224832103, 50.724426232943237],
            [9.8379305767387795, 50.724285346783532],
            [9.8377574784305963, 50.72419253164675],
            [9.8373018144576498, 50.723947486845752],
            [9.8368337250233857, 50.723444444948235],
            [9.8364166708969307, 50.722976117203082],
            [9.8360283508029926, 50.722556054827713],
            [9.8357671002170282, 50.722770624079637],
            [9.8357584570700016, 50.722777727647255],
            [9.8357153945562867, 50.722813100487301],
            [9.8356876505842994, 50.722835944019174],
            [9.8356023240743369, 50.722906198278295],
            [9.8355306528553381, 50.722965203151354],
            [9.8342098210573603, 50.724052602958643],
            [9.8341493876273898, 50.724102389552449],
            [9.8337080898685176, 50.724469371362687],
            [9.8333146627369743, 50.724782284472219],
            [9.8329685578404131, 50.72505801337239],
            [9.8322492154009105, 50.725545333960248],
            [9.8317194145691271, 50.725842199957654],
            [9.8312364356232251, 50.726111183823619],
            [9.8305634473382604, 50.726507998440852],
            [9.8299571158663053, 50.726680586088449],
            [9.8291002239629339, 50.726934190815115],
            [9.8287186306662395, 50.727055819514014],
            [9.8283347738215845, 50.727179360583555],
            [9.8278414386437145, 50.727480823973451],
            [9.8275315852331175, 50.727751090071649],
            [9.827174906031896, 50.728030527753013],
            [9.8267355195106987, 50.728260899843384],
            [9.8262160877908897, 50.728443715377971],
            [9.8256472763067872, 50.728605753453323],
            [9.8251278501343897, 50.728753850686225],
            [9.8251202820826062, 50.728786819005997],
            [9.8250641179621567, 50.729031343259045],
            [9.824579047957771, 50.72906093559088],
            [9.8242384389962325, 50.729097409734401],
            [9.8236980670523302, 50.729172327852986],
            [9.823318128250186, 50.729333586651627],
            [9.8232357703546835, 50.729640419284848],
            [9.8230549740717432, 50.729702902690818],
            [9.8228092922862196, 50.729721498723926],
            [9.8223510685612663, 50.729830625155451],
          ],
        ],
      },
      properties: {
        FID: 3,
        OBJECTID: 17,
        gemarkungs: "Kirchhasel",
        FIRST_geme: "Hünfeld",
        shape_Leng: 19388.667426200002,
        shape_Area: 12870480.831800001,
      },
    },
    {
      type: "Feature",
      id: 4,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.7818388885192249, 50.665286031641671],
            [9.7816377798866991, 50.665211253291858],
            [9.7812040984647783, 50.665049945526341],
            [9.7809814937822317, 50.664967216265971],
            [9.7803606511160108, 50.664736329083979],
            [9.7799917585255223, 50.664599107395965],
            [9.7799490361341626, 50.664625311663613],
            [9.7798879917667847, 50.664662736109051],
            [9.7791172998471243, 50.665161175808144],
            [9.7790532915651962, 50.665202558637347],
            [9.7790295768360611, 50.665189757981061],
            [9.7789178330708797, 50.665128892567367],
            [9.7788916836938125, 50.665117744913587],
            [9.7785881258074809, 50.664988454788961],
            [9.7783143094396845, 50.664871825509529],
            [9.7780577338026688, 50.664765773383373],
            [9.7778156121778199, 50.664673788490795],
            [9.7776862799312276, 50.664625575774856],
            [9.7776345414175143, 50.664606288887164],
            [9.7773573249302483, 50.664502837073535],
            [9.776769358404735, 50.664283762369223],
            [9.7767225193566034, 50.664249216965231],
            [9.7759704710993045, 50.663694706764893],
            [9.7759337459368165, 50.663667638983448],
            [9.7748752031799508, 50.662887112741743],
            [9.7748374334808634, 50.662859260158548],
            [9.7746804112455177, 50.662743473556596],
            [9.7746009767314384, 50.662684907327957],
            [9.7745449763848153, 50.662643731006995],
            [9.7744793345968404, 50.662595307271985],
            [9.7743387431604134, 50.662491686714326],
            [9.7741911705428279, 50.662333469776748],
            [9.774037251761591, 50.662192813521585],
            [9.7740309072519214, 50.662187297871242],
            [9.7735807260246297, 50.66179607066443],
            [9.7737003900897932, 50.661771552289771],
            [9.7738270343164402, 50.661745611487923],
            [9.7741191870874715, 50.661643694049253],
            [9.77453329541434, 50.661468336471771],
            [9.7748281139997335, 50.661366111745295],
            [9.7748821227294371, 50.661347388575422],
            [9.7737807080997552, 50.660628767585898],
            [9.7738906984773344, 50.660582288918313],
            [9.7738773571167368, 50.660549615331163],
            [9.7738142403599362, 50.660354153829417],
            [9.7737684283025281, 50.660233643529054],
            [9.773698376731339, 50.660110326380142],
            [9.7735258066679584, 50.659856074381771],
            [9.7734128597878147, 50.659709551551565],
            [9.7734042044024338, 50.659702000754635],
            [9.7733861699553479, 50.659679016935677],
            [9.7733391087560744, 50.659666693801093],
            [9.7733089010195471, 50.659622674743275],
            [9.7732665908925203, 50.659552457883919],
            [9.7732241293003685, 50.65944802293361],
            [9.7727257912425252, 50.65964294605655],
            [9.7725676086254705, 50.659696047339217],
            [9.772477754875144, 50.659726320470945],
            [9.7724523803976719, 50.659661225098532],
            [9.7721982084937675, 50.659009005830022],
            [9.7721522668864154, 50.658898379250978],
            [9.7721443124241603, 50.65887919552366],
            [9.7721080752667486, 50.658791787993046],
            [9.7720652405015365, 50.658850779307443],
            [9.7719477078230561, 50.658890129665302],
            [9.7718396065218514, 50.65885838924109],
            [9.7717721988285575, 50.658834661964761],
            [9.7717096431232324, 50.658812611226637],
            [9.7716085257000529, 50.658828020594385],
            [9.7714558228365558, 50.65885798959043],
            [9.7714526459942963, 50.65881261300612],
            [9.7715198349095154, 50.658806592448819],
            [9.7716234760576928, 50.658699993504982],
            [9.7716070038686755, 50.658645352035542],
            [9.7715003069084343, 50.65858097471105],
            [9.7719389536367238, 50.658341441265343],
            [9.7721340839273125, 50.658247635668431],
            [9.7722283447023326, 50.658202422878517],
            [9.7723260123012725, 50.658166540351445],
            [9.7726890294671858, 50.658099311127756],
            [9.7729091717473011, 50.658071959937345],
            [9.7730332564233482, 50.658058735320701],
            [9.7730907085394225, 50.658052598575708],
            [9.7732152794932645, 50.658317143194203],
            [9.7739919042423082, 50.658186946609391],
            [9.7744786218570621, 50.658106506574704],
            [9.7745490830778206, 50.658246188178168],
            [9.7748228582823717, 50.658219646021593],
            [9.7748865275927113, 50.658170047932195],
            [9.774917648212643, 50.658093263986892],
            [9.774896953913542, 50.658038471179403],
            [9.7747073724014193, 50.657999630945099],
            [9.774664360318468, 50.65796504126763],
            [9.7746307220143116, 50.657871591786389],
            [9.7746317039186135, 50.657750671806781],
            [9.7745303331822431, 50.65763178108709],
            [9.7745178390242273, 50.657551671836259],
            [9.774447744526185, 50.657554655751667],
            [9.7744679087148096, 50.657491236664647],
            [9.7745082684604547, 50.657442872858915],
            [9.7745643169022447, 50.657411662702053],
            [9.7746608816322844, 50.657400966472501],
            [9.7748546339643632, 50.657436352644069],
            [9.7748658112214688, 50.657419200256939],
            [9.7749528391576472, 50.657285486370803],
            [9.7748295253688191, 50.657113250307965],
            [9.7746760871084248, 50.657148795224295],
            [9.7740277456132283, 50.657298990698379],
            [9.7739777407728603, 50.657305411197093],
            [9.7738239967032161, 50.657325866443365],
            [9.7737681696962984, 50.657331830885404],
            [9.773536402574603, 50.657356625866193],
            [9.7728372157699663, 50.657431411519362],
            [9.7728073735863319, 50.657373342530647],
            [9.7726568203139923, 50.657080443306498],
            [9.7725157383861507, 50.656805953109966],
            [9.7725091329615665, 50.656793100658852],
            [9.7724488365636528, 50.656675770458421],
            [9.772359079873846, 50.656501141998831],
            [9.7721396118912942, 50.656413878465926],
            [9.7720884843453248, 50.656282745727466],
            [9.7719834389803033, 50.656013322092122],
            [9.7719555271990775, 50.655941714326168],
            [9.771895569536559, 50.655787932173169],
            [9.7718288667817443, 50.65556870622575],
            [9.7717975385433231, 50.655367151412797],
            [9.7718004137040229, 50.655279304842601],
            [9.7707430782101525, 50.655352006744742],
            [9.7706827813242523, 50.65535615567233],
            [9.7706534025486356, 50.655358148592569],
            [9.7706404029549283, 50.655306361818461],
            [9.7705399130158881, 50.654905741006587],
            [9.7704950772522778, 50.654704841714874],
            [9.770486955045282, 50.654668401033426],
            [9.7704233391724404, 50.654462688606081],
            [9.7700782778243536, 50.65334591903062],
            [9.7698404580570379, 50.653393985129085],
            [9.7695395276086181, 50.653470409361233],
            [9.7688999337704185, 50.653565965987049],
            [9.7688856223898473, 50.653469203699267],
            [9.7684883062110135, 50.653575686837129],
            [9.7683810225830037, 50.653604444030307],
            [9.7682308295065159, 50.652842037843065],
            [9.768069200983156, 50.652019128568483],
            [9.768727930302159, 50.651849112438839],
            [9.7687250436273718, 50.651810919383841],
            [9.7700461226152129, 50.651557420543817],
            [9.7718023126101308, 50.651217847688265],
            [9.7718287520751055, 50.651211880958058],
            [9.7720726669144415, 50.651156737692098],
            [9.7713476842237039, 50.649897004667757],
            [9.7717675460859592, 50.649797008298549],
            [9.7717340002361084, 50.649751652909472],
            [9.7708365278390339, 50.648541415105718],
            [9.7699307247884342, 50.647319704986373],
            [9.7699994315385865, 50.647295904657391],
            [9.7699885646365239, 50.647283547851899],
            [9.7699674939171821, 50.647259594279042],
            [9.7697145080710666, 50.646971441546611],
            [9.7695958563144529, 50.646866141352653],
            [9.7695490322700067, 50.646847573839494],
            [9.7694610892676597, 50.646796011957044],
            [9.7693608874267284, 50.646668484396059],
            [9.7692738475323715, 50.646576914629641],
            [9.7691796381050615, 50.646521805612224],
            [9.7690592839044985, 50.646398718551239],
            [9.7689614550521959, 50.646303802333676],
            [9.7688015832173729, 50.646098373165081],
            [9.7687806652064921, 50.646072439862323],
            [9.7685502730592209, 50.645860092139536],
            [9.7679854076295314, 50.645259985312812],
            [9.7679030684236103, 50.645168392525406],
            [9.7678750142190598, 50.645138459139979],
            [9.7680534161199013, 50.645032107860267],
            [9.768189297341614, 50.64489429958558],
            [9.7681692482496132, 50.64480102829242],
            [9.7683417760235951, 50.644777776555465],
            [9.7686221084136129, 50.644793626474325],
            [9.7691137734261133, 50.644652471595002],
            [9.7694182579601474, 50.644416008591477],
            [9.7695889834589558, 50.644437535098007],
            [9.7696760481494209, 50.644634720739077],
            [9.7697989055799184, 50.644653235609631],
            [9.7700219028217354, 50.64455161389084],
            [9.7701986091862025, 50.644533179127464],
            [9.7703622315530509, 50.6445449578449],
            [9.7705400290320625, 50.644575951022865],
            [9.7707364508847476, 50.644543643451044],
            [9.7707111369082824, 50.644526543914658],
            [9.7705713953824702, 50.644453759717024],
            [9.770354417412733, 50.644404409664737],
            [9.7704233072324058, 50.644346235806509],
            [9.7703032304932922, 50.644221808167323],
            [9.770262815868854, 50.644119327892412],
            [9.7701876505895129, 50.64403586410431],
            [9.7711833398175276, 50.643291125164694],
            [9.7714268288655592, 50.643017208038103],
            [9.7715232553249241, 50.642889270726158],
            [9.7716405673183129, 50.642766312167872],
            [9.7714764704383636, 50.642682008135765],
            [9.7715798472235953, 50.64258126519497],
            [9.7716466396733637, 50.642516179699982],
            [9.7717885524438319, 50.642281425315346],
            [9.7718595915961775, 50.642121648999215],
            [9.7724702805752219, 50.641783351498326],
            [9.7725132157155414, 50.64172023127886],
            [9.772592080238784, 50.641590251095685],
            [9.772682317425927, 50.641429366703072],
            [9.772760042321492, 50.641290319800206],
            [9.7727797337535272, 50.64125525956397],
            [9.7728210403877505, 50.641087729796439],
            [9.7729009421510149, 50.640719332347011],
            [9.7728947940996012, 50.640646285256381],
            [9.7729467229788849, 50.640528894097933],
            [9.7729975295187934, 50.640462933572344],
            [9.7730544434715689, 50.640321857131077],
            [9.7730980435075292, 50.640214053962104],
            [9.7731442521526102, 50.640143717196274],
            [9.7732016408495799, 50.63996353501377],
            [9.7732232317652201, 50.639858126133049],
            [9.7732552841912685, 50.639712852835324],
            [9.773132375699948, 50.639171629125308],
            [9.773707559530262, 50.638577262667454],
            [9.7742540323523297, 50.638061891650786],
            [9.7744326313584171, 50.638227322201672],
            [9.7751840189795001, 50.638135829176072],
            [9.7752554055171732, 50.638127179412919],
            [9.7753282029383062, 50.638118331360587],
            [9.7753942263676663, 50.638110337729429],
            [9.7755061725214869, 50.638093171122847],
            [9.7754604447573055, 50.63804544265146],
            [9.7755594928243017, 50.637948322156205],
            [9.7756143498803141, 50.637649409038879],
            [9.775593578853961, 50.637537887447863],
            [9.7758798235860258, 50.637528787243944],
            [9.7761475522203689, 50.637520259332256],
            [9.7762917779980878, 50.637515737259363],
            [9.7760587565909827, 50.636943998454257],
            [9.7759821779012004, 50.63678320599638],
            [9.7759651200337316, 50.636606935494655],
            [9.7759612017706825, 50.636566501073354],
            [9.7760157711794715, 50.636283858346488],
            [9.7761282631169255, 50.636290663428511],
            [9.7773534868399405, 50.636364658178572],
            [9.7777164288282865, 50.636386580991555],
            [9.777807840255937, 50.636392742739702],
            [9.7787753680425382, 50.636457830418202],
            [9.7790422113460913, 50.636473133803399],
            [9.7798102303884562, 50.636517261376532],
            [9.7806669319685504, 50.636462439858775],
            [9.7814499119145353, 50.636404113476061],
            [9.7828690729238854, 50.636349135570967],
            [9.7841266842176058, 50.636305344998334],
            [9.785125849349555, 50.636006912575709],
            [9.7859816179027028, 50.63575122143385],
            [9.787937476977266, 50.635476398993426],
            [9.7894892959852413, 50.635038707534108],
            [9.7900479956963515, 50.63504356322634],
            [9.7903736536557719, 50.63411153536822],
            [9.7905209911386137, 50.633835450826645],
            [9.790908638492585, 50.633109161595605],
            [9.7916570481170293, 50.632168548747451],
            [9.7928358738249202, 50.630929035442655],
            [9.7932949970976004, 50.63113722462478],
            [9.7933150734449068, 50.631146350973019],
            [9.7933861586529147, 50.631178584371703],
            [9.7934197707562856, 50.63119383286292],
            [9.7938915818856849, 50.631407859843449],
            [9.7945536487487654, 50.631957447445252],
            [9.7953951718418359, 50.632386583022971],
            [9.7960410170909089, 50.63313925925133],
            [9.7965178327940521, 50.633896891575667],
            [9.7969429622606103, 50.634649308591406],
            [9.797196407911537, 50.635119252087172],
            [9.7978210722437122, 50.636046882632641],
            [9.7980237463242368, 50.636911426211249],
            [9.7980771003287312, 50.637139309036705],
            [9.7981813913917719, 50.637745341067074],
            [9.7989446552608417, 50.637756073222533],
            [9.7994618998452143, 50.638367114026899],
            [9.8001961658874812, 50.639210450388148],
            [9.8008834116042571, 50.639622214393611],
            [9.8009486207607583, 50.639661291433256],
            [9.801397865977556, 50.639841979275033],
            [9.8017395325291297, 50.640027560492122],
            [9.8019759492572334, 50.640165481554007],
            [9.8024373563355969, 50.640461051130714],
            [9.802468623654315, 50.640501647082147],
            [9.8025048948843168, 50.640529636029306],
            [9.8027008400734665, 50.640680811566298],
            [9.8029126715718871, 50.640859549475245],
            [9.8029344820076769, 50.640877961214173],
            [9.8031170294980825, 50.641032016266962],
            [9.8032823337661998, 50.641157600428109],
            [9.8034858218285805, 50.641346907889947],
            [9.8035778791216153, 50.641432464188405],
            [9.803731282107174, 50.641584830675697],
            [9.8040144023457039, 50.641907002459426],
            [9.8041434663887674, 50.642015334617263],
            [9.8043232182589879, 50.642103720528922],
            [9.8045295957566321, 50.642160266176447],
            [9.8045889249900888, 50.642190055796036],
            [9.8047556432658727, 50.642325088972022],
            [9.8047612889078746, 50.642481738979043],
            [9.8047632912500386, 50.642507886447859],
            [9.8047669533244939, 50.642548402623909],
            [9.8048874074026759, 50.642665822615776],
            [9.8049287871314537, 50.642707040528649],
            [9.8053280071176747, 50.643087798329304],
            [9.8053912340259988, 50.643169271653676],
            [9.8054837320510249, 50.643288467048635],
            [9.8056135084997091, 50.643426461297075],
            [9.8057583608281522, 50.643611493644656],
            [9.8058463641666798, 50.643696023961702],
            [9.8060886326597103, 50.643862530623842],
            [9.8062831323645305, 50.643933535502711],
            [9.806616453066205, 50.64409965384074],
            [9.806843857381585, 50.644232060585942],
            [9.8068868587841234, 50.644257123731684],
            [9.8069858297149555, 50.644257607498034],
            [9.8071343561309323, 50.644173670280757],
            [9.8071707684369223, 50.644113801973248],
            [9.8071878335766733, 50.644207555053164],
            [9.8071953870348931, 50.644299233629567],
            [9.8071803033483587, 50.644461476970562],
            [9.8072926579140489, 50.644518282270653],
            [9.8072666819045491, 50.644596217648328],
            [9.8071633821730355, 50.644906956655198],
            [9.8070801349762782, 50.645152346802682],
            [9.8070057349017628, 50.645380039898178],
            [9.8069858125800504, 50.645477025889228],
            [9.8070022667413728, 50.645594552295272],
            [9.8070624665613639, 50.645778019812468],
            [9.8071050797153472, 50.64589591361058],
            [9.8083360841026348, 50.645691661452211],
            [9.8084783113688587, 50.645667472255425],
            [9.8094170368275524, 50.645507872973951],
            [9.8094827101064368, 50.645628222770718],
            [9.8094432713541497, 50.645678130332954],
            [9.8094841549924379, 50.645762526536991],
            [9.80961814471768, 50.645753663665445],
            [9.8097859736191033, 50.645755887959581],
            [9.8099506962781167, 50.645767477562302],
            [9.8102381205132669, 50.645800562008858],
            [9.8104240133443739, 50.64583660021249],
            [9.8112342659816569, 50.64610578632319],
            [9.8113539869334385, 50.64614549426922],
            [9.8131674064202237, 50.647147514556693],
            [9.8129229882724331, 50.647247398900966],
            [9.81275424773259, 50.647316447772319],
            [9.8123885463366456, 50.647466001030338],
            [9.8119367297086448, 50.647672963360002],
            [9.8115465980258456, 50.647989355548738],
            [9.8116925744569521, 50.648042891734256],
            [9.8131521710387108, 50.648607625570634],
            [9.813273897151598, 50.648661418924505],
            [9.8132333472323694, 50.648690300357636],
            [9.8131952126839828, 50.648717348290589],
            [9.813070376461571, 50.648833296623501],
            [9.8128649502802503, 50.648980905554573],
            [9.812248545094878, 50.649393864535078],
            [9.812692626445596, 50.649679711541218],
            [9.81306961701031, 50.649887415245011],
            [9.8131721345793448, 50.649791938365503],
            [9.8145403704516987, 50.649742524779676],
            [9.8146640216543837, 50.649738063131004],
            [9.8151770234516924, 50.64965940028106],
            [9.8153255214015527, 50.649636606805217],
            [9.8161508621060882, 50.649540879166423],
            [9.8165845526586253, 50.649551456695328],
            [9.8166232078335121, 50.649587149711515],
            [9.8179187192009465, 50.649959196732866],
            [9.8184868120629485, 50.650122203167307],
            [9.819267276708576, 50.65046023293619],
            [9.8203330788423138, 50.650945869434246],
            [9.8203867685670154, 50.650983640719581],
            [9.8210311082310504, 50.651430382675322],
            [9.8212219450753189, 50.651577848815514],
            [9.8213673714061169, 50.65169022840886],
            [9.821540360478048, 50.651824033214034],
            [9.8216795797487428, 50.651929198646037],
            [9.8220613183381431, 50.652027931939621],
            [9.8226546408043731, 50.651891050037165],
            [9.8231414204287919, 50.651796664247861],
            [9.8232395176758001, 50.651776995600805],
            [9.8237078672649929, 50.652363119750227],
            [9.8240636150195133, 50.652651958019256],
            [9.8248684234594403, 50.652826056600745],
            [9.8258446410545393, 50.65285536727437],
            [9.8267394912194135, 50.652720284395805],
            [9.827124432068052, 50.652698316362113],
            [9.8280626087723348, 50.652768617739639],
            [9.8288641831741224, 50.652828722652963],
            [9.8293356501837117, 50.652885955698423],
            [9.8300940604060987, 50.652920737642674],
            [9.8306181247480247, 50.652967500439992],
            [9.8306787092842143, 50.652972905202923],
            [9.8314146506306344, 50.653161146180878],
            [9.8326676078585322, 50.653542452213131],
            [9.8336463711778173, 50.653723242160041],
            [9.8344869758160751, 50.65380038614726],
            [9.8352082436398405, 50.653989220891333],
            [9.8359753008960791, 50.654207841019456],
            [9.8366030334777061, 50.654471774989368],
            [9.8371895795187552, 50.654756497024501],
            [9.83751880418437, 50.655052499582006],
            [9.8378748845451902, 50.655320707922783],
            [9.8381854785121856, 50.655675910016534],
            [9.8386131883606556, 50.656148754246971],
            [9.8393832484434238, 50.656930620752043],
            [9.8389474677008302, 50.657108613879203],
            [9.8385657862653471, 50.65720332559588],
            [9.8380911619004383, 50.657088613899056],
            [9.8376260257253136, 50.656975891523089],
            [9.8370741761358556, 50.656914547901991],
            [9.8368617577022306, 50.656890937422411],
            [9.8361812028482536, 50.656829110794533],
            [9.8355110092835965, 50.656768285097961],
            [9.8348792003250836, 50.656719492286093],
            [9.8342784493115634, 50.65681781860512],
            [9.8337210966691817, 50.656897575121974],
            [9.8332366610312256, 50.656982302498697],
            [9.8326657940326729, 50.657078868972775],
            [9.832637623783226, 50.657241146000331],
            [9.8320502132516641, 50.657512224091583],
            [9.8314504060690471, 50.657881251807844],
            [9.8308780489109822, 50.657959132206116],
            [9.8304841443826554, 50.65799340717389],
            [9.830015260492269, 50.658190056733702],
            [9.8295046715389915, 50.658386281788843],
            [9.8289695092774902, 50.658530599689684],
            [9.8286250653702449, 50.658746098484002],
            [9.8282918218480528, 50.658957478688322],
            [9.8279781948187672, 50.659151739374877],
            [9.8276136833575318, 50.659429421871423],
            [9.8273365732497169, 50.659773814934468],
            [9.8270491016856454, 50.660128632025469],
            [9.8267750858427352, 50.660317733974345],
            [9.8264854913365216, 50.660487547396841],
            [9.8258614271187756, 50.66071644192769],
            [9.8256498467717872, 50.660660591386609],
            [9.8252501305326394, 50.660441461143691],
            [9.8243440570121479, 50.660018429976638],
            [9.8229671041769553, 50.659551620453932],
            [9.8222405292381705, 50.659216481925831],
            [9.821412370049611, 50.659057458465512],
            [9.8209937098721198, 50.659077423704808],
            [9.8197407407523656, 50.659029226819555],
            [9.8192405278794759, 50.659099437849612],
            [9.8190524456319555, 50.659124762722968],
            [9.8181629854864028, 50.659244450807101],
            [9.8169074437122976, 50.659465588056129],
            [9.815787154553858, 50.659673264498039],
            [9.8147818751443374, 50.659793926253307],
            [9.8145650346618751, 50.65981575771049],
            [9.8139513468612432, 50.659878375386668],
            [9.8130934329092891, 50.659860073447312],
            [9.8129724800664828, 50.659874047333979],
            [9.8124297517385521, 50.659935523450116],
            [9.8115838948034391, 50.659946129425528],
            [9.8105921344010074, 50.659865168135262],
            [9.8106680618095119, 50.659043821303207],
            [9.8104671252381088, 50.659029480755073],
            [9.8102656666263677, 50.659049749340198],
            [9.8099895797262437, 50.659064420908088],
            [9.8098784272868418, 50.659064923597903],
            [9.8097525998648027, 50.659051237946663],
            [9.8096673456750167, 50.659043115866766],
            [9.8093216804336834, 50.658966520560853],
            [9.8092293320739685, 50.658898759531766],
            [9.808955992421085, 50.658973664041817],
            [9.8087340565823791, 50.65864685350757],
            [9.8072766284064397, 50.658387135140735],
            [9.8071391855544157, 50.658362564415967],
            [9.8064566344402113, 50.658240908163854],
            [9.8059308427821605, 50.658147292944662],
            [9.8057896998046079, 50.658122197633048],
            [9.8049032065517032, 50.657964368621961],
            [9.8048319609607866, 50.657933042715747],
            [9.8046977777044582, 50.657875531479291],
            [9.8045101304192883, 50.657792326875025],
            [9.8042950165501228, 50.657707072224049],
            [9.8038806713774509, 50.65759828045239],
            [9.803070337287668, 50.657384082347789],
            [9.803018659944895, 50.657064873936989],
            [9.8029988874209533, 50.656999656564103],
            [9.8029777462250305, 50.656937137585771],
            [9.8029138185931881, 50.656869355594189],
            [9.8029035830919167, 50.656866458355324],
            [9.8027714295528536, 50.656827277055477],
            [9.8026295031040984, 50.656813155029404],
            [9.802095102562145, 50.656851449176564],
            [9.8017203275074962, 50.656889002579646],
            [9.8015249826731772, 50.656908242747825],
            [9.801065724874265, 50.656973615475032],
            [9.8009201642617949, 50.65698620817551],
            [9.8007534760938508, 50.656995987101638],
            [9.8004372280222221, 50.656955378978488],
            [9.8003491534483071, 50.656988260481832],
            [9.8002739850972098, 50.657016376826441],
            [9.8000975364264882, 50.657107844147653],
            [9.7999027888047365, 50.657262281299623],
            [9.799530350374928, 50.657714398217003],
            [9.7990255362303369, 50.657629421293997],
            [9.7990948697717677, 50.657702564253299],
            [9.7990196081131984, 50.657716120427864],
            [9.7986792538264975, 50.657388834642326],
            [9.7985390619946688, 50.657274295641876],
            [9.7985215330150961, 50.657281790176164],
            [9.7982780880511147, 50.657388912273824],
            [9.7984072922726178, 50.657525461279739],
            [9.7985141290389883, 50.657674475094126],
            [9.7986295898115774, 50.6578556253695],
            [9.7986988848202614, 50.657977395062368],
            [9.7987271712274513, 50.658038588789111],
            [9.7987581231291969, 50.658106787929214],
            [9.7987677716125123, 50.658176832791106],
            [9.7987532117538514, 50.658297666300271],
            [9.798666852148747, 50.658469416753135],
            [9.7985190805908626, 50.658681490764486],
            [9.7984196439317568, 50.658790495135939],
            [9.7980959202809448, 50.659084056192796],
            [9.7980391843438994, 50.659057204318216],
            [9.7979099106532299, 50.658996063382141],
            [9.7977718028601402, 50.658947923989643],
            [9.797611407026972, 50.658902814799887],
            [9.7974587907743729, 50.658874289574463],
            [9.7973148654629121, 50.658867720041656],
            [9.797170902308487, 50.658883984321939],
            [9.7970193577019913, 50.658886990339475],
            [9.796922186757552, 50.658875963689383],
            [9.7964772500434147, 50.658752327180039],
            [9.7962877942355693, 50.658694545505405],
            [9.7966263022581188, 50.658235719310603],
            [9.7964413201218701, 50.65832857037536],
            [9.7962583101731298, 50.658412594333342],
            [9.796045478986068, 50.658529440035345],
            [9.7958538656210976, 50.65866034071054],
            [9.7957031762472013, 50.65878592546575],
            [9.7956297362520957, 50.658847166928325],
            [9.7954165482174886, 50.658994186080527],
            [9.795256614434237, 50.659048355405289],
            [9.7951631587630565, 50.659072177557221],
            [9.7951112101646753, 50.659077379325907],
            [9.7949573127356366, 50.659091910005237],
            [9.7950319150867138, 50.659643277638345],
            [9.7950513330405506, 50.660097832188562],
            [9.7949423053673623, 50.660339152908804],
            [9.7948455000334675, 50.660556117522106],
            [9.7947566212895705, 50.660764115734075],
            [9.7947362035149741, 50.660811810985088],
            [9.7946408067666102, 50.661035222914826],
            [9.7943914200407498, 50.661201075853597],
            [9.7942933743799241, 50.661265928547017],
            [9.793811530339223, 50.661611897177288],
            [9.7936462577970591, 50.661741130817418],
            [9.7933832054951324, 50.661943649936966],
            [9.793059931599787, 50.662483768999969],
            [9.7930875705338369, 50.662637832139652],
            [9.7929742482154865, 50.66259401500497],
            [9.792678608990796, 50.662624615535726],
            [9.7924462798557457, 50.662668265646651],
            [9.7922317090275541, 50.662708646962102],
            [9.7915747741493711, 50.662767222781063],
            [9.791432665253339, 50.662835699786434],
            [9.7913907611339166, 50.66279984071052],
            [9.7911282744977921, 50.6629249554342],
            [9.7910463273338806, 50.66296398454746],
            [9.7909087377004393, 50.662855156472709],
            [9.7907801416478843, 50.662701328239827],
            [9.7906410510593815, 50.662300815893019],
            [9.7904497522796206, 50.662328364643074],
            [9.7904039099470097, 50.662335132667017],
            [9.7901523684662202, 50.662371354222259],
            [9.790039365090129, 50.662387660548539],
            [9.7900053780980709, 50.662392657328844],
            [9.7899269203263177, 50.662411966918242],
            [9.7899097394971459, 50.662415950439311],
            [9.7894921380823714, 50.662515097169099],
            [9.7891291641351366, 50.662683443838453],
            [9.7886517617645907, 50.662969951853505],
            [9.7886058341915803, 50.662946340751802],
            [9.788535922618701, 50.662909141323425],
            [9.7885606114091264, 50.663023943205033],
            [9.7885157070393713, 50.663051289546132],
            [9.7884689530516606, 50.66307971857254],
            [9.7879966720136302, 50.663266059061613],
            [9.787965923372294, 50.663277760253706],
            [9.7879191580160985, 50.663295397308246],
            [9.7878311547352084, 50.663330382186587],
            [9.7876585420123394, 50.663383340673462],
            [9.7869322890803385, 50.66354521382614],
            [9.7860120029389481, 50.663751315692011],
            [9.7846876873239061, 50.664121442026243],
            [9.7839401647230293, 50.664406799185876],
            [9.7839291813000653, 50.664462684769717],
            [9.7837720215628057, 50.664345409451983],
            [9.7837265729110818, 50.664394700963818],
            [9.7818388885192249, 50.665286031641671],
          ],
        ],
      },
      properties: {
        FID: 4,
        OBJECTID: 20,
        gemarkungs: "Mackenzell",
        FIRST_geme: "Hünfeld",
        shape_Leng: 17709.847967099999,
        shape_Area: 8843586.0800700001,
      },
    },
    {
      type: "Feature",
      id: 5,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.8090561558847345, 50.739842425133176],
            [9.8092484671574809, 50.7401273134519],
            [9.8094929395199522, 50.740495448022209],
            [9.8092421223600041, 50.74058311779293],
            [9.8095265151834212, 50.741082400084053],
            [9.8093665358550464, 50.741114608652978],
            [9.8093270898649099, 50.741126753299071],
            [9.8094286979723737, 50.741369481695415],
            [9.8095533567012954, 50.741700415958313],
            [9.809659724433498, 50.741930529775964],
            [9.8097667418582652, 50.742131690261431],
            [9.8097025297919043, 50.742178882164254],
            [9.8088001204519664, 50.742450602352498],
            [9.8084170013763341, 50.74099424905728],
            [9.8080557384632385, 50.740861632018593],
            [9.8079627735032719, 50.740827490963802],
            [9.8071966233225609, 50.740546106604242],
            [9.8070698864280708, 50.740499599308265],
            [9.8070752640691126, 50.740449668486121],
            [9.8071544230782788, 50.739704709419982],
            [9.8064101171398423, 50.739616169178824],
            [9.8060698745303334, 50.739577702061332],
            [9.8056820700085385, 50.739525173654478],
            [9.8056644787137426, 50.739523316719485],
            [9.805629008033053, 50.739743172792572],
            [9.8050162857322327, 50.739693814633135],
            [9.8050112029515581, 50.739356024688774],
            [9.8050379296804238, 50.739212904163146],
            [9.805086452359669, 50.73889451988326],
            [9.8050675389779052, 50.73867584881063],
            [9.805140885879057, 50.738310106387416],
            [9.805154291632034, 50.738265426125295],
            [9.8052158520082866, 50.738071910585923],
            [9.8051477737838226, 50.738059700427705],
            [9.8046941800422811, 50.737978044750456],
            [9.8046619170981586, 50.737971792371305],
            [9.8047297033191025, 50.737810690153346],
            [9.8048051007323807, 50.737638923521992],
            [9.80495695135461, 50.737233897097603],
            [9.8049966900803884, 50.73705626117944],
            [9.804319766746131, 50.736987198111173],
            [9.8040171906153724, 50.736905226469887],
            [9.8036629259389461, 50.73747785705865],
            [9.8035350337308653, 50.737810349332534],
            [9.8035008705313231, 50.738022650597138],
            [9.8034061177322744, 50.738008563810638],
            [9.8033441981506932, 50.737999277884818],
            [9.8024837650793781, 50.737871196632611],
            [9.8024279134991712, 50.738122433982944],
            [9.8023694736866389, 50.738490949875143],
            [9.802337254459669, 50.73869460402841],
            [9.8022441957206201, 50.739043501296116],
            [9.8021874790255374, 50.739255867348],
            [9.8021259459896815, 50.739484984627062],
            [9.8020849847204765, 50.739649048325795],
            [9.8020795219912884, 50.739900883089078],
            [9.8021508567987219, 50.739905338590354],
            [9.8027188462123469, 50.739941170409971],
            [9.8027087799600796, 50.739990683646695],
            [9.8026844599621743, 50.740730144961809],
            [9.8019620921156001, 50.740911658260522],
            [9.8018016949764384, 50.740952034020012],
            [9.8014893847375664, 50.741030856001643],
            [9.801594739722308, 50.741243906219601],
            [9.8016892343015591, 50.741492355801618],
            [9.8017454977511083, 50.74170798263917],
            [9.8017574600103288, 50.741753648164099],
            [9.8016764531646459, 50.741763108246559],
            [9.801754436665453, 50.741957550945429],
            [9.8017678301079449, 50.742004024997179],
            [9.801805473165901, 50.742134291651816],
            [9.801944050729066, 50.742482404885052],
            [9.8018513133136, 50.742452385225725],
            [9.8016802035123725, 50.742396923327881],
            [9.8012702508676348, 50.742275141937036],
            [9.8008671074368543, 50.742146117883394],
            [9.800573007267964, 50.742039177280226],
            [9.8003681803437495, 50.742282029352651],
            [9.8002816713935772, 50.742376196011186],
            [9.8002077070782008, 50.742350722606304],
            [9.799334715969156, 50.742051134374911],
            [9.798980474257144, 50.74194029432077],
            [9.7989651053466655, 50.741935813155933],
            [9.7988446496020334, 50.741900854968932],
            [9.7985683141433189, 50.741820568991372],
            [9.7983834623302961, 50.741772579683996],
            [9.7983172945174442, 50.741755406266975],
            [9.7976562704500338, 50.741583638581666],
            [9.7974033415288559, 50.741512722393161],
            [9.7970875565941551, 50.74142509452939],
            [9.797116908891244, 50.741380872793982],
            [9.7973526576462007, 50.741017739469541],
            [9.7977016091550908, 50.740732763509165],
            [9.7978552486677835, 50.740772451016333],
            [9.7979592224444954, 50.74080016667655],
            [9.7982642406515517, 50.740371384601254],
            [9.798467570796662, 50.739818680973343],
            [9.7985898415236257, 50.739510721111543],
            [9.7986131096476203, 50.739445568791233],
            [9.7987353603502196, 50.739100719286498],
            [9.7988292105691155, 50.738835958697535],
            [9.7984837479727549, 50.738762342290215],
            [9.798386056385576, 50.738740447571836],
            [9.7982858019932344, 50.738717850870316],
            [9.798067847246541, 50.738629268100759],
            [9.7976529616941441, 50.73841482510926],
            [9.7972462316030118, 50.738204722498601],
            [9.7974951146101521, 50.73637545594881],
            [9.7969640548825563, 50.736111344784902],
            [9.7968922833622223, 50.73608245490437],
            [9.7968289566146893, 50.73605682571916],
            [9.79612161713235, 50.735770245417626],
            [9.7960893751751943, 50.735806869538763],
            [9.795938011647614, 50.735978168839374],
            [9.7957543606638957, 50.736186011333778],
            [9.7955597382680715, 50.736406329840278],
            [9.7953496684577566, 50.736644289880559],
            [9.7951856329791838, 50.736829973596862],
            [9.794975548451907, 50.73702459539026],
            [9.7948764216855331, 50.737136110853591],
            [9.7945740953731768, 50.737017727043899],
            [9.7942218383944351, 50.736879708919474],
            [9.7945448222434699, 50.736509646667898],
            [9.794338612854169, 50.736404798349966],
            [9.7941728586692296, 50.736320619026337],
            [9.7939570893622943, 50.736210978880948],
            [9.7941421976060372, 50.736033525922785],
            [9.7945531947461557, 50.735724709549544],
            [9.7943220829217505, 50.735613564855107],
            [9.7942623113535188, 50.735598602833335],
            [9.7937083407920991, 50.735459708692943],
            [9.7939559928260653, 50.735120197610406],
            [9.7942132028950954, 50.73480057652224],
            [9.7943075033361051, 50.734690443410535],
            [9.7944017091265376, 50.734582280349422],
            [9.7945640797611606, 50.734459281793249],
            [9.794719720815305, 50.734341445936735],
            [9.794596886943884, 50.734298594655833],
            [9.7944849013404554, 50.734259536356774],
            [9.7946038335238512, 50.73415723001024],
            [9.7943209084737344, 50.734012633374576],
            [9.7942563275458561, 50.733979628014779],
            [9.7939795010565867, 50.733851976944564],
            [9.7937118679539168, 50.733714819901564],
            [9.7935878172704491, 50.733650122648591],
            [9.7933911709206889, 50.733547635684552],
            [9.7936782310964805, 50.733343598528812],
            [9.7929842160715861, 50.732819554025355],
            [9.792801533334579, 50.732662571930192],
            [9.792711281075448, 50.732569243773682],
            [9.7925792425802989, 50.732324000616337],
            [9.7925135368665934, 50.732209011977332],
            [9.7924050885004643, 50.732018906756998],
            [9.7922405060486373, 50.731751050913843],
            [9.7921766095736036, 50.73167434234481],
            [9.7920114958468396, 50.731668089658889],
            [9.7918947306358053, 50.731663567809171],
            [9.7918203708845741, 50.731658748889508],
            [9.7918160779683419, 50.731615916994684],
            [9.7917406202824928, 50.731608164741047],
            [9.7917474687104828, 50.731376429363486],
            [9.7917531221439003, 50.730915783067573],
            [9.7917598359661699, 50.730748358142542],
            [9.7917601405371855, 50.730657993533761],
            [9.7911381422044119, 50.730568192734381],
            [9.7912455405570658, 50.730360874781681],
            [9.7913586895991784, 50.730142555117112],
            [9.7914189024446419, 50.730042898721685],
            [9.7915422224316817, 50.729838808839062],
            [9.7916671649865918, 50.729664636799519],
            [9.7917688655178292, 50.729447383673175],
            [9.7911990437127923, 50.729359486165812],
            [9.7908753168137963, 50.729304576282587],
            [9.7907772108806839, 50.729288667413755],
            [9.7905419005437846, 50.729576405142176],
            [9.790339505457057, 50.729892094618009],
            [9.7902284434882993, 50.73007637829884],
            [9.7901519388442892, 50.730230273869466],
            [9.7899923445899031, 50.730471830409726],
            [9.7897581416711077, 50.730435052436754],
            [9.7893014876242805, 50.730371020147288],
            [9.7892221432257802, 50.730355279737388],
            [9.7890682738070964, 50.730324737395208],
            [9.7889535843449149, 50.730452010229037],
            [9.788656443464367, 50.730750369555793],
            [9.7885050400036473, 50.730605120966537],
            [9.7884755604455531, 50.730576857199075],
            [9.7883455271529574, 50.730362468932171],
            [9.787867895575463, 50.730149206691117],
            [9.7876324439170848, 50.730041990045102],
            [9.7875621077652006, 50.730009919162804],
            [9.787248941727924, 50.729876679037723],
            [9.7869534721061058, 50.729750881823605],
            [9.7867492814732007, 50.729663938657268],
            [9.786121838623334, 50.729449800459207],
            [9.7859714379542666, 50.729398474605681],
            [9.7857481259298549, 50.729316829604315],
            [9.7856447807648959, 50.729286616746521],
            [9.7855937318583752, 50.729274909964637],
            [9.7855025743816793, 50.729254138544412],
            [9.7851145587725927, 50.729096009428105],
            [9.7847096430906877, 50.728962534894194],
            [9.7845242938097599, 50.728926676663143],
            [9.7845500390443316, 50.72881640107331],
            [9.7845470887113919, 50.728667756201197],
            [9.784543300148961, 50.728469771894133],
            [9.7845245307022335, 50.728457307861255],
            [9.7844964461859405, 50.728438539392926],
            [9.784390127378634, 50.728367543793127],
            [9.7842586064188986, 50.728279810606757],
            [9.7841704582772753, 50.728210841934747],
            [9.7840512928831238, 50.728207965132057],
            [9.7840056219019989, 50.728212768774448],
            [9.7838551806891996, 50.728228259108278],
            [9.7838643624306059, 50.728083939261531],
            [9.7839271697308661, 50.72709722698967],
            [9.7839144085362264, 50.72696166102493],
            [9.7840551098926891, 50.726965571240584],
            [9.7841558050061064, 50.72673809734578],
            [9.7842644373798571, 50.72654480666047],
            [9.7844234027280912, 50.72631297460542],
            [9.7845812612800902, 50.725897052195585],
            [9.7847289840563985, 50.72534185878164],
            [9.7852445962099903, 50.725138273117764],
            [9.7857220835222201, 50.72493161463531],
            [9.7862498826883204, 50.724703319273367],
            [9.786730064388518, 50.724489722768745],
            [9.7871284326348338, 50.724312181353753],
            [9.7870050323568307, 50.724217462720489],
            [9.7872375658213979, 50.724116015600806],
            [9.7874803437717866, 50.724026540577903],
            [9.7876113380091851, 50.723975042631842],
            [9.7877258567261709, 50.723930400670369],
            [9.787817983119643, 50.723901369031509],
            [9.7881977787611927, 50.723781846969857],
            [9.7882810306065959, 50.723748288476671],
            [9.7883322393947054, 50.723727626846141],
            [9.7885604071246135, 50.723635649337169],
            [9.78862234942636, 50.72361383581277],
            [9.7885858735596187, 50.723566886750177],
            [9.7887478158804502, 50.723513388570822],
            [9.7890145238821233, 50.72342266027993],
            [9.789664115478649, 50.723231475949298],
            [9.7898778681246963, 50.723157921405281],
            [9.7900358480179328, 50.723103171250393],
            [9.790758219515185, 50.722852897581262],
            [9.7908147978527591, 50.72285677657441],
            [9.7917035613541934, 50.722919650166268],
            [9.7917465344584098, 50.722916516541304],
            [9.7924761081781959, 50.722959547185994],
            [9.7931305609521502, 50.722997940247552],
            [9.793923542606521, 50.723035503348378],
            [9.7943725709474609, 50.723051213524442],
            [9.7945532016395553, 50.723047734449253],
            [9.7950205150536558, 50.723038856289342],
            [9.795136168731144, 50.723036709667504],
            [9.7953458413772356, 50.723025647968043],
            [9.7957245756212661, 50.723005599223711],
            [9.7963367716598828, 50.722983028652962],
            [9.796723223339125, 50.722968652557782],
            [9.7970006163085266, 50.722973546510737],
            [9.7979046302163457, 50.722989450517062],
            [9.7980522401084542, 50.72299201952017],
            [9.7984818196636514, 50.722999501392401],
            [9.798931416493069, 50.722920267926703],
            [9.799747737755478, 50.722682505499243],
            [9.7997957303779835, 50.722673012323874],
            [9.8001410942604856, 50.722604597381775],
            [9.8004908350116882, 50.722534532604982],
            [9.800709787558775, 50.722517048405848],
            [9.8012880641458864, 50.722464881604779],
            [9.8017815252165477, 50.722419599380864],
            [9.8023971777216943, 50.72237439150544],
            [9.8029119434458387, 50.722334875333935],
            [9.8035115471515155, 50.722330528837951],
            [9.8041573028063542, 50.722326760263051],
            [9.804198509302287, 50.72264011958471],
            [9.8042134706577144, 50.722753554336897],
            [9.8042085453799874, 50.723213180907685],
            [9.8046504789354572, 50.723268461110116],
            [9.8046290579935516, 50.723323889550819],
            [9.8043911756767272, 50.724553584135087],
            [9.8056298447003005, 50.724750056735274],
            [9.8057379258170645, 50.724841453212903],
            [9.805838351695872, 50.724857396412659],
            [9.8062531853984396, 50.724923406767445],
            [9.8065709084684496, 50.724951156327521],
            [9.8071719775005324, 50.724992232086734],
            [9.8074623925467623, 50.724979978088655],
            [9.8079081991645136, 50.724993176393959],
            [9.8080361056753258, 50.72500217346191],
            [9.8081382676859938, 50.724996258403657],
            [9.8082891776071968, 50.7250230639725],
            [9.8082380529454589, 50.725108304276979],
            [9.8120210004328676, 50.72595833467097],
            [9.8125466091432045, 50.725506879333444],
            [9.8126649941766537, 50.725518986343623],
            [9.8127104123097997, 50.725523633967732],
            [9.8127915119821907, 50.725531926857748],
            [9.8128899845778399, 50.725542023095684],
            [9.8132196730783043, 50.725886471209478],
            [9.8132777785222238, 50.725947138097965],
            [9.8145123841945345, 50.725487407880493],
            [9.8148860939600731, 50.726201232318722],
            [9.8152650723718065, 50.72688733788825],
            [9.8154317396806814, 50.726812464930788],
            [9.8157160033734758, 50.726731013498537],
            [9.815984964847269, 50.7266822234971],
            [9.816013119752256, 50.726721506182294],
            [9.81630656903946, 50.727131228317283],
            [9.8164155163915368, 50.727278285157126],
            [9.816554352564772, 50.727465655502179],
            [9.8167607282300047, 50.727618585638673],
            [9.816918789973025, 50.727755225673548],
            [9.8169645193365191, 50.727794726729982],
            [9.8171400138084675, 50.7279877929452],
            [9.8173076226783103, 50.72814287334603],
            [9.8175300103399206, 50.728376151161299],
            [9.8176002812649088, 50.728449833540516],
            [9.8176473695718993, 50.7285738419693],
            [9.8176205178166853, 50.728754458861822],
            [9.8176137183201462, 50.728835138688737],
            [9.8176139644579745, 50.728905445028722],
            [9.8177078428297282, 50.729080098211263],
            [9.8178446727461317, 50.72916058905971],
            [9.8180213748562934, 50.729244316326508],
            [9.8180705012938514, 50.729259071077536],
            [9.8181753553925972, 50.729284415356851],
            [9.8183615052327973, 50.729381727340957],
            [9.8184975111305466, 50.729496127197059],
            [9.818565832192073, 50.729561809822592],
            [9.8186775890881872, 50.729730311324516],
            [9.8188643749855959, 50.730041123231658],
            [9.8197795169836066, 50.729826601365644],
            [9.820031388544388, 50.730085342395618],
            [9.8203805431379703, 50.730563495580292],
            [9.8207664370028542, 50.730994049415131],
            [9.8208711452242223, 50.731091004352457],
            [9.820821538544573, 50.732289432134138],
            [9.8208210529825788, 50.732307053030304],
            [9.8208193728136877, 50.732368208927475],
            [9.8208126197643448, 50.732615036046184],
            [9.8205750286578724, 50.73282592371671],
            [9.8202714748314737, 50.733095352357275],
            [9.8202197273941767, 50.73327886976859],
            [9.8202348245860716, 50.73373111626865],
            [9.8203885536949063, 50.734043839823919],
            [9.8207391269423852, 50.734756926289165],
            [9.8209581500181606, 50.735093254970295],
            [9.8213961617281882, 50.735765830367562],
            [9.8216370229760059, 50.736317883073731],
            [9.8217912226780726, 50.736746351446158],
            [9.8208012258810431, 50.736923401703002],
            [9.8202689639123566, 50.737029146777346],
            [9.8197885167564696, 50.737070296498352],
            [9.8192989260199024, 50.736894460263009],
            [9.8187535234309014, 50.736645801130486],
            [9.8182034423101765, 50.736374734518499],
            [9.8177724493871974, 50.736100465003155],
            [9.8175692856584398, 50.735729018131131],
            [9.8173570780777801, 50.735340943106443],
            [9.8168776337016368, 50.734842560304756],
            [9.8164388712754214, 50.734560282458403],
            [9.8161347961578258, 50.734398089365548],
            [9.8158142556727643, 50.734354926339954],
            [9.8150750459599916, 50.73431603870894],
            [9.8142659874151139, 50.734293336121652],
            [9.8134873566291088, 50.734300227743105],
            [9.8129551323699786, 50.734343527192749],
            [9.8122591493682059, 50.734449855515962],
            [9.8116182224455848, 50.734340052238139],
            [9.8111910009352385, 50.734095391393176],
            [9.8107066795553752, 50.733844336211732],
            [9.8101263769221081, 50.733543422727202],
            [9.8098149923166957, 50.734060152374859],
            [9.8097966298499539, 50.734090712517897],
            [9.8095038207385912, 50.734601143005357],
            [9.8092575878278847, 50.735175046426441],
            [9.8092117833778989, 50.735246364739183],
            [9.8090275296381915, 50.735537391568897],
            [9.8088400140752778, 50.735843216355541],
            [9.8087830285923694, 50.736003472735568],
            [9.8087600693218793, 50.736241157378316],
            [9.8087528259949934, 50.736485432853641],
            [9.8087005532247815, 50.736740011939823],
            [9.8086514939940912, 50.736976321735249],
            [9.8086569226461489, 50.737275097316683],
            [9.8086565956014375, 50.737642053396463],
            [9.8086882041668915, 50.73791475629703],
            [9.8082534718886389, 50.737917293802035],
            [9.8082585956811652, 50.738263536953816],
            [9.8083457797326066, 50.738687415099029],
            [9.8084612761928867, 50.738928267506928],
            [9.8086298837155166, 50.739192556187426],
            [9.8088291478932241, 50.739496345423433],
            [9.8090561558847345, 50.739842425133176],
          ],
        ],
      },
      properties: {
        FID: 5,
        OBJECTID: 21,
        gemarkungs: "Malges",
        FIRST_geme: "Hünfeld",
        shape_Leng: 11692.1558611,
        shape_Area: 3109722.1788499998,
      },
    },
    {
      type: "Feature",
      id: 6,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.6545385176660261, 50.683099121407274],
            [9.6538707697397239, 50.683453105606205],
            [9.653370747275698, 50.683720036937352],
            [9.6530679841223019, 50.6839264346589],
            [9.6526614694623589, 50.684182586841722],
            [9.6525666776823797, 50.684142306850362],
            [9.6524002749349318, 50.684071500319178],
            [9.6520943987742296, 50.683941426932279],
            [9.6521097514111442, 50.683487548986676],
            [9.6520982354275286, 50.683167323970842],
            [9.6514306809126627, 50.683086836167568],
            [9.6506528202414472, 50.682950231813592],
            [9.6501625907589723, 50.683015100589301],
            [9.6495653955257534, 50.683213697349629],
            [9.6491594190878764, 50.682883978541817],
            [9.6501384797023775, 50.680801024294674],
            [9.6494589119411334, 50.679325509176884],
            [9.6486771582848707, 50.67762940890487],
            [9.6479017389878603, 50.676022272650613],
            [9.647898140702786, 50.676014828366753],
            [9.6478832081063022, 50.67598390310993],
            [9.6476427113621011, 50.675485510229755],
            [9.6471112478908321, 50.675025784238791],
            [9.6464299851946631, 50.674433842531137],
            [9.6458839723767156, 50.673963049123209],
            [9.6453318372793309, 50.673488770727545],
            [9.6450011086785015, 50.673242679122751],
            [9.6445772552606357, 50.672930948235177],
            [9.643745224059721, 50.672315219252795],
            [9.6436116367345139, 50.672216358200096],
            [9.6424992728366803, 50.67176452074316],
            [9.6413081730416064, 50.671282295495516],
            [9.6410772930827857, 50.671434245345985],
            [9.6405384569777475, 50.671649652294917],
            [9.6402155773467744, 50.671779092589517],
            [9.6399712717501735, 50.671840211527808],
            [9.6389926491108682, 50.6720831250163],
            [9.6377267348528211, 50.672328294993036],
            [9.6372618795063669, 50.672391505077577],
            [9.6367672452216251, 50.672458752031623],
            [9.6366970673518502, 50.672468290401582],
            [9.6365872091083791, 50.672483189520619],
            [9.6365110428420131, 50.672416184098523],
            [9.6364829715849929, 50.672391525385024],
            [9.6363889604967792, 50.67230885239492],
            [9.6363641539391267, 50.672286999665914],
            [9.6361936929462217, 50.672137178606697],
            [9.6360372966647745, 50.671999682054398],
            [9.6358871168226621, 50.671867637177165],
            [9.6359415946126745, 50.671857726307145],
            [9.6376424585858551, 50.671546795507993],
            [9.6381405583658974, 50.671441601855115],
            [9.6384235172203034, 50.671381884802479],
            [9.6392078087799096, 50.671161571489414],
            [9.6393723382334411, 50.671006589939005],
            [9.6396085896120169, 50.670784161882438],
            [9.6397352243015568, 50.670664973530691],
            [9.6393814367591162, 50.670653353228964],
            [9.6385793420848387, 50.670626769253857],
            [9.6376327103617854, 50.670591105121737],
            [9.637567212630195, 50.670588639584686],
            [9.6375010014966342, 50.669577428588859],
            [9.6374314580764651, 50.668603107413901],
            [9.6372408844401125, 50.66815429552662],
            [9.6368156565555907, 50.66715004629264],
            [9.6363967864884419, 50.666161516609073],
            [9.6355705340820812, 50.66554505889696],
            [9.6344337947089453, 50.664698056322948],
            [9.6343089588163071, 50.664605045398645],
            [9.6339130000341093, 50.664309994824045],
            [9.6326941377204101, 50.663569037160059],
            [9.6326232634607436, 50.663525823200622],
            [9.6307981295347318, 50.662289726737676],
            [9.6302001605296503, 50.661793303341902],
            [9.6294297028350559, 50.661150689912908],
            [9.628789527616533, 50.660775939415807],
            [9.6283331344563479, 50.660509281402476],
            [9.628304600170889, 50.660492537141728],
            [9.6282718040588424, 50.660473432668752],
            [9.6282515983221639, 50.660461625692271],
            [9.6283086663562276, 50.660407071472747],
            [9.6287036386147697, 50.660029343277898],
            [9.628715999071698, 50.660017306738588],
            [9.6287546302088245, 50.659979650925905],
            [9.6293360150648795, 50.659414891732233],
            [9.6285283301741416, 50.657180274449829],
            [9.6274272547175244, 50.656746964690818],
            [9.6264982180977743, 50.656380950758226],
            [9.6264614036400395, 50.656366849568499],
            [9.6254947313468779, 50.655986568974953],
            [9.6252042036242678, 50.65568887225416],
            [9.6251962438988237, 50.655680722206128],
            [9.6251777715114031, 50.655661800813952],
            [9.6236478408298112, 50.654094534926053],
            [9.6231158373125698, 50.651942174245292],
            [9.6214153529471851, 50.651142905436309],
            [9.6201664954809214, 50.650555947594526],
            [9.6207577689267634, 50.647480461728421],
            [9.6211633814022157, 50.645353722386737],
            [9.621173182312182, 50.645336502214981],
            [9.6219881139925665, 50.643903700769769],
            [9.6220075898192707, 50.643869449804797],
            [9.6227433275990197, 50.642575775784991],
            [9.6235696640996338, 50.641122792783314],
            [9.6238371237028097, 50.640652475843581],
            [9.6255344312306992, 50.639001836954371],
            [9.626041653244604, 50.638508571860591],
            [9.6269631118193661, 50.637655822932757],
            [9.628164250815912, 50.636544201671541],
            [9.6284435017079577, 50.636212682621895],
            [9.6289242480565402, 50.635757543569483],
            [9.6313298749687757, 50.634362077344839],
            [9.6331751953465901, 50.633332535482388],
            [9.6350780922653581, 50.632503018267705],
            [9.6359893988576921, 50.632102770673939],
            [9.6374842170411252, 50.631453343539299],
            [9.6397709868079939, 50.62964545637476],
            [9.6419743662484869, 50.627903255489045],
            [9.6444122973303603, 50.627997554184461],
            [9.6475694082889518, 50.628120245565619],
            [9.649939636092471, 50.628212349753525],
            [9.6532845484897667, 50.628341878520686],
            [9.6569003598525569, 50.62848125066273],
            [9.6570282184198142, 50.628387036544943],
            [9.6575689751762059, 50.628216236046114],
            [9.6579965490823572, 50.628523601721071],
            [9.6605977762689239, 50.628625830620258],
            [9.6615972492516722, 50.628664190513696],
            [9.6660300247179531, 50.628834493644042],
            [9.6702186608988914, 50.628995348818449],
            [9.6704437911890562, 50.62900403608905],
            [9.670613659722898, 50.629011242353975],
            [9.6733852234868607, 50.629128707882522],
            [9.6757768705348042, 50.6292302621953],
            [9.6763455192343173, 50.629254383051595],
            [9.679377416806533, 50.629382982985867],
            [9.6795479281329513, 50.62925019336074],
            [9.6800095116756797, 50.629405178180427],
            [9.6804320929019223, 50.629229385301343],
            [9.6807278875068068, 50.629198528607311],
            [9.6816426639838102, 50.629232633841042],
            [9.681908664818927, 50.629242678636665],
            [9.6829188927610943, 50.629182901764636],
            [9.6837934639413916, 50.629143247693762],
            [9.6840501854169574, 50.628641467104103],
            [9.684179031713624, 50.62813289153997],
            [9.6859066328415899, 50.627403907771395],
            [9.68868213600045, 50.626261739644939],
            [9.689149274549294, 50.626072260684502],
            [9.690726150256479, 50.625435521248022],
            [9.6908493010238814, 50.625391821573231],
            [9.692560669791833, 50.624784269958731],
            [9.6930555360237385, 50.624608639821133],
            [9.6945272798742774, 50.623586800009285],
            [9.6950472242658901, 50.623187053875171],
            [9.6958203363911828, 50.622505376323581],
            [9.6962795315089281, 50.62185039119241],
            [9.696942170398593, 50.622186444840167],
            [9.6971103148870359, 50.622271566948264],
            [9.6970695950116887, 50.622308484903705],
            [9.6971931871531112, 50.622371111603876],
            [9.6980827710528423, 50.622821965890573],
            [9.6981531087705477, 50.622857679178829],
            [9.69916227910986, 50.62364094420964],
            [9.7002402033339461, 50.62464582969298],
            [9.7013535835223905, 50.62568454000008],
            [9.7023428708135757, 50.626235845196575],
            [9.7033066930052065, 50.626755126986872],
            [9.7049769153545959, 50.627654147956186],
            [9.7051440206752364, 50.627744076111661],
            [9.7061197416001246, 50.628269198126908],
            [9.7069233492308786, 50.628702303591517],
            [9.7076383501205807, 50.629087477272293],
            [9.7092984873305781, 50.629260125077593],
            [9.7094200962427095, 50.629273225273366],
            [9.7100587768963518, 50.629544949784616],
            [9.7108880516559744, 50.62989667182925],
            [9.7111600697260734, 50.630127747146197],
            [9.7111422002246126, 50.63021695192127],
            [9.7113698775107942, 50.630103992389628],
            [9.7120473670294345, 50.629901284164653],
            [9.7129459557593396, 50.629665653176552],
            [9.713762683137535, 50.629587745013765],
            [9.7149720514743692, 50.629566759682049],
            [9.71562867833042, 50.629594186927228],
            [9.7162887459252421, 50.629679577544465],
            [9.71666174748953, 50.62972771786972],
            [9.7171219193099034, 50.629874470687021],
            [9.7171851164208238, 50.629894577245452],
            [9.7178220903180712, 50.630097138972395],
            [9.7183411646850839, 50.63012082072369],
            [9.7190819196391853, 50.629964041488307],
            [9.7202190328490676, 50.629987667224228],
            [9.7207043178136416, 50.630030064323648],
            [9.7213168550750382, 50.630130541273154],
            [9.7232052597899621, 50.630555635980564],
            [9.7245863183139232, 50.630852150616846],
            [9.7256935668477471, 50.631008144875537],
            [9.7274756405844087, 50.631113179651081],
            [9.7283060676560762, 50.631198877418917],
            [9.7290021485443106, 50.631270310280811],
            [9.7295927941811229, 50.63135192303681],
            [9.7298335846755926, 50.631385200814037],
            [9.7309441794316474, 50.631535287665372],
            [9.7318281819207062, 50.631529927691311],
            [9.7324697074271818, 50.63159223062042],
            [9.7330029524999429, 50.631635444157524],
            [9.7332392350050867, 50.631613192176935],
            [9.7333124606800183, 50.631577486949205],
            [9.7333188768093635, 50.631519107587259],
            [9.7335298574929787, 50.631805445530055],
            [9.7336586843949497, 50.631982538211282],
            [9.7338304851035264, 50.632157921196011],
            [9.7340703183334565, 50.63236533185114],
            [9.7342257109090848, 50.632468449229037],
            [9.7346637194862513, 50.632693036829018],
            [9.7352388377193098, 50.632899670603393],
            [9.7357833225870003, 50.633053983612804],
            [9.7363908711121372, 50.633172660137909],
            [9.7369060127352842, 50.633213335241692],
            [9.7368828027359751, 50.633373947927552],
            [9.7369438234404662, 50.633433079098133],
            [9.7370379277866643, 50.63352174153016],
            [9.7371367559914077, 50.633615868854569],
            [9.7371917614749215, 50.633668275051569],
            [9.7372205969762007, 50.633840249683807],
            [9.7372080710969424, 50.633969687299874],
            [9.7371689725461739, 50.634063697892238],
            [9.7371028302340434, 50.634109343183681],
            [9.7368938667938068, 50.63416119773423],
            [9.7366579439875967, 50.634196315112732],
            [9.7363333715501845, 50.634204131279105],
            [9.7363614945666388, 50.634294479225332],
            [9.736517623529819, 50.634786931967959],
            [9.7365362248701004, 50.634846151594594],
            [9.7367095892683437, 50.635211573921602],
            [9.7370309611526515, 50.63561353459388],
            [9.7370790081651535, 50.635648547010966],
            [9.7377524102120852, 50.636161367947523],
            [9.7377641468092033, 50.636170367786129],
            [9.7376586254304573, 50.636220030847348],
            [9.7364140145483002, 50.636801409165827],
            [9.7343879039019949, 50.637579877863345],
            [9.7335848396298932, 50.637888160252864],
            [9.7329049541614268, 50.638083741953132],
            [9.7321803740673278, 50.638322030448471],
            [9.7315753049570972, 50.638673642933981],
            [9.7311381161683705, 50.638941137781408],
            [9.7306149319865494, 50.639251051527474],
            [9.7301163582709407, 50.639598121485754],
            [9.7296379876625245, 50.640062172225498],
            [9.7292444174788564, 50.640370728465342],
            [9.7282520006831064, 50.641028357280703],
            [9.7278524048194193, 50.641340705547606],
            [9.7275365876912456, 50.641497495657433],
            [9.7272810787407256, 50.641624410834325],
            [9.7270185784545937, 50.641756603104881],
            [9.7268069827062202, 50.641658134551911],
            [9.7265083510461654, 50.641519145348845],
            [9.7264780208963888, 50.641504855395802],
            [9.7260282987814382, 50.641295697614012],
            [9.7251834364521645, 50.641222501839415],
            [9.7250236569687356, 50.641276978620539],
            [9.7238966579687194, 50.641655278321402],
            [9.7239448202012877, 50.641790992202509],
            [9.7239966074156694, 50.642330118251373],
            [9.7240059069923106, 50.642427258916129],
            [9.7241104129430571, 50.642940993270557],
            [9.7241456929373165, 50.643114450585514],
            [9.7242372755685942, 50.64382972158144],
            [9.7241518142442658, 50.6445341408076],
            [9.7240814960468551, 50.64515557555638],
            [9.7243232112708551, 50.645749719307148],
            [9.7240774561461478, 50.645518502846414],
            [9.7235845338558509, 50.64505482707191],
            [9.7232323324948453, 50.64478346762985],
            [9.7230694740580166, 50.64434243597961],
            [9.7222699125272651, 50.643686592815214],
            [9.7218648929565781, 50.643216968910984],
            [9.7214357305184986, 50.64267657273156],
            [9.7202644658771291, 50.641966449185496],
            [9.7191544971006163, 50.641267087138729],
            [9.7183131314825584, 50.640705390616411],
            [9.7174296879076216, 50.640116985478315],
            [9.7163402412991609, 50.639822352878831],
            [9.7154790029469051, 50.639566679570443],
            [9.7147260665618749, 50.639302142871358],
            [9.7137541450498226, 50.639148354077108],
            [9.712377615783307, 50.638906694797775],
            [9.7114681587396205, 50.638788369926225],
            [9.7104721824060203, 50.63872323865538],
            [9.7089212580158346, 50.638604738336085],
            [9.7081111837111091, 50.638515172374071],
            [9.707611115889403, 50.638558508649076],
            [9.7066519774674429, 50.639099918754667],
            [9.7062058445727093, 50.63970599463309],
            [9.7060785647389167, 50.64063655395065],
            [9.7064138327360165, 50.641284189994892],
            [9.7069229308072273, 50.64191710995356],
            [9.7078666864445822, 50.642396644271365],
            [9.7084553569682139, 50.642905381781119],
            [9.7090458483356841, 50.643524865308635],
            [9.7089111269546144, 50.644257622404979],
            [9.7083320469494279, 50.645029845257952],
            [9.7080936920673846, 50.64609387443447],
            [9.708433786487225, 50.646851056787916],
            [9.7087454915963516, 50.647995702041626],
            [9.7087845563044883, 50.648140290614293],
            [9.7088529757579529, 50.648393257283331],
            [9.7089350404375363, 50.648548124234686],
            [9.7091876872848584, 50.648952557062962],
            [9.707133204321563, 50.64866780830458],
            [9.7072151296928784, 50.648955065867263],
            [9.7070576680978, 50.648968243081192],
            [9.7064640029521119, 50.649018077484627],
            [9.705745766425883, 50.64907765652277],
            [9.7056480475931171, 50.649085793553695],
            [9.7050340112618656, 50.64913566307758],
            [9.7049467376488447, 50.649143286666913],
            [9.7044497947017216, 50.648805522287851],
            [9.7039936190411265, 50.648214702297899],
            [9.703403305627381, 50.647683388703001],
            [9.7025504337542952, 50.647330686019529],
            [9.7022554355158181, 50.647108533974134],
            [9.702115719693893, 50.646944503904123],
            [9.701975934769921, 50.646699832241957],
            [9.7018912256596739, 50.646613495354408],
            [9.7015782239721613, 50.646167771650681],
            [9.7002536234650041, 50.646226325475894],
            [9.6999581768921317, 50.646239350937009],
            [9.6989465088925293, 50.646655251334593],
            [9.6988112533722948, 50.646648966882907],
            [9.69804266490336, 50.646360341780323],
            [9.697428386543292, 50.646133074944316],
            [9.6973244673997119, 50.646094675438057],
            [9.696604874197682, 50.645829723477291],
            [9.6956914884913257, 50.645491919306444],
            [9.6956415999447074, 50.645473511317469],
            [9.6942948079497029, 50.644975542080203],
            [9.6936409036759361, 50.644733634647842],
            [9.6935308893087608, 50.644692938808944],
            [9.6922438531781445, 50.644216920299705],
            [9.6916202413095522, 50.645068850774173],
            [9.6915553769534082, 50.645157422831758],
            [9.6915064966329982, 50.645226843458659],
            [9.6911358180711886, 50.645752445411347],
            [9.6908844871184119, 50.646106899937166],
            [9.6907979036756888, 50.646228884059809],
            [9.6904379648322383, 50.646736541575905],
            [9.6902852345804966, 50.646705610538973],
            [9.6890954466236057, 50.646466598950958],
            [9.6878378883767891, 50.646213757055769],
            [9.6868974370642054, 50.646024844905476],
            [9.6868028211293904, 50.64600499706583],
            [9.6868629916377866, 50.645806243993221],
            [9.6867699212048848, 50.645939927493266],
            [9.6858696050856548, 50.646954668716404],
            [9.6854604424801014, 50.648338238497324],
            [9.6854504659156735, 50.648645108182279],
            [9.6848984843291497, 50.649599902861183],
            [9.6843879108462563, 50.650088245764707],
            [9.6842176175355377, 50.650123401785457],
            [9.6824958713182063, 50.649718346405152],
            [9.6813922616493695, 50.64976685658074],
            [9.6797996714896026, 50.650360758458952],
            [9.6796972775848982, 50.650408102191719],
            [9.679619051479623, 50.650503040614815],
            [9.6784117570537145, 50.651019609811762],
            [9.6781021826296882, 50.651152298079559],
            [9.6779779157674852, 50.65126152361465],
            [9.6774275124268723, 50.651746569083009],
            [9.6766207226959065, 50.652457471142185],
            [9.6764453514136122, 50.65261184959428],
            [9.6771957147056522, 50.653257436397453],
            [9.6772203375992856, 50.653278688041013],
            [9.6777516777231156, 50.65373639966316],
            [9.6776974276115748, 50.653789567950341],
            [9.678143175280761, 50.653851168155207],
            [9.6792026808954059, 50.653997198278709],
            [9.6793391436251959, 50.654016268767826],
            [9.6792701089223474, 50.654141810986921],
            [9.6789102486680871, 50.654790336208102],
            [9.6781800819312505, 50.655863082570839],
            [9.6779291282931013, 50.656306295486353],
            [9.6773540389156842, 50.657535163571794],
            [9.6768914038744782, 50.658611085383711],
            [9.6769209862577465, 50.658750793044952],
            [9.677022030492445, 50.658873816970178],
            [9.6772137557508291, 50.658958920384741],
            [9.6771841437899777, 50.659043502170071],
            [9.6770275684982092, 50.659484347807748],
            [9.6769995746475672, 50.659563434315629],
            [9.6769814303861903, 50.659614162157389],
            [9.6769651772716276, 50.659659644997291],
            [9.6778751748943908, 50.659626521754944],
            [9.6786761005726092, 50.659481288479341],
            [9.6789025130417254, 50.659648168680448],
            [9.6789631325422967, 50.659692943168956],
            [9.6791076624150367, 50.659800333644469],
            [9.6789603461345202, 50.659882373144001],
            [9.6788920970711203, 50.65996591240598],
            [9.6788739633268044, 50.660061399390656],
            [9.6788662007304058, 50.660230524824939],
            [9.6789045271545238, 50.660750519305516],
            [9.6789025598078684, 50.660918270984126],
            [9.6788789492877214, 50.661096122134389],
            [9.6787735225458125, 50.661248828331544],
            [9.6786591031790685, 50.661356836888132],
            [9.678532336209587, 50.661440626725486],
            [9.6783340496904948, 50.661528519904522],
            [9.6779477496689292, 50.661661294211697],
            [9.6776790288489636, 50.661789507553841],
            [9.6774806085669542, 50.661945999772847],
            [9.6773328205149305, 50.662122782709815],
            [9.6772525441468851, 50.662313912925164],
            [9.6772011131138402, 50.662572656813893],
            [9.6771116039411904, 50.662878458201789],
            [9.6769504995887168, 50.663054418667436],
            [9.677086559007714, 50.662995469628385],
            [9.6771202141344208, 50.663044268756053],
            [9.6792502021194622, 50.663380943277097],
            [9.6793292020766177, 50.663346947388305],
            [9.6797840667443413, 50.662624348858685],
            [9.6798359036805497, 50.662638696331307],
            [9.6798617450614959, 50.662645919970998],
            [9.6802486155138983, 50.662767893882396],
            [9.6802938020777916, 50.6627821990295],
            [9.6805849170391394, 50.662874064092165],
            [9.6809565969772429, 50.66299127705534],
            [9.6813999927278278, 50.663158763930099],
            [9.6814496115470785, 50.663225077061327],
            [9.6814251629618617, 50.663314126254988],
            [9.6812388925844335, 50.664006761116916],
            [9.6810074101850798, 50.664733734801572],
            [9.6809366259155851, 50.665188113279413],
            [9.6808899275467457, 50.665488190515084],
            [9.6809265783019409, 50.665676202866656],
            [9.6811153733732223, 50.666094279045488],
            [9.6811987119456244, 50.667005318794423],
            [9.6812124610708974, 50.667125251893673],
            [9.6812607919639291, 50.667434747104409],
            [9.6810363542639806, 50.668358287208989],
            [9.6809097916931695, 50.668774176910709],
            [9.6808789973054967, 50.668993177835631],
            [9.6808763284445067, 50.669038572797938],
            [9.680871557302936, 50.669132336139846],
            [9.680870351751544, 50.669156606666526],
            [9.6808515867550824, 50.669518546155153],
            [9.6807872111727082, 50.669527195880974],
            [9.6807246816482788, 50.66954509771984],
            [9.6806527014631509, 50.669556309864099],
            [9.6805367474743615, 50.669568066512049],
            [9.6799413738434961, 50.66958512995938],
            [9.6799111318354054, 50.669586034825656],
            [9.6792395818230794, 50.670387605539723],
            [9.6792290635136933, 50.670458928436105],
            [9.679188603525601, 50.670486305496247],
            [9.6790873278624101, 50.670528884562074],
            [9.6788382451207333, 50.670603179949957],
            [9.6784418557722649, 50.670723378960794],
            [9.6782678818522214, 50.670783566335388],
            [9.6782370367637878, 50.670790796448102],
            [9.678182412403677, 50.670803587778323],
            [9.6781852534349522, 50.670823976667492],
            [9.6767894075228806, 50.671158594985975],
            [9.6762186457711064, 50.671236406938974],
            [9.6760811076944915, 50.67126310504694],
            [9.675032919916875, 50.671566610310791],
            [9.6748822656122258, 50.671621225696988],
            [9.6747309098738636, 50.671676294604438],
            [9.6742021843437662, 50.671856923571909],
            [9.673485072878675, 50.672133021731547],
            [9.6729414915272898, 50.672335386243063],
            [9.6727735579253569, 50.672402239374577],
            [9.672616883970889, 50.672608384812051],
            [9.6724155385531265, 50.672942876879567],
            [9.6722935360407174, 50.673105564883748],
            [9.6721735186517233, 50.673344880739762],
            [9.6721229915970497, 50.67357751855787],
            [9.6720870893938109, 50.673822464543179],
            [9.6720752709365669, 50.673895718756299],
            [9.672108336456235, 50.674281612001977],
            [9.6721458804000005, 50.67454006478399],
            [9.6722521577953682, 50.674773291115997],
            [9.6724032947169043, 50.675091306545767],
            [9.672665234366038, 50.675618105250095],
            [9.6726659213581048, 50.675676070964258],
            [9.6726504431363569, 50.675753663033902],
            [9.6726161338202328, 50.67583072525867],
            [9.6725434282966951, 50.676017788088565],
            [9.6724567646226589, 50.676214572024051],
            [9.6724345679237302, 50.676272687740415],
            [9.672433825125033, 50.676291029003899],
            [9.6723607396693208, 50.676439711245827],
            [9.6723135710407835, 50.676489517448459],
            [9.6721996372320813, 50.676624226058372],
            [9.6720940619779174, 50.676735765038373],
            [9.6720132889255002, 50.676797186145471],
            [9.6719302982855027, 50.676871821882735],
            [9.671849650312593, 50.676943026669811],
            [9.6717230942890424, 50.677059245750328],
            [9.671295167161766, 50.677407469638304],
            [9.6710566964529594, 50.677566339344175],
            [9.6707928908785803, 50.677685604766857],
            [9.6705010248450609, 50.677794464091647],
            [9.6702730224992308, 50.677867828323151],
            [9.6701323477149472, 50.677894510396179],
            [9.6699078255230368, 50.677824665447929],
            [9.6699423125511679, 50.678042019793025],
            [9.6699594203018826, 50.678150099696857],
            [9.6699864402963502, 50.678321128414197],
            [9.6699291766031603, 50.678593697250875],
            [9.6699182965906623, 50.6786347594118],
            [9.6698095281172449, 50.67876726858433],
            [9.6697826844784931, 50.678799528270872],
            [9.6694245988277192, 50.678890590266796],
            [9.6687168672083086, 50.678897989201666],
            [9.6682879090460947, 50.678970047132864],
            [9.6673306244146815, 50.67924066680564],
            [9.6666258906773166, 50.679331186224331],
            [9.6657292078081554, 50.679505516523697],
            [9.6649008136735191, 50.679717023620121],
            [9.6628175468306452, 50.680652346553828],
            [9.6622510934029435, 50.680929397561485],
            [9.6619926466617105, 50.680913338079144],
            [9.6621245224705419, 50.680743221748344],
            [9.6620607437656076, 50.68058436995608],
            [9.6620535544693773, 50.680566343626801],
            [9.6619210031017158, 50.680236644485419],
            [9.6612630352478632, 50.680525352827786],
            [9.6598152494205287, 50.681155943545868],
            [9.658992250742374, 50.681563607613064],
            [9.6589078091924137, 50.681605435299744],
            [9.6587842873812679, 50.6816665491978],
            [9.6569866220102583, 50.682638697178604],
            [9.6568586927431426, 50.682707918661265],
            [9.6567341196607792, 50.682775241533299],
            [9.6551511216831081, 50.683419107920109],
            [9.6550342671424136, 50.683217590626377],
            [9.6549676144650398, 50.683044011506453],
            [9.6545385176660261, 50.683099121407274],
          ],
        ],
      },
      properties: {
        FID: 6,
        OBJECTID: 23,
        gemarkungs: "Michelsrombach",
        FIRST_geme: "Hünfeld",
        shape_Leng: 30233.150723999999,
        shape_Area: 25727135.0803,
      },
    },
    {
      type: "Feature",
      id: 7,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.793741241208739, 50.675591648731334],
            [9.7934253010399157, 50.676074161123296],
            [9.7933641352989262, 50.676053416548683],
            [9.7922566462246916, 50.675688146799892],
            [9.7914852828616254, 50.675434267183775],
            [9.7914251891537116, 50.675559644350884],
            [9.7913294566744202, 50.675543549065367],
            [9.7919259308539548, 50.674296083901815],
            [9.7926427950292076, 50.673690619821421],
            [9.7934112534561049, 50.673043143631908],
            [9.7935373554400744, 50.672903304607885],
            [9.7938121681072712, 50.672382562937962],
            [9.7938951399528733, 50.672307804094757],
            [9.79400870429183, 50.672205974163823],
            [9.7946581245748483, 50.671623448724311],
            [9.7946225300386427, 50.671552033759788],
            [9.7936588558124669, 50.671344193535205],
            [9.7917824335527612, 50.670911523868668],
            [9.7916514959457519, 50.670881647649665],
            [9.791288407396868, 50.67079821046044],
            [9.7885989821557278, 50.670180225146581],
            [9.788399444272331, 50.670134378883304],
            [9.7882576369326628, 50.670067817265547],
            [9.7878084818726343, 50.669443454050104],
            [9.7872699122485241, 50.668695155526393],
            [9.787239705025172, 50.66865314558143],
            [9.7868761755977047, 50.668785632881992],
            [9.7860646391391821, 50.669084259750001],
            [9.7861311115354557, 50.668960002318613],
            [9.7860344533140413, 50.66894433162286],
            [9.7860270563837357, 50.668936098791427],
            [9.7860096887998562, 50.66891719534653],
            [9.785365223928201, 50.66821145640197],
            [9.7847044993354704, 50.66748837655738],
            [9.7842985720583631, 50.667044741379115],
            [9.7843246504542662, 50.666982692798527],
            [9.7842565656889366, 50.666955928605233],
            [9.784123158229562, 50.666981557482515],
            [9.7836365205369979, 50.666931914643577],
            [9.7825173260647436, 50.666758338758108],
            [9.7813521320514933, 50.66662365926426],
            [9.7812542433643763, 50.666547100036276],
            [9.7812436782497389, 50.666472374574774],
            [9.7814097472883024, 50.666275560651876],
            [9.7821912431119209, 50.66568510505703],
            [9.7818388885192249, 50.665286031641671],
            [9.7837265729110818, 50.664394700963818],
            [9.7837720215628057, 50.664345409451983],
            [9.7839291813000653, 50.664462684769717],
            [9.7839401647230293, 50.664406799185876],
            [9.7846876873239061, 50.664121442026243],
            [9.7860120029389481, 50.663751315692011],
            [9.7869322890803385, 50.66354521382614],
            [9.7876585420123394, 50.663383340673462],
            [9.7878311547352084, 50.663330382186587],
            [9.7879191580160985, 50.663295397308246],
            [9.787965923372294, 50.663277760253706],
            [9.7879966720136302, 50.663266059061613],
            [9.7884689530516606, 50.66307971857254],
            [9.7885157070393713, 50.663051289546132],
            [9.7885606114091264, 50.663023943205033],
            [9.788535922618701, 50.662909141323425],
            [9.7886058341915803, 50.662946340751802],
            [9.7886517617645907, 50.662969951853505],
            [9.7891291641351366, 50.662683443838453],
            [9.7894921380823714, 50.662515097169099],
            [9.7899097394971459, 50.662415950439311],
            [9.7899269203263177, 50.662411966918242],
            [9.7900053780980709, 50.662392657328844],
            [9.790039365090129, 50.662387660548539],
            [9.7901523684662202, 50.662371354222259],
            [9.7904039099470097, 50.662335132667017],
            [9.7904497522796206, 50.662328364643074],
            [9.7906410510593815, 50.662300815893019],
            [9.7907801416478843, 50.662701328239827],
            [9.7909087377004393, 50.662855156472709],
            [9.7910463273338806, 50.66296398454746],
            [9.7911282744977921, 50.6629249554342],
            [9.7913907611339166, 50.66279984071052],
            [9.791432665253339, 50.662835699786434],
            [9.7915747741493711, 50.662767222781063],
            [9.7922317090275541, 50.662708646962102],
            [9.7924462798557457, 50.662668265646651],
            [9.792678608990796, 50.662624615535726],
            [9.7929742482154865, 50.66259401500497],
            [9.7930875705338369, 50.662637832139652],
            [9.793059931599787, 50.662483768999969],
            [9.7933832054951324, 50.661943649936966],
            [9.7936462577970591, 50.661741130817418],
            [9.793811530339223, 50.661611897177288],
            [9.7942933743799241, 50.661265928547017],
            [9.7943914200407498, 50.661201075853597],
            [9.7946408067666102, 50.661035222914826],
            [9.7947362035149741, 50.660811810985088],
            [9.7947566212895705, 50.660764115734075],
            [9.7948455000334675, 50.660556117522106],
            [9.7949423053673623, 50.660339152908804],
            [9.7950513330405506, 50.660097832188562],
            [9.7950319150867138, 50.659643277638345],
            [9.7949573127356366, 50.659091910005237],
            [9.7951112101646753, 50.659077379325907],
            [9.7951631587630565, 50.659072177557221],
            [9.795256614434237, 50.659048355405289],
            [9.7954165482174886, 50.658994186080527],
            [9.7956297362520957, 50.658847166928325],
            [9.7957031762472013, 50.65878592546575],
            [9.7958538656210976, 50.65866034071054],
            [9.796045478986068, 50.658529440035345],
            [9.7962583101731298, 50.658412594333342],
            [9.7964413201218701, 50.65832857037536],
            [9.7966263022581188, 50.658235719310603],
            [9.7962877942355693, 50.658694545505405],
            [9.7964772500434147, 50.658752327180039],
            [9.796922186757552, 50.658875963689383],
            [9.7970193577019913, 50.658886990339475],
            [9.797170902308487, 50.658883984321939],
            [9.7973148654629121, 50.658867720041656],
            [9.7974587907743729, 50.658874289574463],
            [9.797611407026972, 50.658902814799887],
            [9.7977718028601402, 50.658947923989643],
            [9.7979099106532299, 50.658996063382141],
            [9.7980391843438994, 50.659057204318216],
            [9.7980959202809448, 50.659084056192796],
            [9.7984196439317568, 50.658790495135939],
            [9.7985190805908626, 50.658681490764486],
            [9.798666852148747, 50.658469416753135],
            [9.7987532117538514, 50.658297666300271],
            [9.7987677716125123, 50.658176832791106],
            [9.7987581231291969, 50.658106787929214],
            [9.7987271712274513, 50.658038588789111],
            [9.7986988848202614, 50.657977395062368],
            [9.7986295898115774, 50.6578556253695],
            [9.7985141290389883, 50.657674475094126],
            [9.7984072922726178, 50.657525461279739],
            [9.7982780880511147, 50.657388912273824],
            [9.7985215330150961, 50.657281790176164],
            [9.7985390619946688, 50.657274295641876],
            [9.7986792538264975, 50.657388834642326],
            [9.7990196081131984, 50.657716120427864],
            [9.7990948697717677, 50.657702564253299],
            [9.7990255362303369, 50.657629421293997],
            [9.799530350374928, 50.657714398217003],
            [9.7999027888047365, 50.657262281299623],
            [9.8000975364264882, 50.657107844147653],
            [9.8002739850972098, 50.657016376826441],
            [9.8003491534483071, 50.656988260481832],
            [9.8004372280222221, 50.656955378978488],
            [9.8007534760938508, 50.656995987101638],
            [9.8009201642617949, 50.65698620817551],
            [9.801065724874265, 50.656973615475032],
            [9.8015249826731772, 50.656908242747825],
            [9.8017203275074962, 50.656889002579646],
            [9.802095102562145, 50.656851449176564],
            [9.8026295031040984, 50.656813155029404],
            [9.8027714295528536, 50.656827277055477],
            [9.8029035830919167, 50.656866458355324],
            [9.8029138185931881, 50.656869355594189],
            [9.8029777462250305, 50.656937137585771],
            [9.8029988874209533, 50.656999656564103],
            [9.803018659944895, 50.657064873936989],
            [9.803070337287668, 50.657384082347789],
            [9.8038806713774509, 50.65759828045239],
            [9.8042950165501228, 50.657707072224049],
            [9.8045101304192883, 50.657792326875025],
            [9.8046977777044582, 50.657875531479291],
            [9.8048319609607866, 50.657933042715747],
            [9.8049032065517032, 50.657964368621961],
            [9.8057896998046079, 50.658122197633048],
            [9.8059308427821605, 50.658147292944662],
            [9.8064566344402113, 50.658240908163854],
            [9.8071391855544157, 50.658362564415967],
            [9.8072766284064397, 50.658387135140735],
            [9.8087340565823791, 50.65864685350757],
            [9.808955992421085, 50.658973664041817],
            [9.8092293320739685, 50.658898759531766],
            [9.8093216804336834, 50.658966520560853],
            [9.8096673456750167, 50.659043115866766],
            [9.8097525998648027, 50.659051237946663],
            [9.8098784272868418, 50.659064923597903],
            [9.8099895797262437, 50.659064420908088],
            [9.8102656666263677, 50.659049749340198],
            [9.8104671252381088, 50.659029480755073],
            [9.8106680618095119, 50.659043821303207],
            [9.8105921344010074, 50.659865168135262],
            [9.8115838948034391, 50.659946129425528],
            [9.8124297517385521, 50.659935523450116],
            [9.8129724800664828, 50.659874047333979],
            [9.8130934329092891, 50.659860073447312],
            [9.8139513468612432, 50.659878375386668],
            [9.8145650346618751, 50.65981575771049],
            [9.8147818751443374, 50.659793926253307],
            [9.815787154553858, 50.659673264498039],
            [9.8169074437122976, 50.659465588056129],
            [9.8181629854864028, 50.659244450807101],
            [9.8190524456319555, 50.659124762722968],
            [9.8192405278794759, 50.659099437849612],
            [9.8197407407523656, 50.659029226819555],
            [9.8209937098721198, 50.659077423704808],
            [9.821412370049611, 50.659057458465512],
            [9.8222405292381705, 50.659216481925831],
            [9.8229671041769553, 50.659551620453932],
            [9.8243440570121479, 50.660018429976638],
            [9.8252501305326394, 50.660441461143691],
            [9.8256498467717872, 50.660660591386609],
            [9.8256767117273895, 50.660961367765111],
            [9.8256972570137666, 50.661265624302985],
            [9.8257816343237483, 50.661514587969734],
            [9.8258548579128639, 50.661788254006439],
            [9.8258149229367646, 50.662013735878837],
            [9.8257672162995728, 50.662279355514201],
            [9.8263566137830747, 50.662735198318558],
            [9.8273311349812857, 50.662809205229607],
            [9.8284487507006748, 50.662953206249817],
            [9.829296309487118, 50.663143167259001],
            [9.830047162470299, 50.663245533595337],
            [9.8304468944239645, 50.663408376782584],
            [9.8309990822538857, 50.663675855772524],
            [9.8315058061686731, 50.66401098866104],
            [9.8319204197472398, 50.66401361457573],
            [9.832447016498536, 50.66417329695259],
            [9.8328325133815593, 50.663853667179005],
            [9.8331368324380914, 50.663550966094874],
            [9.8331596723502894, 50.663305656970458],
            [9.834020031406153, 50.663244689575016],
            [9.834448569103861, 50.663295653247829],
            [9.8345001348250829, 50.663299960496595],
            [9.8346699912616486, 50.663313978628508],
            [9.8350274027719244, 50.663343470146948],
            [9.8359341545122465, 50.663799625435217],
            [9.8365958183724, 50.664339848085426],
            [9.8366436247518187, 50.664364236227151],
            [9.8372498526912722, 50.664676164824428],
            [9.8379879459597799, 50.665031532673112],
            [9.8387803374068916, 50.665455635862386],
            [9.8390975061821813, 50.665740478881197],
            [9.839311501253956, 50.665974029395315],
            [9.839639052815766, 50.666332081795758],
            [9.8399144632767754, 50.666621809943095],
            [9.840362120883178, 50.667092542877725],
            [9.8401962469474871, 50.667430957001471],
            [9.8398942559956986, 50.668046991808751],
            [9.8394623451968304, 50.668340503074376],
            [9.838475985306232, 50.668492816616222],
            [9.8382699742630848, 50.668650015823665],
            [9.8385210009114683, 50.669008468722595],
            [9.8391230856916643, 50.669453031054189],
            [9.8391637236234164, 50.669825866368818],
            [9.838690264832568, 50.670231756026524],
            [9.8383970615887364, 50.670701287081172],
            [9.838394462771987, 50.671175085462686],
            [9.8382246021010555, 50.671631327052182],
            [9.8381235483890652, 50.671903378539042],
            [9.8379312651469935, 50.672117935834471],
            [9.8376744923986763, 50.672382760636005],
            [9.8372400685888906, 50.672654149131851],
            [9.8363764339643502, 50.672830914726994],
            [9.8363096774098793, 50.672844415899704],
            [9.8354651700245501, 50.672996486052071],
            [9.8349967820966153, 50.673066374249771],
            [9.8345507158903285, 50.673010438837913],
            [9.8342474564092743, 50.672907146701327],
            [9.8340755995968312, 50.672848482010899],
            [9.8340237112472533, 50.672833744760801],
            [9.833519412071988, 50.672755424351848],
            [9.8331138446707111, 50.672698159596735],
            [9.8326580500693161, 50.672954341298855],
            [9.832170822715586, 50.67307630639305],
            [9.8317262543591042, 50.673200240308844],
            [9.8310876251290029, 50.673336096473982],
            [9.830408430189971, 50.673536044846927],
            [9.8303792309251907, 50.67402918764779],
            [9.8301912293743055, 50.674133534910752],
            [9.8298769879658892, 50.674165774069252],
            [9.8295578094687208, 50.674070497108332],
            [9.8291722387975504, 50.674058536880651],
            [9.8284297366748543, 50.674187832851892],
            [9.8284613755971417, 50.674273501915366],
            [9.8279072703490495, 50.67439728242163],
            [9.8277356911225642, 50.674454843392873],
            [9.8274145284915235, 50.67451141565526],
            [9.8264806399316953, 50.674751846502829],
            [9.8253254445352667, 50.675001751139241],
            [9.8251811708478165, 50.674989660596545],
            [9.8238262323237322, 50.675179468904574],
            [9.8230228917599121, 50.675117470587466],
            [9.8229525350720017, 50.675122976580802],
            [9.8227224384102989, 50.675137587157813],
            [9.8223095852529472, 50.675164332585759],
            [9.8218479367111851, 50.675178353529894],
            [9.8211756199457998, 50.675124114942676],
            [9.8208864278670465, 50.675109946356045],
            [9.8203297306551551, 50.675118389403579],
            [9.8191058999903014, 50.675136853070875],
            [9.8183588796344239, 50.675135832260509],
            [9.8183161376396821, 50.67513337144922],
            [9.8175475242979608, 50.675091856845135],
            [9.8170224520946388, 50.675032271649251],
            [9.8163119370259722, 50.67495148208652],
            [9.8145862450191217, 50.674470036728856],
            [9.8134998592202543, 50.674170435233926],
            [9.8133298500089836, 50.674123265370248],
            [9.813168005936264, 50.674132542458935],
            [9.8124038368715922, 50.674170174914366],
            [9.8114973123534597, 50.674214756065915],
            [9.8106353270147526, 50.674166642439332],
            [9.8082028522196669, 50.674032215806719],
            [9.808099436618507, 50.674018849543991],
            [9.8079957660512314, 50.674005466970783],
            [9.8075907289862077, 50.673953154950858],
            [9.8061089666669972, 50.673762576887022],
            [9.8060389569272068, 50.67375445459686],
            [9.8049840451332795, 50.673801459092488],
            [9.8036804954581758, 50.67386141922308],
            [9.8035968824884172, 50.673926890515567],
            [9.8035508791668082, 50.67396291060377],
            [9.8035062592077811, 50.673997895914312],
            [9.8027311195522362, 50.674604898646052],
            [9.8026863122662533, 50.674639759021808],
            [9.802061034120225, 50.674969598537388],
            [9.8020474769673616, 50.674999567161521],
            [9.8018728819746936, 50.675039924765748],
            [9.8018026232197251, 50.675045498250448],
            [9.800974292508748, 50.675112383147848],
            [9.8009041922568407, 50.675118134864647],
            [9.8000959132474765, 50.675105079280392],
            [9.7998590560493994, 50.675101201140805],
            [9.799397678653671, 50.675125680275357],
            [9.7991215332299024, 50.675140146373423],
            [9.7990193914065049, 50.675145109399757],
            [9.7981080031698244, 50.675192632380451],
            [9.7980381461125923, 50.675196060467947],
            [9.7969493804501102, 50.675272154963864],
            [9.7965916105898785, 50.675297308454141],
            [9.79543702906291, 50.675508205605624],
            [9.7949825073148737, 50.675560193525428],
            [9.7947208530657761, 50.675589047339834],
            [9.7940701916974717, 50.675475455621289],
            [9.7938373655198134, 50.675434584833333],
            [9.793741241208739, 50.675591648731334],
          ],
        ],
      },
      properties: {
        FID: 7,
        OBJECTID: 25,
        gemarkungs: "Molzbach",
        FIRST_geme: "Hünfeld",
        shape_Leng: 12031.3569035,
        shape_Area: 5570793.9183999998,
      },
    },
    {
      type: "Feature",
      id: 8,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.7578712947518085, 50.664735913472391],
            [9.7579282153138198, 50.664829728825289],
            [9.7579559076537912, 50.664875512841938],
            [9.7580096719417337, 50.66496397975574],
            [9.7582895450850913, 50.665005569196182],
            [9.7583219616033219, 50.665104795796431],
            [9.7584189580102407, 50.665327860827112],
            [9.7585183361006163, 50.665549390423841],
            [9.758055817900285, 50.665518702274539],
            [9.7580203309318474, 50.665511738548567],
            [9.7578029537017361, 50.665469175550328],
            [9.7575893439409729, 50.665387242528176],
            [9.7574660138875213, 50.665290531174605],
            [9.7573755850994282, 50.665181653960488],
            [9.7572336006493803, 50.664988683749684],
            [9.7570488099567108, 50.664732076943551],
            [9.7567934013972284, 50.664635728114469],
            [9.7563640122589685, 50.664430314604594],
            [9.7561883707483048, 50.664317402928127],
            [9.7559101999388975, 50.664100340590871],
            [9.7556606305119242, 50.66385259629169],
            [9.755466938005533, 50.663577419808746],
            [9.7552552347526778, 50.663114303434128],
            [9.7551680263716367, 50.662948917483867],
            [9.7551378030656597, 50.662889767352219],
            [9.755073395510065, 50.662761798083253],
            [9.7545459880297134, 50.661717364969419],
            [9.7545226861959815, 50.661683890342914],
            [9.7545005435863157, 50.661660948210198],
            [9.7544244030198417, 50.661597877341478],
            [9.7543865263458969, 50.661558993209532],
            [9.7542502868765091, 50.661506806267965],
            [9.7540014529441397, 50.661469682796138],
            [9.7536604462272578, 50.661439099378875],
            [9.7535079960536901, 50.661441002441229],
            [9.7532414583991862, 50.66149291634067],
            [9.7530304509957748, 50.661529605121181],
            [9.7533332689197572, 50.662349786280402],
            [9.7528715002588484, 50.66233193300787],
            [9.7528160718223234, 50.662350969884159],
            [9.7520019481737865, 50.662517176693562],
            [9.7510907716818576, 50.662983260159635],
            [9.7503593325670916, 50.66335716844916],
            [9.7496235247582277, 50.663733798150737],
            [9.749533814288041, 50.663665154691429],
            [9.7491713180498394, 50.663391952627705],
            [9.748272858795433, 50.662713083377454],
            [9.7480860697790224, 50.662571947553737],
            [9.7480491472268138, 50.662543856054121],
            [9.7476487897637156, 50.662239353188681],
            [9.7475126535292613, 50.662136283344047],
            [9.7469374892542859, 50.661703919007685],
            [9.7451964614988249, 50.660477001048456],
            [9.745114268861645, 50.660376578183332],
            [9.7446662386545064, 50.659829482136907],
            [9.7445923955331768, 50.659738889046871],
            [9.7443531976767801, 50.659448363650313],
            [9.7436369753918015, 50.658573798974111],
            [9.743603968316954, 50.658534367657886],
            [9.7435621360520344, 50.658482582054496],
            [9.7434993816189479, 50.658407646578397],
            [9.7434434753293164, 50.658339906890269],
            [9.743417714792713, 50.65830870301626],
            [9.7427975509139308, 50.657557293400643],
            [9.7423637581530542, 50.657032220826189],
            [9.7423094511097776, 50.656888918698989],
            [9.7430800237587611, 50.65658559625377],
            [9.7437452224473891, 50.656450034507529],
            [9.7444224030446343, 50.656339726207491],
            [9.7440623042482368, 50.656192190470577],
            [9.7443065284506964, 50.656041065336318],
            [9.7442874007318867, 50.656020422242499],
            [9.7443899626966086, 50.655942281926599],
            [9.7445091445596876, 50.655851453882761],
            [9.7446336387195469, 50.655756634771556],
            [9.7444031085592044, 50.655780491557223],
            [9.7445709163245944, 50.655643334722448],
            [9.7447608955794891, 50.655509129607324],
            [9.7433203623452158, 50.653450595946232],
            [9.7439538307312343, 50.65331965088145],
            [9.7441821961401978, 50.653274135196526],
            [9.7444227056595025, 50.653147620802933],
            [9.7446713511570362, 50.653016836114624],
            [9.744955195751368, 50.65286757871182],
            [9.7451257378511791, 50.65277793249804],
            [9.7451564473005199, 50.652761728268224],
            [9.7453126270785067, 50.652426929399518],
            [9.7457807831519201, 50.651423280283012],
            [9.7460777325344434, 50.65021961956289],
            [9.7461514338433748, 50.649985918269429],
            [9.7478514146005359, 50.65038525460092],
            [9.7479968907658314, 50.650419421356261],
            [9.7496880000287476, 50.650816638499862],
            [9.7501606104482956, 50.650852717550727],
            [9.7500256176089835, 50.650383530292949],
            [9.7499176067968225, 50.65014384636725],
            [9.7501101137451602, 50.650033025342559],
            [9.7501261251564486, 50.649991211850363],
            [9.7501535795786367, 50.649983121114616],
            [9.7501442880065383, 50.649950373757811],
            [9.7502748570913695, 50.649901167805027],
            [9.7503505550013347, 50.649895374435943],
            [9.7505815683944803, 50.64985856147625],
            [9.7508676292113954, 50.649850693206687],
            [9.7513202605915712, 50.650030616495968],
            [9.7512515893881666, 50.649950165437879],
            [9.7512194225452422, 50.649839261894577],
            [9.7512728381664466, 50.649772062145317],
            [9.7513952046028702, 50.649682267569553],
            [9.7514219821904078, 50.649632659321249],
            [9.7513762555099195, 50.649503479146674],
            [9.75127123158172, 50.649405258105389],
            [9.7513129095122739, 50.649249578015649],
            [9.751418822263112, 50.649150815349223],
            [9.7515808211230297, 50.649119535533714],
            [9.7516382562195218, 50.649081816355007],
            [9.7515813530866104, 50.648854997270405],
            [9.7514787314081275, 50.648743262127894],
            [9.7513574030467201, 50.648731256140771],
            [9.7511764251238731, 50.648783962870638],
            [9.7510124551870252, 50.648745674479613],
            [9.7508103244012094, 50.648562841286193],
            [9.7508705911337863, 50.648479149009198],
            [9.7508849543955964, 50.648409440132831],
            [9.7509381878570789, 50.648360165131187],
            [9.7509744867330586, 50.648346180619498],
            [9.7514360180291852, 50.648171588295639],
            [9.7517262393549409, 50.648061672245554],
            [9.7520615606669114, 50.647934548213549],
            [9.7520751285973208, 50.647929433452994],
            [9.7521286114295727, 50.647909177336878],
            [9.7539085880484766, 50.647317047513823],
            [9.7542085923374113, 50.647217224264807],
            [9.7551283256774628, 50.64691137179598],
            [9.7551905325017465, 50.646889313053052],
            [9.755252918193408, 50.646868710015809],
            [9.7554975373436541, 50.646816231841704],
            [9.7555840520568271, 50.646797333783198],
            [9.7570202478359782, 50.646497847104804],
            [9.757092382300339, 50.646482818484508],
            [9.7576806175918556, 50.645513134386881],
            [9.7577010121520296, 50.645479654983752],
            [9.7593265447075819, 50.645961452035309],
            [9.7593827218817459, 50.645964773179124],
            [9.7594942883013847, 50.645781286223375],
            [9.7595259672068142, 50.645729296886515],
            [9.7595838718016665, 50.645523964355],
            [9.7596282012612505, 50.645099546903587],
            [9.7596613925389928, 50.644659823633347],
            [9.7596841951073241, 50.644582819050797],
            [9.7597131524394296, 50.644484910100886],
            [9.7598062281565454, 50.644356653701848],
            [9.7599531099757879, 50.644154149166141],
            [9.7598117172675209, 50.644110303002414],
            [9.7597096981349711, 50.644078601609962],
            [9.7597262546683599, 50.644061595435197],
            [9.7598807733005621, 50.643903809370329],
            [9.759974049721194, 50.643857362710328],
            [9.7599329503990813, 50.643736447287182],
            [9.760121412656126, 50.643763717567985],
            [9.7601659922035413, 50.643802259431709],
            [9.7606345622742268, 50.643733848187125],
            [9.7610194326013193, 50.643622661426882],
            [9.7610452428852454, 50.643619075445294],
            [9.7613210351738484, 50.643581238150979],
            [9.761351643470471, 50.643576991229835],
            [9.7616150305988505, 50.643540844071978],
            [9.7617065044378286, 50.643523896525849],
            [9.7620112696037769, 50.643467342198342],
            [9.7623231481269865, 50.643360963130817],
            [9.7624606528184259, 50.643286903755403],
            [9.7629108937363398, 50.643044350852151],
            [9.7634838593722666, 50.642702885250209],
            [9.7635929670621948, 50.642637922844685],
            [9.7638117106558475, 50.642478694382888],
            [9.7638304417685262, 50.642465090781911],
            [9.7640372877517692, 50.642314474432347],
            [9.7642726019998509, 50.642089737812299],
            [9.7642792674939329, 50.642082148791189],
            [9.7643073056700498, 50.64205031778291],
            [9.7643361763910868, 50.642017501046773],
            [9.7642426959629205, 50.64200554166419],
            [9.7625923780270707, 50.640857335482664],
            [9.7624389226752637, 50.640906606216397],
            [9.7623659439018411, 50.640930007393273],
            [9.7622914262824168, 50.640877524877574],
            [9.7622100414246695, 50.640820158953396],
            [9.7621852925057055, 50.640802703133517],
            [9.7620252718770555, 50.640690012547466],
            [9.761899898196658, 50.640601637685251],
            [9.7625783433293325, 50.639816229138191],
            [9.7631126608956826, 50.639197721240848],
            [9.7627754463849641, 50.638767148660605],
            [9.7625968767384013, 50.638539080388753],
            [9.7625523066709334, 50.638370722298227],
            [9.7624107170967171, 50.63783478077611],
            [9.7622706486579247, 50.637305250246271],
            [9.7621467557933528, 50.636261237592727],
            [9.7624027215669038, 50.635161383064009],
            [9.7620805703495233, 50.635146826015351],
            [9.7633980720563471, 50.634052180338458],
            [9.7637076660653559, 50.633605508659059],
            [9.7641121365270571, 50.633312429779693],
            [9.7644626948357214, 50.633268392823716],
            [9.7652350805320047, 50.633564658789822],
            [9.7658405912427142, 50.633924492483409],
            [9.7659135223235793, 50.633965058189979],
            [9.7664273491290139, 50.634194909844048],
            [9.766568689579751, 50.634290900050267],
            [9.7667110504262222, 50.634387863631858],
            [9.7677650721383102, 50.634413658724561],
            [9.768830402061182, 50.634689633109559],
            [9.7695187532413765, 50.63485311860326],
            [9.7704314566745989, 50.635267700538037],
            [9.7713414192085253, 50.635600230308711],
            [9.7718549576674807, 50.635651751324694],
            [9.7731367939142153, 50.636188719257973],
            [9.7732742089944882, 50.636095123256013],
            [9.7734791545336712, 50.636120014222854],
            [9.773609539283294, 50.636046150656526],
            [9.773709677756127, 50.636067150455823],
            [9.7740512062565568, 50.636307771191127],
            [9.7741432478805255, 50.636447031333816],
            [9.7742156239103934, 50.637067730358886],
            [9.7741437844832983, 50.637645293719444],
            [9.7742540323523297, 50.638061891650786],
            [9.773707559530262, 50.638577262667454],
            [9.773132375699948, 50.639171629125308],
            [9.7732552841912685, 50.639712852835324],
            [9.7732232317652201, 50.639858126133049],
            [9.7732016408495799, 50.63996353501377],
            [9.7731442521526102, 50.640143717196274],
            [9.7730980435075292, 50.640214053962104],
            [9.7730544434715689, 50.640321857131077],
            [9.7729975295187934, 50.640462933572344],
            [9.7729467229788849, 50.640528894097933],
            [9.7728947940996012, 50.640646285256381],
            [9.7729009421510149, 50.640719332347011],
            [9.7728210403877505, 50.641087729796439],
            [9.7727797337535272, 50.64125525956397],
            [9.772760042321492, 50.641290319800206],
            [9.772682317425927, 50.641429366703072],
            [9.772592080238784, 50.641590251095685],
            [9.7725132157155414, 50.64172023127886],
            [9.7724702805752219, 50.641783351498326],
            [9.7718595915961775, 50.642121648999215],
            [9.7717885524438319, 50.642281425315346],
            [9.7716466396733637, 50.642516179699982],
            [9.7715798472235953, 50.64258126519497],
            [9.7714764704383636, 50.642682008135765],
            [9.7716405673183129, 50.642766312167872],
            [9.7715232553249241, 50.642889270726158],
            [9.7714268288655592, 50.643017208038103],
            [9.7711833398175276, 50.643291125164694],
            [9.7701876505895129, 50.64403586410431],
            [9.770262815868854, 50.644119327892412],
            [9.7703032304932922, 50.644221808167323],
            [9.7704233072324058, 50.644346235806509],
            [9.770354417412733, 50.644404409664737],
            [9.7705713953824702, 50.644453759717024],
            [9.7707111369082824, 50.644526543914658],
            [9.7707364508847476, 50.644543643451044],
            [9.7705400290320625, 50.644575951022865],
            [9.7703622315530509, 50.6445449578449],
            [9.7701986091862025, 50.644533179127464],
            [9.7700219028217354, 50.64455161389084],
            [9.7697989055799184, 50.644653235609631],
            [9.7696760481494209, 50.644634720739077],
            [9.7695889834589558, 50.644437535098007],
            [9.7694182579601474, 50.644416008591477],
            [9.7691137734261133, 50.644652471595002],
            [9.7686221084136129, 50.644793626474325],
            [9.7683417760235951, 50.644777776555465],
            [9.7681692482496132, 50.64480102829242],
            [9.768189297341614, 50.64489429958558],
            [9.7680534161199013, 50.645032107860267],
            [9.7678750142190598, 50.645138459139979],
            [9.7679030684236103, 50.645168392525406],
            [9.7679854076295314, 50.645259985312812],
            [9.7685502730592209, 50.645860092139536],
            [9.7687806652064921, 50.646072439862323],
            [9.7688015832173729, 50.646098373165081],
            [9.7689614550521959, 50.646303802333676],
            [9.7690592839044985, 50.646398718551239],
            [9.7691796381050615, 50.646521805612224],
            [9.7692738475323715, 50.646576914629641],
            [9.7693608874267284, 50.646668484396059],
            [9.7694610892676597, 50.646796011957044],
            [9.7695490322700067, 50.646847573839494],
            [9.7695958563144529, 50.646866141352653],
            [9.7697145080710666, 50.646971441546611],
            [9.7699674939171821, 50.647259594279042],
            [9.7699885646365239, 50.647283547851899],
            [9.7699994315385865, 50.647295904657391],
            [9.7699307247884342, 50.647319704986373],
            [9.7708365278390339, 50.648541415105718],
            [9.7717340002361084, 50.649751652909472],
            [9.7717675460859592, 50.649797008298549],
            [9.7713476842237039, 50.649897004667757],
            [9.7720726669144415, 50.651156737692098],
            [9.7718287520751055, 50.651211880958058],
            [9.7718023126101308, 50.651217847688265],
            [9.7700461226152129, 50.651557420543817],
            [9.7687250436273718, 50.651810919383841],
            [9.768727930302159, 50.651849112438839],
            [9.768069200983156, 50.652019128568483],
            [9.7682308295065159, 50.652842037843065],
            [9.7683810225830037, 50.653604444030307],
            [9.7684883062110135, 50.653575686837129],
            [9.7688856223898473, 50.653469203699267],
            [9.7688999337704185, 50.653565965987049],
            [9.7695395276086181, 50.653470409361233],
            [9.7698404580570379, 50.653393985129085],
            [9.7700782778243536, 50.65334591903062],
            [9.7704233391724404, 50.654462688606081],
            [9.770486955045282, 50.654668401033426],
            [9.7704950772522778, 50.654704841714874],
            [9.7705399130158881, 50.654905741006587],
            [9.7706404029549283, 50.655306361818461],
            [9.7706534025486356, 50.655358148592569],
            [9.7706827813242523, 50.65535615567233],
            [9.7707430782101525, 50.655352006744742],
            [9.7718004137040229, 50.655279304842601],
            [9.7717975385433231, 50.655367151412797],
            [9.7718288667817443, 50.65556870622575],
            [9.771895569536559, 50.655787932173169],
            [9.7719555271990775, 50.655941714326168],
            [9.7719834389803033, 50.656013322092122],
            [9.7720884843453248, 50.656282745727466],
            [9.7721396118912942, 50.656413878465926],
            [9.772359079873846, 50.656501141998831],
            [9.7724488365636528, 50.656675770458421],
            [9.7725091329615665, 50.656793100658852],
            [9.7725157383861507, 50.656805953109966],
            [9.7726568203139923, 50.657080443306498],
            [9.7728073735863319, 50.657373342530647],
            [9.7728372157699663, 50.657431411519362],
            [9.773536402574603, 50.657356625866193],
            [9.7737681696962984, 50.657331830885404],
            [9.7738239967032161, 50.657325866443365],
            [9.7739777407728603, 50.657305411197093],
            [9.7740277456132283, 50.657298990698379],
            [9.7746760871084248, 50.657148795224295],
            [9.7748295253688191, 50.657113250307965],
            [9.7749528391576472, 50.657285486370803],
            [9.7748658112214688, 50.657419200256939],
            [9.7748546339643632, 50.657436352644069],
            [9.7746608816322844, 50.657400966472501],
            [9.7745643169022447, 50.657411662702053],
            [9.7745082684604547, 50.657442872858915],
            [9.7744679087148096, 50.657491236664647],
            [9.774447744526185, 50.657554655751667],
            [9.7745178390242273, 50.657551671836259],
            [9.7745303331822431, 50.65763178108709],
            [9.7746317039186135, 50.657750671806781],
            [9.7746307220143116, 50.657871591786389],
            [9.774664360318468, 50.65796504126763],
            [9.7747073724014193, 50.657999630945099],
            [9.774896953913542, 50.658038471179403],
            [9.774917648212643, 50.658093263986892],
            [9.7748865275927113, 50.658170047932195],
            [9.7748228582823717, 50.658219646021593],
            [9.7745490830778206, 50.658246188178168],
            [9.7744786218570621, 50.658106506574704],
            [9.7739919042423082, 50.658186946609391],
            [9.7732152794932645, 50.658317143194203],
            [9.7730907085394225, 50.658052598575708],
            [9.7730332564233482, 50.658058735320701],
            [9.7729091717473011, 50.658071959937345],
            [9.7726890294671858, 50.658099311127756],
            [9.7723260123012725, 50.658166540351445],
            [9.7722283447023326, 50.658202422878517],
            [9.7721340839273125, 50.658247635668431],
            [9.7719389536367238, 50.658341441265343],
            [9.7715003069084343, 50.65858097471105],
            [9.7716070038686755, 50.658645352035542],
            [9.7716234760576928, 50.658699993504982],
            [9.7715198349095154, 50.658806592448819],
            [9.7714526459942963, 50.65881261300612],
            [9.7714558228365558, 50.65885798959043],
            [9.7716085257000529, 50.658828020594385],
            [9.7717096431232324, 50.658812611226637],
            [9.7717721988285575, 50.658834661964761],
            [9.7718396065218514, 50.65885838924109],
            [9.7719477078230561, 50.658890129665302],
            [9.7720652405015365, 50.658850779307443],
            [9.7721080752667486, 50.658791787993046],
            [9.7721443124241603, 50.65887919552366],
            [9.7721522668864154, 50.658898379250978],
            [9.7721982084937675, 50.659009005830022],
            [9.7714362741311653, 50.659037847854279],
            [9.7713694972873455, 50.659027587965113],
            [9.7705483846647656, 50.659046275842726],
            [9.7703167391521504, 50.659079121482598],
            [9.7698795536954535, 50.659107811825663],
            [9.7695675606357497, 50.659123335250314],
            [9.7692467532601466, 50.659229801164066],
            [9.7688275893760075, 50.659315619224934],
            [9.7687167566586606, 50.659354823187705],
            [9.7685340975560528, 50.659419393863878],
            [9.7682287912334793, 50.659507588502805],
            [9.7680259570245287, 50.659543621092112],
            [9.7679836776426363, 50.659551139225144],
            [9.7676824306683301, 50.659604663926388],
            [9.7673989016848797, 50.659627251534189],
            [9.7662775169138456, 50.659726324673223],
            [9.7659776284842241, 50.65976613940672],
            [9.7658667229776146, 50.659781940861151],
            [9.7654752727596694, 50.659856952748932],
            [9.7650689148006666, 50.659961055208953],
            [9.7649941091444763, 50.659986079668833],
            [9.7648928064221447, 50.660019983391052],
            [9.763716732963621, 50.660413584758082],
            [9.7635675542028455, 50.660452972128368],
            [9.7629523937958886, 50.660695635008317],
            [9.7624328636901065, 50.660964037044828],
            [9.7623144786581104, 50.661034580565236],
            [9.7617218835124397, 50.661440666249653],
            [9.7613867431425572, 50.661689170425802],
            [9.7612710355819896, 50.661725125596156],
            [9.761305222805813, 50.661776109442592],
            [9.7614022094374988, 50.661946508137909],
            [9.7616107646910937, 50.662098766912429],
            [9.7618864565278489, 50.662231691770273],
            [9.7606895567389405, 50.662594407922022],
            [9.7604550501887086, 50.66239029131323],
            [9.7604172915678458, 50.662389728296802],
            [9.7602719496390549, 50.662528838711886],
            [9.7599780315913875, 50.662637531697406],
            [9.7596494296135106, 50.663087165521297],
            [9.759562543989281, 50.663119028070902],
            [9.7595009187434059, 50.663463400698866],
            [9.7594742080842831, 50.663612717594965],
            [9.759484166324409, 50.663661656516709],
            [9.7595419403944312, 50.663945958986176],
            [9.7595550496576653, 50.664010408577667],
            [9.7595543512394745, 50.664069732113802],
            [9.7595500304893701, 50.664329896855136],
            [9.7595454606831691, 50.664612339300994],
            [9.7594321061344882, 50.664692065040192],
            [9.7594052934050186, 50.664692122841195],
            [9.7592478421048323, 50.664687258143346],
            [9.7591071203109276, 50.664650205616638],
            [9.7590537270175322, 50.664749847008821],
            [9.7589692363243223, 50.664807602835857],
            [9.7587955667748929, 50.664826297338308],
            [9.7582540965910525, 50.664752345220805],
            [9.7581890062112588, 50.664743451754191],
            [9.7578463379997302, 50.664694806083325],
            [9.7578712947518085, 50.664735913472391],
          ],
        ],
      },
      properties: {
        FID: 8,
        OBJECTID: 26,
        gemarkungs: "Nüst",
        FIRST_geme: "Hünfeld",
        shape_Leng: 12725.591618300001,
        shape_Area: 3922717.6450899998,
      },
    },
    {
      type: "Feature",
      id: 9,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.7068566698704757, 50.655548615731824],
            [9.7055614385104789, 50.655636638673769],
            [9.7043572231939699, 50.655849470882515],
            [9.7041776091009133, 50.655904092632703],
            [9.7041643931060637, 50.655907697808885],
            [9.7038688180744614, 50.65598711237994],
            [9.7033658759899346, 50.656131044842802],
            [9.702929453507652, 50.656286543824997],
            [9.7023025907799365, 50.656530116886699],
            [9.7009647237895802, 50.657194861304717],
            [9.7007698289749911, 50.657285164618493],
            [9.7007418501247837, 50.657234143771596],
            [9.7007122569605038, 50.657180164880124],
            [9.6973776045218969, 50.655470197227281],
            [9.697221093916621, 50.655386813778513],
            [9.6972517465877885, 50.65544868322214],
            [9.6969700178602665, 50.656047514512373],
            [9.6964605261155423, 50.657130414357361],
            [9.6963842120939088, 50.657144557982988],
            [9.6937115153861182, 50.655767232333609],
            [9.6936897330319702, 50.655699310732331],
            [9.6935618093039455, 50.655742061186473],
            [9.6934927183406838, 50.655688450418694],
            [9.6933151026691, 50.655718186411761],
            [9.6928413430871618, 50.655678322369681],
            [9.6924901349013375, 50.655693009224535],
            [9.6924774179092701, 50.655693084789156],
            [9.692459298578596, 50.655693282383815],
            [9.6923293124614336, 50.655700709604027],
            [9.6921485946841255, 50.655721568009113],
            [9.6914079617362034, 50.655806534114205],
            [9.6910626203117953, 50.655846092914096],
            [9.6906946973224777, 50.655888383548152],
            [9.6903510554662198, 50.655878935423097],
            [9.6918186741994994, 50.653485389410683],
            [9.6916958786092948, 50.653462835005968],
            [9.6901498056112363, 50.653179111847848],
            [9.6899625967922596, 50.653093292433454],
            [9.688829437640269, 50.652583887038048],
            [9.6870324617698245, 50.652284608251733],
            [9.6867593184321521, 50.652239022085276],
            [9.6859889203149603, 50.652110946314458],
            [9.6857799790369743, 50.652085214537394],
            [9.6830623183449589, 50.651632523522522],
            [9.682931618068876, 50.651608558324781],
            [9.682989751309373, 50.651551587677993],
            [9.6838113635769805, 50.650620212655753],
            [9.6842597150287375, 50.650237412802234],
            [9.6843879108462563, 50.650088245764707],
            [9.6848984843291497, 50.649599902861183],
            [9.6854504659156735, 50.648645108182279],
            [9.6854604424801014, 50.648338238497324],
            [9.6858696050856548, 50.646954668716404],
            [9.6867699212048848, 50.645939927493266],
            [9.6868629916377866, 50.645806243993221],
            [9.6868028211293904, 50.64600499706583],
            [9.6868974370642054, 50.646024844905476],
            [9.6878378883767891, 50.646213757055769],
            [9.6890954466236057, 50.646466598950958],
            [9.6902852345804966, 50.646705610538973],
            [9.6904379648322383, 50.646736541575905],
            [9.6907979036756888, 50.646228884059809],
            [9.6908844871184119, 50.646106899937166],
            [9.6911358180711886, 50.645752445411347],
            [9.6915064966329982, 50.645226843458659],
            [9.6915553769534082, 50.645157422831758],
            [9.6916202413095522, 50.645068850774173],
            [9.6922438531781445, 50.644216920299705],
            [9.6935308893087608, 50.644692938808944],
            [9.6936409036759361, 50.644733634647842],
            [9.6942948079497029, 50.644975542080203],
            [9.6956415999447074, 50.645473511317469],
            [9.6956914884913257, 50.645491919306444],
            [9.696604874197682, 50.645829723477291],
            [9.6973244673997119, 50.646094675438057],
            [9.697428386543292, 50.646133074944316],
            [9.69804266490336, 50.646360341780323],
            [9.6988112533722948, 50.646648966882907],
            [9.6989465088925293, 50.646655251334593],
            [9.6999581768921317, 50.646239350937009],
            [9.7002536234650041, 50.646226325475894],
            [9.7015782239721613, 50.646167771650681],
            [9.7018912256596739, 50.646613495354408],
            [9.701975934769921, 50.646699832241957],
            [9.702115719693893, 50.646944503904123],
            [9.7022554355158181, 50.647108533974134],
            [9.7025504337542952, 50.647330686019529],
            [9.703403305627381, 50.647683388703001],
            [9.7039936190411265, 50.648214702297899],
            [9.7044497947017216, 50.648805522287851],
            [9.7049467376488447, 50.649143286666913],
            [9.7050340112618656, 50.64913566307758],
            [9.7056480475931171, 50.649085793553695],
            [9.705745766425883, 50.64907765652277],
            [9.7064640029521119, 50.649018077484627],
            [9.7070576680978, 50.648968243081192],
            [9.7072151296928784, 50.648955065867263],
            [9.707133204321563, 50.64866780830458],
            [9.7091876872848584, 50.648952557062962],
            [9.7093657220277283, 50.649237609426244],
            [9.7093268085430662, 50.649269511273197],
            [9.7096150896546121, 50.649671756728829],
            [9.7097766906521681, 50.649974490169988],
            [9.7098564124872766, 50.650123687023914],
            [9.7098973538865536, 50.650200041510104],
            [9.7098114518292196, 50.650444261870106],
            [9.7096256950966779, 50.650719397250228],
            [9.709612090835229, 50.650922456069949],
            [9.7095389131203707, 50.65106405852579],
            [9.7094740646499247, 50.651189557405289],
            [9.7094647351297976, 50.651298935240625],
            [9.7094627184276057, 50.651323759688175],
            [9.7094574050375613, 50.651385161461739],
            [9.7094965826515338, 50.651517185569844],
            [9.7095311438623781, 50.651632906164195],
            [9.7098255307242081, 50.651990534630819],
            [9.7100296348213515, 50.6522378535917],
            [9.7101076670233635, 50.652332840675712],
            [9.7104388625898199, 50.652735883626534],
            [9.7105801502695233, 50.652907259471306],
            [9.7110870489290591, 50.653522906866549],
            [9.7111371884677382, 50.653585076484127],
            [9.7114529562961263, 50.653968614522441],
            [9.7116732306928473, 50.654237316523357],
            [9.7122139620554506, 50.654895550821422],
            [9.7128718236473599, 50.656006582688093],
            [9.7133619164601495, 50.656831962534746],
            [9.7133363128095116, 50.656917149659279],
            [9.7132583256513332, 50.656894262735634],
            [9.7132324913596015, 50.656886704706906],
            [9.710808498052236, 50.656127994062601],
            [9.7106301915345803, 50.656024149176559],
            [9.7097057954754948, 50.655853345264262],
            [9.709186780227494, 50.655737516203345],
            [9.7087758556013863, 50.655645551864026],
            [9.7082017148630513, 50.655580550311022],
            [9.7075701419046556, 50.655540203178063],
            [9.7068566698704757, 50.655548615731824],
          ],
        ],
      },
      properties: {
        FID: 9,
        OBJECTID: 27,
        gemarkungs: "Oberfeld",
        FIRST_geme: "Hünfeld",
        shape_Leng: 6740.2434124399997,
        shape_Area: 1716136.0145700001,
      },
    },
    {
      type: "Feature",
      id: 10,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.6860596643729426, 50.664221477966123],
            [9.6858642369095911, 50.664252935096577],
            [9.6854711209941851, 50.664316104485245],
            [9.6844244040607634, 50.66432838935529],
            [9.6843957010270323, 50.664328827704651],
            [9.6824657243814727, 50.664350693991253],
            [9.6823360583620488, 50.664440539297196],
            [9.6821950054686301, 50.664817755095683],
            [9.6821173245043379, 50.665303183033124],
            [9.6820394777298144, 50.665348496417351],
            [9.6812455946260698, 50.665358814183108],
            [9.6811430852126179, 50.665351418419611],
            [9.6810542104558941, 50.665298626444169],
            [9.6809366259155851, 50.665188113279413],
            [9.6810074101850798, 50.664733734801572],
            [9.6812388925844335, 50.664006761116916],
            [9.6814251629618617, 50.663314126254988],
            [9.6814496115470785, 50.663225077061327],
            [9.6813999927278278, 50.663158763930099],
            [9.6809565969772429, 50.66299127705534],
            [9.6805849170391394, 50.662874064092165],
            [9.6802938020777916, 50.6627821990295],
            [9.6802486155138983, 50.662767893882396],
            [9.6798617450614959, 50.662645919970998],
            [9.6798359036805497, 50.662638696331307],
            [9.6797840667443413, 50.662624348858685],
            [9.6793292020766177, 50.663346947388305],
            [9.6792502021194622, 50.663380943277097],
            [9.6771202141344208, 50.663044268756053],
            [9.677086559007714, 50.662995469628385],
            [9.6769504995887168, 50.663054418667436],
            [9.6771116039411904, 50.662878458201789],
            [9.6772011131138402, 50.662572656813893],
            [9.6772525441468851, 50.662313912925164],
            [9.6773328205149305, 50.662122782709815],
            [9.6774806085669542, 50.661945999772847],
            [9.6776790288489636, 50.661789507553841],
            [9.6779477496689292, 50.661661294211697],
            [9.6783340496904948, 50.661528519904522],
            [9.678532336209587, 50.661440626725486],
            [9.6786591031790685, 50.661356836888132],
            [9.6787735225458125, 50.661248828331544],
            [9.6788789492877214, 50.661096122134389],
            [9.6789025598078684, 50.660918270984126],
            [9.6789045271545238, 50.660750519305516],
            [9.6788662007304058, 50.660230524824939],
            [9.6788739633268044, 50.660061399390656],
            [9.6788920970711203, 50.65996591240598],
            [9.6789603461345202, 50.659882373144001],
            [9.6791076624150367, 50.659800333644469],
            [9.6789631325422967, 50.659692943168956],
            [9.6789025130417254, 50.659648168680448],
            [9.6786761005726092, 50.659481288479341],
            [9.6778751748943908, 50.659626521754944],
            [9.6769651772716276, 50.659659644997291],
            [9.6769814303861903, 50.659614162157389],
            [9.6769995746475672, 50.659563434315629],
            [9.6770275684982092, 50.659484347807748],
            [9.6771841437899777, 50.659043502170071],
            [9.6772137557508291, 50.658958920384741],
            [9.677022030492445, 50.658873816970178],
            [9.6769209862577465, 50.658750793044952],
            [9.6768914038744782, 50.658611085383711],
            [9.6773540389156842, 50.657535163571794],
            [9.6779291282931013, 50.656306295486353],
            [9.6781800819312505, 50.655863082570839],
            [9.6789102486680871, 50.654790336208102],
            [9.6792701089223474, 50.654141810986921],
            [9.6793391436251959, 50.654016268767826],
            [9.6792026808954059, 50.653997198278709],
            [9.678143175280761, 50.653851168155207],
            [9.6776974276115748, 50.653789567950341],
            [9.6777516777231156, 50.65373639966316],
            [9.6772203375992856, 50.653278688041013],
            [9.6771957147056522, 50.653257436397453],
            [9.6764453514136122, 50.65261184959428],
            [9.6766207226959065, 50.652457471142185],
            [9.6774275124268723, 50.651746569083009],
            [9.6779779157674852, 50.65126152361465],
            [9.6781021826296882, 50.651152298079559],
            [9.6784117570537145, 50.651019609811762],
            [9.679619051479623, 50.650503040614815],
            [9.6796972775848982, 50.650408102191719],
            [9.6797996714896026, 50.650360758458952],
            [9.6813922616493695, 50.64976685658074],
            [9.6824958713182063, 50.649718346405152],
            [9.6842176175355377, 50.650123401785457],
            [9.6843879108462563, 50.650088245764707],
            [9.6842597150287375, 50.650237412802234],
            [9.6838113635769805, 50.650620212655753],
            [9.682989751309373, 50.651551587677993],
            [9.682931618068876, 50.651608558324781],
            [9.6830623183449589, 50.651632523522522],
            [9.6857799790369743, 50.652085214537394],
            [9.6859889203149603, 50.652110946314458],
            [9.6867593184321521, 50.652239022085276],
            [9.6870324617698245, 50.652284608251733],
            [9.688829437640269, 50.652583887038048],
            [9.6899625967922596, 50.653093292433454],
            [9.6901498056112363, 50.653179111847848],
            [9.6916958786092948, 50.653462835005968],
            [9.6918186741994994, 50.653485389410683],
            [9.6903510554662198, 50.655878935423097],
            [9.6906946973224777, 50.655888383548152],
            [9.6910626203117953, 50.655846092914096],
            [9.6914079617362034, 50.655806534114205],
            [9.6921485946841255, 50.655721568009113],
            [9.6923293124614336, 50.655700709604027],
            [9.692459298578596, 50.655693282383815],
            [9.6924774179092701, 50.655693084789156],
            [9.6924901349013375, 50.655693009224535],
            [9.6928413430871618, 50.655678322369681],
            [9.6933151026691, 50.655718186411761],
            [9.6934927183406838, 50.655688450418694],
            [9.6935618093039455, 50.655742061186473],
            [9.6935209340570534, 50.655799384039909],
            [9.6927807955644028, 50.656835337749868],
            [9.6927536065039241, 50.656872982864925],
            [9.6927286964544166, 50.657961914764989],
            [9.69270900064115, 50.658815806281133],
            [9.6928187046313266, 50.658899554949791],
            [9.6926315939469649, 50.658889623530563],
            [9.6925124378550755, 50.658883685689531],
            [9.6929698217109159, 50.659173775158543],
            [9.6928219034609278, 50.659155436217013],
            [9.6926574359452768, 50.659126772378677],
            [9.6927008787308786, 50.659195635577305],
            [9.6917424562641781, 50.660544321099451],
            [9.6916488851209603, 50.660566909677087],
            [9.691659985801321, 50.660638753058876],
            [9.691728801319675, 50.660702151064662],
            [9.6918242906483858, 50.661428321003505],
            [9.6919402873195324, 50.662308709499548],
            [9.6918759152221075, 50.662345675804779],
            [9.6895467101543851, 50.662049620891111],
            [9.6895268248816269, 50.662111035859837],
            [9.6894936549982074, 50.66210683444973],
            [9.6883083217895365, 50.661957953685004],
            [9.6870682799559731, 50.661802089965548],
            [9.6869639005022119, 50.661898797079807],
            [9.6871556373755432, 50.662584662473982],
            [9.6873593989345448, 50.663308758385242],
            [9.6873850487660871, 50.663399671727589],
            [9.6875986338205298, 50.664157442490726],
            [9.6876813206212038, 50.664201812449008],
            [9.6874537685864794, 50.664202795160655],
            [9.6862830337790928, 50.664206313434299],
            [9.6861735643800433, 50.664176579138719],
            [9.6860596643729426, 50.664221477966123],
          ],
        ],
      },
      properties: {
        FID: 10,
        OBJECTID: 28,
        gemarkungs: "Oberrombach",
        FIRST_geme: "Hünfeld",
        shape_Leng: 6293.8133858299998,
        shape_Area: 1317678.3447,
      },
    },
    {
      type: "Feature",
      id: 11,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.782680223496806, 50.727245725273647],
            [9.7819147154803172, 50.727463871619577],
            [9.7816813663194093, 50.726754235826952],
            [9.7809003440536166, 50.72595454280065],
            [9.7806457229147625, 50.725634502048806],
            [9.7803006843279672, 50.725268337769471],
            [9.7791258660192675, 50.725552902489461],
            [9.7798441129667797, 50.726239867218759],
            [9.7791221184939907, 50.726499836849968],
            [9.7780518467643738, 50.726765175196363],
            [9.7779871884718741, 50.726685136493849],
            [9.7777304542952059, 50.726367441751677],
            [9.7773715302602895, 50.726459100688537],
            [9.7768382880796452, 50.726562522900451],
            [9.7761221367513613, 50.726610611798023],
            [9.7759304509296516, 50.725916542071232],
            [9.7758361216957894, 50.725925254561069],
            [9.7747872706056764, 50.726021584201924],
            [9.7742891708669752, 50.725053459671699],
            [9.7742763747698493, 50.725006888503863],
            [9.7733889976811401, 50.724949133806298],
            [9.7729332363665122, 50.724920958978601],
            [9.7724102013154273, 50.72485798414327],
            [9.7719611503489379, 50.724782844125059],
            [9.771373324734121, 50.724628150413153],
            [9.7708612669480104, 50.72449128974224],
            [9.7705956675300438, 50.724418888417787],
            [9.7705340365602087, 50.72445075354856],
            [9.7700941334531066, 50.724283303792873],
            [9.7698310846474392, 50.724235857687759],
            [9.7693105695580407, 50.724116672507719],
            [9.768965838605471, 50.723949300960335],
            [9.768899453707343, 50.723916662182717],
            [9.7683365551306931, 50.723639797557055],
            [9.7679875279195372, 50.723330513516835],
            [9.7677400170037938, 50.723108125722156],
            [9.7674597307283193, 50.722726478692614],
            [9.7677180686489979, 50.722584269922798],
            [9.7673215851613033, 50.722276186642901],
            [9.7671215107812301, 50.721999805563669],
            [9.7670074852082518, 50.721805675465973],
            [9.7669472702775391, 50.721702920824782],
            [9.7668856522301333, 50.721705133739761],
            [9.766997654371135, 50.72142303017219],
            [9.7669287006439252, 50.721415839629039],
            [9.7669404054889064, 50.721396067767195],
            [9.7668674770876738, 50.721394461063014],
            [9.7668358946760598, 50.721186721540313],
            [9.7669032705763552, 50.720823002456484],
            [9.7668830055857452, 50.720815644504434],
            [9.7660673075329907, 50.720519360570137],
            [9.7656730101860649, 50.720376721174645],
            [9.7652123839414617, 50.720178156083669],
            [9.7649391228749192, 50.72006035008129],
            [9.7641403610359419, 50.719938047828947],
            [9.7641059503422003, 50.71993277857063],
            [9.7638669094251913, 50.71992329240738],
            [9.7628669143455724, 50.719923625465164],
            [9.7623577124070771, 50.719889095160859],
            [9.7620570325472329, 50.719849791521057],
            [9.7617292949170462, 50.719837535242043],
            [9.7612056650055781, 50.719732525384693],
            [9.7609929851276132, 50.719681681859299],
            [9.7606586698456752, 50.719601675585018],
            [9.760293882768682, 50.719504069735954],
            [9.7599871750555582, 50.719415886649294],
            [9.7592708755021071, 50.719171193112452],
            [9.7586635420240135, 50.718938385507997],
            [9.7584575386059775, 50.718859327558022],
            [9.7574810342427458, 50.718484989333682],
            [9.7577137551275079, 50.718154033205209],
            [9.7574134918494781, 50.717937506021514],
            [9.7580940341946558, 50.717478724966199],
            [9.7571819683272292, 50.716734052280856],
            [9.7576778402475046, 50.716477486366884],
            [9.758095817268023, 50.716174687725136],
            [9.7572497688627813, 50.715589843144329],
            [9.757545048957688, 50.715325119664485],
            [9.7567584413715185, 50.714864142860684],
            [9.7568567438078126, 50.714787549055771],
            [9.7564678955283188, 50.714376776938799],
            [9.7561970619850396, 50.714090332105819],
            [9.756897876782098, 50.713491088843327],
            [9.7562845661715123, 50.713319874362817],
            [9.756639193469999, 50.712658837855066],
            [9.7567666261152297, 50.712378981832067],
            [9.7569519177850204, 50.71197253183513],
            [9.7572153083541764, 50.711130200221326],
            [9.7570523089775936, 50.711094782757591],
            [9.7568050961306465, 50.711041098193057],
            [9.7562617317631801, 50.710936931744349],
            [9.7561005172645689, 50.710852138233754],
            [9.7559733300201898, 50.710576613972393],
            [9.7558839303247655, 50.710193520874469],
            [9.7552199420403607, 50.710264363975043],
            [9.7540432554665308, 50.710271980471937],
            [9.7540384987524753, 50.710257990990286],
            [9.7539471550021624, 50.709991081900462],
            [9.753863932299625, 50.70979774675331],
            [9.753798864939176, 50.709646642680184],
            [9.7537857716989471, 50.709616213736602],
            [9.7536671897719227, 50.709295980537561],
            [9.7534519025340387, 50.708836907164986],
            [9.7534436868489554, 50.708819162906948],
            [9.7531664965452425, 50.708267167573005],
            [9.752924620461787, 50.707842510685161],
            [9.7529010074228051, 50.707798057377815],
            [9.7540388219542802, 50.707488202574723],
            [9.7544548418775925, 50.707374913835253],
            [9.7547231683938893, 50.707301897695444],
            [9.7547299198905772, 50.706561559325934],
            [9.7547360985471236, 50.705889635156005],
            [9.7547366621798446, 50.705822417005862],
            [9.7547411214895785, 50.705246289503229],
            [9.754745258193088, 50.704704760532053],
            [9.7547473093914583, 50.704315606317479],
            [9.7547476052960871, 50.704246708170615],
            [9.7547488621804384, 50.703960683090195],
            [9.7547504746135907, 50.70362799942388],
            [9.7547514593289701, 50.703428229025903],
            [9.7547524192441983, 50.703234852885501],
            [9.754753232934954, 50.703061478370714],
            [9.754754914083632, 50.702713667790178],
            [9.7547561176453392, 50.702468426756091],
            [9.7547563356476914, 50.702421139546964],
            [9.7546318961632483, 50.701999007923838],
            [9.7548388370529953, 50.701990653536122],
            [9.7553232786639601, 50.701971067363374],
            [9.7560332835815782, 50.702027771042694],
            [9.7568350483067512, 50.702081536511052],
            [9.7570339806234951, 50.702093815461936],
            [9.7571535627420118, 50.702100413210886],
            [9.7576594903513669, 50.702215502581438],
            [9.7580873867457374, 50.702275735586035],
            [9.7593611891356211, 50.702427819530577],
            [9.760145529493828, 50.702491918905459],
            [9.760359885391674, 50.702564588312541],
            [9.7604789135087859, 50.702606870954561],
            [9.7604546969351773, 50.70250086517936],
            [9.7599792324596688, 50.702135131022445],
            [9.7603308085821645, 50.702003031875996],
            [9.7603751619804733, 50.701953208496803],
            [9.760476930132679, 50.701839006555616],
            [9.7602760474391008, 50.701488919712048],
            [9.7600503109146661, 50.701361034654433],
            [9.7596951422643272, 50.70120218403995],
            [9.7594174505311972, 50.701046946508946],
            [9.7591355480749904, 50.700901808029421],
            [9.7588975538594234, 50.700663340228743],
            [9.7588623126945055, 50.700628514460284],
            [9.758727958614525, 50.700495175658439],
            [9.758979162139056, 50.700509898931564],
            [9.7590769307170682, 50.700490017084149],
            [9.7593773222511082, 50.700428993910002],
            [9.7597051844388432, 50.70031890434084],
            [9.759779787696985, 50.700293875915975],
            [9.7599847441693246, 50.700137858269485],
            [9.7600541643919652, 50.700014721531367],
            [9.7600684808283305, 50.699989339534326],
            [9.7601613434295533, 50.699824627567359],
            [9.7604289132018067, 50.699566173854116],
            [9.7606639024530502, 50.699406928390857],
            [9.7609389827656372, 50.699252393956975],
            [9.7612495749411714, 50.699125865379216],
            [9.7618419856337848, 50.698832003112322],
            [9.761626454613145, 50.698365903931922],
            [9.7616020073114402, 50.698034100463879],
            [9.7616270887352918, 50.697995041336249],
            [9.7616649455839415, 50.697936086878912],
            [9.761673246230016, 50.697923163483104],
            [9.7617505615995164, 50.697802752961792],
            [9.7615473932080263, 50.6977499417752],
            [9.7613302257221228, 50.697674657912302],
            [9.7611881915968315, 50.697614360241168],
            [9.7613261117915187, 50.69746953361394],
            [9.7614115901058227, 50.697399395526119],
            [9.7615490362074979, 50.697286578257192],
            [9.7615825744324454, 50.697259127970462],
            [9.7617998089183917, 50.697074680464539],
            [9.7619223525562706, 50.697063807283314],
            [9.7624687011212536, 50.696700950676217],
            [9.7625148312491721, 50.69669128709883],
            [9.7635545399628079, 50.697214761348555],
            [9.764267980541506, 50.697579002166961],
            [9.7643541043441751, 50.697574966152601],
            [9.7645576349359473, 50.697565429727405],
            [9.7645996418905767, 50.697563409547335],
            [9.7646494290548294, 50.697560924635297],
            [9.7650601007910502, 50.697320802627438],
            [9.7656355009548985, 50.696984252220538],
            [9.7656979008312792, 50.696947689997309],
            [9.765519299370597, 50.696540071362463],
            [9.7655803786400543, 50.69631805258259],
            [9.765585459045516, 50.696299619276232],
            [9.7655938383289822, 50.696275084949171],
            [9.7656221803053125, 50.696207828054888],
            [9.7657007860489795, 50.696028321517957],
            [9.7654415594265753, 50.695822066831496],
            [9.7654237856635717, 50.695807893454592],
            [9.7655194622725592, 50.695775078783683],
            [9.7651634954424882, 50.695535248855421],
            [9.7649048648087469, 50.695258249097499],
            [9.7657461644678616, 50.695065623653676],
            [9.7662364931918439, 50.694952298803337],
            [9.7674284238137474, 50.694624356491865],
            [9.7680824415181089, 50.694581819225263],
            [9.7680894094937649, 50.694522985207378],
            [9.7685760480315658, 50.694404602668435],
            [9.7687718660625276, 50.694403122579743],
            [9.7698400054355954, 50.694401262466855],
            [9.7699004479972356, 50.694401223010864],
            [9.7702982091406074, 50.694400519822274],
            [9.7710526610140445, 50.694399345065989],
            [9.7715648263162471, 50.694893294522423],
            [9.7717371416455752, 50.69505223164095],
            [9.771784526442282, 50.695095939316282],
            [9.7718712986052854, 50.695207635063582],
            [9.7719750093359838, 50.695341143831257],
            [9.7721954676471103, 50.695780293209715],
            [9.7725855767389707, 50.696650698667625],
            [9.7726755163360757, 50.696625686207625],
            [9.7727809262572762, 50.696735602985967],
            [9.7729776008261755, 50.696940700377255],
            [9.773296328830348, 50.69727305901732],
            [9.7734659233106438, 50.697449907925673],
            [9.7738465644881547, 50.697846811613445],
            [9.7739106033079537, 50.697913592147479],
            [9.774073731118758, 50.698083540397207],
            [9.7741852546182706, 50.698199728473966],
            [9.7745598943401397, 50.69859001474935],
            [9.7746916048811716, 50.698727238030621],
            [9.7747811314915491, 50.698820504333888],
            [9.7748278830959041, 50.698869206153262],
            [9.7749774888830068, 50.69902505184362],
            [9.7752095671275789, 50.69926697973996],
            [9.7752903804107731, 50.699351238517316],
            [9.7755253723590609, 50.699596204064186],
            [9.7759014652126588, 50.699988238969908],
            [9.7759710568857194, 50.700060790961935],
            [9.776046814680452, 50.700142294991231],
            [9.776070011341579, 50.70007892787725],
            [9.7773365483358869, 50.69955087541706],
            [9.7783544518182559, 50.699126478516895],
            [9.7786709723975456, 50.69898201246658],
            [9.7788601758493705, 50.698890376545776],
            [9.779070876335858, 50.698779261420917],
            [9.7793853170184608, 50.698596721403348],
            [9.7794017128064361, 50.698588095289246],
            [9.779545455618079, 50.698512419220485],
            [9.7799282390956144, 50.698310912820325],
            [9.7800470221379232, 50.698248380203417],
            [9.7804141920425334, 50.698055088470504],
            [9.780625399044439, 50.697935693430985],
            [9.7809736151865323, 50.697744757132881],
            [9.7810581541205668, 50.697704189286107],
            [9.7811774328414831, 50.697646958131571],
            [9.7812847567654124, 50.697595463657258],
            [9.7819777008769293, 50.697312857649592],
            [9.7826741593225659, 50.697081961398659],
            [9.7827010524664271, 50.697072014289994],
            [9.7834545102494168, 50.69701964005818],
            [9.7837598021175953, 50.696998423503338],
            [9.7838010148934398, 50.696995556385218],
            [9.7838502184479452, 50.696992131906221],
            [9.7840266244298633, 50.696979866054058],
            [9.7841125563287807, 50.697018830891231],
            [9.7842263837752252, 50.697338058921247],
            [9.7843065819719666, 50.697562950280428],
            [9.7843758228281956, 50.697552789807382],
            [9.7843969538524274, 50.697549688881821],
            [9.7845610952678985, 50.697541407771745],
            [9.7850427670358169, 50.697517112101245],
            [9.7853690685650463, 50.697500642116403],
            [9.7858488085023065, 50.697635318785352],
            [9.7859676131267932, 50.697668682754291],
            [9.7864723645645704, 50.69771374250211],
            [9.7864896825766614, 50.697474201420007],
            [9.7866954600517886, 50.697446058331032],
            [9.7868098199069653, 50.697430411869256],
            [9.7879805539867739, 50.697270322270171],
            [9.7888224051639074, 50.698397035389959],
            [9.7889218429736076, 50.698427856439473],
            [9.7890762672998513, 50.698406576705793],
            [9.7892350850367471, 50.698384691462046],
            [9.7892036094843196, 50.698439636776769],
            [9.7897875193928705, 50.698424440833627],
            [9.7898800057580111, 50.698465398460961],
            [9.7901261028626188, 50.698950699979505],
            [9.7901863535050442, 50.699069495594415],
            [9.7902420795728577, 50.699179400658529],
            [9.7902959016710529, 50.699285532492553],
            [9.7904712240830598, 50.69963123596186],
            [9.7906259716277386, 50.699936366780314],
            [9.7906647690282718, 50.700012887019547],
            [9.7908455161180523, 50.70036928191049],
            [9.7909795588158435, 50.70063358897216],
            [9.7910815270358551, 50.700834640004139],
            [9.7910898609604402, 50.700851076854804],
            [9.7911865862816541, 50.701041767307252],
            [9.7913474952590906, 50.701359049985399],
            [9.7915416695453565, 50.701741900314666],
            [9.7923713087199289, 50.702802121074662],
            [9.7924184073941696, 50.702949072432567],
            [9.792617463144035, 50.702896485350813],
            [9.7932392274172706, 50.702740506201195],
            [9.7942401108291772, 50.702470633417832],
            [9.7941618931826255, 50.702567527688593],
            [9.7941328234188081, 50.702743766255637],
            [9.7940919444926742, 50.703051726693836],
            [9.794018286539135, 50.703606501979095],
            [9.7939541883888523, 50.704089302999094],
            [9.7939430942377861, 50.704172825795276],
            [9.7939206054639154, 50.704342211640274],
            [9.7938588542096259, 50.704807280099295],
            [9.7938144666277402, 50.70514165005185],
            [9.7937746406080652, 50.705404007923356],
            [9.793750490157036, 50.705563116875162],
            [9.7937457685836051, 50.705605560606273],
            [9.7937435052871376, 50.70562584652275],
            [9.7937350533462588, 50.705860867472943],
            [9.793730689116769, 50.706009193830724],
            [9.7937277727081558, 50.706108227999614],
            [9.7937258677261525, 50.706264649406286],
            [9.7937224113006636, 50.706658823620096],
            [9.7937219361474082, 50.706713720887834],
            [9.7936764521575448, 50.707319672492503],
            [9.7936623592513872, 50.707507499888067],
            [9.793655582765048, 50.707597693088566],
            [9.7936495774606751, 50.707667295778535],
            [9.7936317007137426, 50.70787428628325],
            [9.793598012622887, 50.708264664242634],
            [9.7935425021448381, 50.708907753526333],
            [9.7935228377125423, 50.709135584204851],
            [9.7935063583471127, 50.709326413480696],
            [9.7934996185208902, 50.709404546619169],
            [9.7934889557352385, 50.709880373228643],
            [9.7934790225823356, 50.710323082168408],
            [9.7935392719875942, 50.71031961442975],
            [9.7935379519487729, 50.710342097219112],
            [9.7935499959025112, 50.710703124929147],
            [9.7935878521655848, 50.711837088231896],
            [9.7938083330837848, 50.711897801603655],
            [9.7942747702151891, 50.712160434328801],
            [9.7946622717755556, 50.712420293783005],
            [9.7949755342805869, 50.712675433192445],
            [9.7949920365421086, 50.712688891277629],
            [9.7955305432193889, 50.713018556822199],
            [9.7956224734609219, 50.713056456003038],
            [9.7958465573495896, 50.713157133323733],
            [9.7961104732730853, 50.713284625491532],
            [9.796050180280103, 50.71331165680963],
            [9.7966975065605748, 50.713620060596483],
            [9.7974116956084618, 50.714037683525262],
            [9.7985890949136998, 50.714726087390204],
            [9.798757927771943, 50.71487126677065],
            [9.7988791822055763, 50.714998965564995],
            [9.7988977771924084, 50.715026258152413],
            [9.799160147180709, 50.715259144314288],
            [9.7983553887434969, 50.715669860521231],
            [9.7985519058700223, 50.715802709905141],
            [9.7986644955104296, 50.715878874766801],
            [9.7989320708069307, 50.716042784557516],
            [9.7989888705213382, 50.716077621341007],
            [9.7995593077521725, 50.716448408734237],
            [9.7998592784021721, 50.716644352518792],
            [9.8006916890127123, 50.717139699020464],
            [9.8007873444972962, 50.717276609684312],
            [9.8008678867053618, 50.717391959678331],
            [9.8009906949066732, 50.717567704763084],
            [9.8011037732388502, 50.717730215759772],
            [9.8012906050442954, 50.717998572307856],
            [9.8010766026457237, 50.719290653042037],
            [9.80096890137429, 50.719942413075223],
            [9.8008992182353865, 50.720367168580957],
            [9.8008653293859425, 50.72056399068024],
            [9.8008257601389719, 50.720794099292789],
            [9.800699493147814, 50.721250476304228],
            [9.8006546026103223, 50.721476826282199],
            [9.8006088968906013, 50.721665338845924],
            [9.8003780471330497, 50.721943893423635],
            [9.8004649058766731, 50.722192585978767],
            [9.8001410942604856, 50.722604597381775],
            [9.7997957303779835, 50.722673012323874],
            [9.799747737755478, 50.722682505499243],
            [9.798931416493069, 50.722920267926703],
            [9.7984818196636514, 50.722999501392401],
            [9.7980522401084542, 50.72299201952017],
            [9.7979046302163457, 50.722989450517062],
            [9.7970006163085266, 50.722973546510737],
            [9.796723223339125, 50.722968652557782],
            [9.7963367716598828, 50.722983028652962],
            [9.7957245756212661, 50.723005599223711],
            [9.7953458413772356, 50.723025647968043],
            [9.795136168731144, 50.723036709667504],
            [9.7950205150536558, 50.723038856289342],
            [9.7945532016395553, 50.723047734449253],
            [9.7943725709474609, 50.723051213524442],
            [9.793923542606521, 50.723035503348378],
            [9.7931305609521502, 50.722997940247552],
            [9.7924761081781959, 50.722959547185994],
            [9.7917465344584098, 50.722916516541304],
            [9.7917035613541934, 50.722919650166268],
            [9.7908147978527591, 50.72285677657441],
            [9.790758219515185, 50.722852897581262],
            [9.7900358480179328, 50.723103171250393],
            [9.7898778681246963, 50.723157921405281],
            [9.789664115478649, 50.723231475949298],
            [9.7890145238821233, 50.72342266027993],
            [9.7887478158804502, 50.723513388570822],
            [9.7885858735596187, 50.723566886750177],
            [9.78862234942636, 50.72361383581277],
            [9.7885604071246135, 50.723635649337169],
            [9.7883322393947054, 50.723727626846141],
            [9.7882810306065959, 50.723748288476671],
            [9.7881977787611927, 50.723781846969857],
            [9.787817983119643, 50.723901369031509],
            [9.7877258567261709, 50.723930400670369],
            [9.7876113380091851, 50.723975042631842],
            [9.7874803437717866, 50.724026540577903],
            [9.7872375658213979, 50.724116015600806],
            [9.7870050323568307, 50.724217462720489],
            [9.7871284326348338, 50.724312181353753],
            [9.786730064388518, 50.724489722768745],
            [9.7862498826883204, 50.724703319273367],
            [9.7857220835222201, 50.72493161463531],
            [9.7852445962099903, 50.725138273117764],
            [9.7847289840563985, 50.72534185878164],
            [9.7845812612800902, 50.725897052195585],
            [9.7844234027280912, 50.72631297460542],
            [9.7842644373798571, 50.72654480666047],
            [9.7841558050061064, 50.72673809734578],
            [9.7840551098926891, 50.726965571240584],
            [9.7839144085362264, 50.72696166102493],
            [9.7836944980507106, 50.726955603117702],
            [9.782680223496806, 50.727245725273647],
          ],
        ],
      },
      properties: {
        FID: 11,
        OBJECTID: 32,
        gemarkungs: "Roßbach",
        FIRST_geme: "Hünfeld",
        shape_Leng: 13996.6733267,
        shape_Area: 7967773.2153200004,
      },
    },
    {
      type: "Feature",
      id: 12,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.7229767825566782, 50.658189738870036],
            [9.7229799727161854, 50.658240872228795],
            [9.7230195389390506, 50.658884636606835],
            [9.7230479588507741, 50.659420677947821],
            [9.7230938994844234, 50.660151411517049],
            [9.7231420941643609, 50.660491719672486],
            [9.7231470163547602, 50.660527553877692],
            [9.7231646298131924, 50.660652755362804],
            [9.7231706376309148, 50.660694788114199],
            [9.7231775451237326, 50.660748416466397],
            [9.7232642322303029, 50.661422617738843],
            [9.723269540115659, 50.661464205165501],
            [9.7232747813735791, 50.661502375602524],
            [9.723333717779056, 50.661933861586832],
            [9.7233654255693729, 50.662165958214409],
            [9.7233828562291471, 50.66230680887341],
            [9.7234269685380479, 50.66266475974038],
            [9.723432947634393, 50.662713177788916],
            [9.7234385546991522, 50.662757641154407],
            [9.7234533509563033, 50.662873426211306],
            [9.7235202852049767, 50.663428293510101],
            [9.7235348838911886, 50.663494365572319],
            [9.7235930576777108, 50.663756218021405],
            [9.7236831784648246, 50.664107156166757],
            [9.7234641162149398, 50.664144920519213],
            [9.7225053239219381, 50.66434172113528],
            [9.7213596860946314, 50.664575993828905],
            [9.7206486671530605, 50.664698013989721],
            [9.720469168412686, 50.66472416070475],
            [9.7195854997007096, 50.664867170360182],
            [9.718598179086996, 50.664967564339918],
            [9.7183357791022651, 50.664993994050604],
            [9.7179768050573312, 50.665031450205596],
            [9.7171063245677516, 50.665121489013778],
            [9.7156986240949408, 50.66527730597366],
            [9.7151002949871295, 50.665313425977267],
            [9.7145720565778682, 50.665402235760887],
            [9.7135806101673783, 50.665595008147598],
            [9.7125758095890635, 50.665794148933223],
            [9.7117041566160474, 50.665977647322947],
            [9.7125490430029426, 50.661202181469903],
            [9.7125791249997757, 50.66105834078764],
            [9.7125874223893387, 50.661018558268083],
            [9.7126604873403934, 50.66066905991746],
            [9.7126702561100604, 50.660621804067155],
            [9.7128213242017658, 50.659764182502634],
            [9.712975999161003, 50.658862939677419],
            [9.7131244530105949, 50.658036135028873],
            [9.7131700714639457, 50.657794605196834],
            [9.7132423279698852, 50.657411944418108],
            [9.7132791050189216, 50.657217242089629],
            [9.7133348715139913, 50.656921933853525],
            [9.7133363128095116, 50.656917149659279],
            [9.7133619164601495, 50.656831962534746],
            [9.7128718236473599, 50.656006582688093],
            [9.7122139620554506, 50.654895550821422],
            [9.7116732306928473, 50.654237316523357],
            [9.7114529562961263, 50.653968614522441],
            [9.7111371884677382, 50.653585076484127],
            [9.7110870489290591, 50.653522906866549],
            [9.7105801502695233, 50.652907259471306],
            [9.7104388625898199, 50.652735883626534],
            [9.7101076670233635, 50.652332840675712],
            [9.7100296348213515, 50.6522378535917],
            [9.7098255307242081, 50.651990534630819],
            [9.7095311438623781, 50.651632906164195],
            [9.7094965826515338, 50.651517185569844],
            [9.7094574050375613, 50.651385161461739],
            [9.7094627184276057, 50.651323759688175],
            [9.7094647351297976, 50.651298935240625],
            [9.7094740646499247, 50.651189557405289],
            [9.7095389131203707, 50.65106405852579],
            [9.709612090835229, 50.650922456069949],
            [9.7096256950966779, 50.650719397250228],
            [9.7098114518292196, 50.650444261870106],
            [9.7098973538865536, 50.650200041510104],
            [9.7098564124872766, 50.650123687023914],
            [9.7097766906521681, 50.649974490169988],
            [9.7096150896546121, 50.649671756728829],
            [9.7093268085430662, 50.649269511273197],
            [9.7093657220277283, 50.649237609426244],
            [9.7091876872848584, 50.648952557062962],
            [9.7089350404375363, 50.648548124234686],
            [9.7088529757579529, 50.648393257283331],
            [9.7087845563044883, 50.648140290614293],
            [9.7087454915963516, 50.647995702041626],
            [9.708433786487225, 50.646851056787916],
            [9.7080936920673846, 50.64609387443447],
            [9.7083320469494279, 50.645029845257952],
            [9.7089111269546144, 50.644257622404979],
            [9.7090458483356841, 50.643524865308635],
            [9.7084553569682139, 50.642905381781119],
            [9.7078666864445822, 50.642396644271365],
            [9.7069229308072273, 50.64191710995356],
            [9.7064138327360165, 50.641284189994892],
            [9.7060785647389167, 50.64063655395065],
            [9.7062058445727093, 50.63970599463309],
            [9.7066519774674429, 50.639099918754667],
            [9.707611115889403, 50.638558508649076],
            [9.7081111837111091, 50.638515172374071],
            [9.7089212580158346, 50.638604738336085],
            [9.7104721824060203, 50.63872323865538],
            [9.7114681587396205, 50.638788369926225],
            [9.712377615783307, 50.638906694797775],
            [9.7137541450498226, 50.639148354077108],
            [9.7147260665618749, 50.639302142871358],
            [9.7154790029469051, 50.639566679570443],
            [9.7163402412991609, 50.639822352878831],
            [9.7174296879076216, 50.640116985478315],
            [9.7183131314825584, 50.640705390616411],
            [9.7191544971006163, 50.641267087138729],
            [9.7202644658771291, 50.641966449185496],
            [9.7214357305184986, 50.64267657273156],
            [9.7218648929565781, 50.643216968910984],
            [9.7222699125272651, 50.643686592815214],
            [9.7230694740580166, 50.64434243597961],
            [9.7232323324948453, 50.64478346762985],
            [9.7235845338558509, 50.64505482707191],
            [9.7240774561461478, 50.645518502846414],
            [9.7243232112708551, 50.645749719307148],
            [9.7240814960468551, 50.64515557555638],
            [9.7241518142442658, 50.6445341408076],
            [9.7242372755685942, 50.64382972158144],
            [9.7241456929373165, 50.643114450585514],
            [9.7241104129430571, 50.642940993270557],
            [9.7240059069923106, 50.642427258916129],
            [9.7239966074156694, 50.642330118251373],
            [9.7239448202012877, 50.641790992202509],
            [9.7238966579687194, 50.641655278321402],
            [9.7250236569687356, 50.641276978620539],
            [9.7251834364521645, 50.641222501839415],
            [9.7260282987814382, 50.641295697614012],
            [9.7264780208963888, 50.641504855395802],
            [9.7265083510461654, 50.641519145348845],
            [9.7268069827062202, 50.641658134551911],
            [9.7270185784545937, 50.641756603104881],
            [9.7272810787407256, 50.641624410834325],
            [9.7275365876912456, 50.641497495657433],
            [9.7278524048194193, 50.641340705547606],
            [9.7282520006831064, 50.641028357280703],
            [9.7292444174788564, 50.640370728465342],
            [9.7296379876625245, 50.640062172225498],
            [9.7301163582709407, 50.639598121485754],
            [9.7306149319865494, 50.639251051527474],
            [9.7311381161683705, 50.638941137781408],
            [9.7315753049570972, 50.638673642933981],
            [9.7321803740673278, 50.638322030448471],
            [9.7329049541614268, 50.638083741953132],
            [9.7335848396298932, 50.637888160252864],
            [9.7343879039019949, 50.637579877863345],
            [9.7364140145483002, 50.636801409165827],
            [9.7376586254304573, 50.636220030847348],
            [9.7377641468092033, 50.636170367786129],
            [9.7377524102120852, 50.636161367947523],
            [9.7370790081651535, 50.635648547010966],
            [9.7370309611526515, 50.63561353459388],
            [9.7367095892683437, 50.635211573921602],
            [9.7365362248701004, 50.634846151594594],
            [9.736517623529819, 50.634786931967959],
            [9.7363614945666388, 50.634294479225332],
            [9.7363333715501845, 50.634204131279105],
            [9.7366579439875967, 50.634196315112732],
            [9.7368938667938068, 50.63416119773423],
            [9.7371028302340434, 50.634109343183681],
            [9.7371689725461739, 50.634063697892238],
            [9.7372080710969424, 50.633969687299874],
            [9.7372205969762007, 50.633840249683807],
            [9.7371917614749215, 50.633668275051569],
            [9.7371367559914077, 50.633615868854569],
            [9.7370379277866643, 50.63352174153016],
            [9.7369438234404662, 50.633433079098133],
            [9.7368828027359751, 50.633373947927552],
            [9.7369060127352842, 50.633213335241692],
            [9.737736381590917, 50.633252983942924],
            [9.7382012360194246, 50.633356438561442],
            [9.7383767157233923, 50.63316231434851],
            [9.739253981450263, 50.633376438162344],
            [9.7381857320768486, 50.632399928577243],
            [9.7380359828754823, 50.632270358651319],
            [9.7379606531824425, 50.632189582064655],
            [9.7368642739907596, 50.631400072497335],
            [9.7370425605460404, 50.631277050830164],
            [9.7374879530196807, 50.631566745999756],
            [9.7379286991492471, 50.631856450903058],
            [9.7392994340738053, 50.6324545880953],
            [9.7407398754668399, 50.633026815259285],
            [9.7409100009274958, 50.632738391396025],
            [9.7415571317480385, 50.632976291159096],
            [9.7415649263534903, 50.63296841745511],
            [9.7417551919898724, 50.632836966653571],
            [9.741768340986944, 50.632827898725296],
            [9.7417935769530395, 50.632806073413605],
            [9.7421204241030281, 50.632523997383885],
            [9.7426984451172398, 50.632136672875461],
            [9.7433289287314651, 50.63176006344915],
            [9.743894726021221, 50.631386948235715],
            [9.7439929360022894, 50.631322181553863],
            [9.7446540990533848, 50.630865248366938],
            [9.745085187169348, 50.630580941800041],
            [9.7458047171152877, 50.630321029944909],
            [9.746058798462224, 50.630177013680857],
            [9.7461878063462741, 50.630103819124969],
            [9.7464868303279495, 50.629771178034339],
            [9.7468592228864512, 50.629448668900523],
            [9.7475456448338385, 50.629403006112305],
            [9.7484310041966218, 50.629344109609811],
            [9.7494112853026795, 50.629342665147853],
            [9.749721023940598, 50.629366294269261],
            [9.7498558763688585, 50.629376578093286],
            [9.7499173009520614, 50.629381299919601],
            [9.7502583259140785, 50.62940734327583],
            [9.7507882073101424, 50.629452925240457],
            [9.7510167820837843, 50.629472577263797],
            [9.7516125805124769, 50.629407248825835],
            [9.7517121864740002, 50.629396362977246],
            [9.75237443089698, 50.629384714179565],
            [9.7531180600637803, 50.629282828183484],
            [9.753354287131998, 50.629250463301545],
            [9.7538282481359175, 50.629193375584705],
            [9.754423666900621, 50.629121569079167],
            [9.7545433067836438, 50.629134545018275],
            [9.7550566934400678, 50.629190106939028],
            [9.7552054759224429, 50.629223487833798],
            [9.755513412260246, 50.629302142796654],
            [9.7558403496810762, 50.629366509357475],
            [9.756287780414187, 50.62945460850564],
            [9.7565264206770888, 50.629599118296682],
            [9.7565702397316336, 50.629625660574419],
            [9.7561478354293261, 50.630252550426171],
            [9.7561145674491847, 50.630302031203925],
            [9.7561076614970901, 50.630312238362812],
            [9.7560797523492582, 50.630997289510034],
            [9.7559705771902774, 50.631612441915912],
            [9.7558615678979397, 50.63258639565381],
            [9.7556562161960976, 50.633011766964493],
            [9.7555741441150712, 50.63318184838198],
            [9.7552469305934917, 50.633859900154434],
            [9.7550708668647061, 50.634479432930995],
            [9.755327480794616, 50.635020564447181],
            [9.755445552635182, 50.635282347010929],
            [9.7554784803879002, 50.635349879433747],
            [9.7555653917089025, 50.635528146033238],
            [9.7558128196409015, 50.635473733202979],
            [9.7562978766610016, 50.635329815073504],
            [9.7563580978696418, 50.635311896405256],
            [9.756446158281916, 50.635285793016791],
            [9.7571237756755451, 50.635214599414013],
            [9.7571963095691068, 50.635207023446647],
            [9.757785654013226, 50.635184846049121],
            [9.7582563435772602, 50.635167143071499],
            [9.7584583608857329, 50.635142634775917],
            [9.7591378958620432, 50.635059995576448],
            [9.7592782662277866, 50.635042992112481],
            [9.7598799954926747, 50.635043647128803],
            [9.7604326027473025, 50.635039232683894],
            [9.7605429821620611, 50.635047775301928],
            [9.7612984518033201, 50.635106307027662],
            [9.7614173423270252, 50.635115602599939],
            [9.7620805703495233, 50.635146826015351],
            [9.7624027215669038, 50.635161383064009],
            [9.7621467557933528, 50.636261237592727],
            [9.7622706486579247, 50.637305250246271],
            [9.7624107170967171, 50.63783478077611],
            [9.7625523066709334, 50.638370722298227],
            [9.7625968767384013, 50.638539080388753],
            [9.7627754463849641, 50.638767148660605],
            [9.7631126608956826, 50.639197721240848],
            [9.7625783433293325, 50.639816229138191],
            [9.761899898196658, 50.640601637685251],
            [9.7620252718770555, 50.640690012547466],
            [9.7621852925057055, 50.640802703133517],
            [9.7622100414246695, 50.640820158953396],
            [9.7622914262824168, 50.640877524877574],
            [9.7623659439018411, 50.640930007393273],
            [9.7624389226752637, 50.640906606216397],
            [9.7625923780270707, 50.640857335482664],
            [9.7642426959629205, 50.64200554166419],
            [9.7643361763910868, 50.642017501046773],
            [9.7643073056700498, 50.64205031778291],
            [9.7642792674939329, 50.642082148791189],
            [9.7642726019998509, 50.642089737812299],
            [9.7640372877517692, 50.642314474432347],
            [9.7638304417685262, 50.642465090781911],
            [9.7638117106558475, 50.642478694382888],
            [9.7635929670621948, 50.642637922844685],
            [9.7634838593722666, 50.642702885250209],
            [9.7629108937363398, 50.643044350852151],
            [9.7624606528184259, 50.643286903755403],
            [9.7623231481269865, 50.643360963130817],
            [9.7620112696037769, 50.643467342198342],
            [9.7617065044378286, 50.643523896525849],
            [9.7616150305988505, 50.643540844071978],
            [9.761351643470471, 50.643576991229835],
            [9.7613210351738484, 50.643581238150979],
            [9.7610452428852454, 50.643619075445294],
            [9.7610194326013193, 50.643622661426882],
            [9.7606345622742268, 50.643733848187125],
            [9.7601659922035413, 50.643802259431709],
            [9.760121412656126, 50.643763717567985],
            [9.7599329503990813, 50.643736447287182],
            [9.759974049721194, 50.643857362710328],
            [9.7598807733005621, 50.643903809370329],
            [9.7597262546683599, 50.644061595435197],
            [9.7597096981349711, 50.644078601609962],
            [9.7598117172675209, 50.644110303002414],
            [9.7599531099757879, 50.644154149166141],
            [9.7598062281565454, 50.644356653701848],
            [9.7597131524394296, 50.644484910100886],
            [9.7596841951073241, 50.644582819050797],
            [9.7596613925389928, 50.644659823633347],
            [9.7596282012612505, 50.645099546903587],
            [9.7595838718016665, 50.645523964355],
            [9.7595259672068142, 50.645729296886515],
            [9.7594942883013847, 50.645781286223375],
            [9.7593827218817459, 50.645964773179124],
            [9.7593265447075819, 50.645961452035309],
            [9.7577010121520296, 50.645479654983752],
            [9.7576806175918556, 50.645513134386881],
            [9.757092382300339, 50.646482818484508],
            [9.7570202478359782, 50.646497847104804],
            [9.7555840520568271, 50.646797333783198],
            [9.7554975373436541, 50.646816231841704],
            [9.755252918193408, 50.646868710015809],
            [9.7551905325017465, 50.646889313053052],
            [9.7551283256774628, 50.64691137179598],
            [9.7542085923374113, 50.647217224264807],
            [9.7539085880484766, 50.647317047513823],
            [9.7521286114295727, 50.647909177336878],
            [9.7520751285973208, 50.647929433452994],
            [9.7520615606669114, 50.647934548213549],
            [9.7517262393549409, 50.648061672245554],
            [9.7514360180291852, 50.648171588295639],
            [9.7509744867330586, 50.648346180619498],
            [9.7509381878570789, 50.648360165131187],
            [9.7508849543955964, 50.648409440132831],
            [9.7508705911337863, 50.648479149009198],
            [9.7508103244012094, 50.648562841286193],
            [9.7510124551870252, 50.648745674479613],
            [9.7511764251238731, 50.648783962870638],
            [9.7513574030467201, 50.648731256140771],
            [9.7514787314081275, 50.648743262127894],
            [9.7515813530866104, 50.648854997270405],
            [9.7516382562195218, 50.649081816355007],
            [9.7515808211230297, 50.649119535533714],
            [9.751418822263112, 50.649150815349223],
            [9.7513129095122739, 50.649249578015649],
            [9.75127123158172, 50.649405258105389],
            [9.7513762555099195, 50.649503479146674],
            [9.7514219821904078, 50.649632659321249],
            [9.7513952046028702, 50.649682267569553],
            [9.7512728381664466, 50.649772062145317],
            [9.7512194225452422, 50.649839261894577],
            [9.7512515893881666, 50.649950165437879],
            [9.7513202605915712, 50.650030616495968],
            [9.7508676292113954, 50.649850693206687],
            [9.7505815683944803, 50.64985856147625],
            [9.7503505550013347, 50.649895374435943],
            [9.7502748570913695, 50.649901167805027],
            [9.7501442880065383, 50.649950373757811],
            [9.7501535795786367, 50.649983121114616],
            [9.7501261251564486, 50.649991211850363],
            [9.7501101137451602, 50.650033025342559],
            [9.7499176067968225, 50.65014384636725],
            [9.7500256176089835, 50.650383530292949],
            [9.7501606104482956, 50.650852717550727],
            [9.7496880000287476, 50.650816638499862],
            [9.7479968907658314, 50.650419421356261],
            [9.7478514146005359, 50.65038525460092],
            [9.7461514338433748, 50.649985918269429],
            [9.7460777325344434, 50.65021961956289],
            [9.7457807831519201, 50.651423280283012],
            [9.7453126270785067, 50.652426929399518],
            [9.7451564473005199, 50.652761728268224],
            [9.7451257378511791, 50.65277793249804],
            [9.744955195751368, 50.65286757871182],
            [9.7446713511570362, 50.653016836114624],
            [9.7444227056595025, 50.653147620802933],
            [9.7441821961401978, 50.653274135196526],
            [9.7439538307312343, 50.65331965088145],
            [9.7433203623452158, 50.653450595946232],
            [9.7447608955794891, 50.655509129607324],
            [9.7445709163245944, 50.655643334722448],
            [9.7444031085592044, 50.655780491557223],
            [9.7446336387195469, 50.655756634771556],
            [9.7445091445596876, 50.655851453882761],
            [9.7443899626966086, 50.655942281926599],
            [9.7442874007318867, 50.656020422242499],
            [9.7443065284506964, 50.656041065336318],
            [9.7440623042482368, 50.656192190470577],
            [9.7444224030446343, 50.656339726207491],
            [9.7437452224473891, 50.656450034507529],
            [9.7430800237587611, 50.65658559625377],
            [9.7423094511097776, 50.656888918698989],
            [9.7422691060084574, 50.656913349338147],
            [9.74222443385041, 50.656940604400212],
            [9.742148650720365, 50.656986655463413],
            [9.7416699340707211, 50.657190682587157],
            [9.7408624039843925, 50.657537208982291],
            [9.7405585081902135, 50.65768071108414],
            [9.7395652298464128, 50.658149681595305],
            [9.7393567818012166, 50.658248175477141],
            [9.7392937305275105, 50.658286355799412],
            [9.7392363352214417, 50.658318034117336],
            [9.7390947355188278, 50.658356226914869],
            [9.7390308083999724, 50.658371075356982],
            [9.7389434104990418, 50.658391360572402],
            [9.7388741261191285, 50.658401107793118],
            [9.7382692271845634, 50.658486176637908],
            [9.7380951964604332, 50.658510643228624],
            [9.7380141244106255, 50.658522056383518],
            [9.7377308864356795, 50.658561881814443],
            [9.7375916954529593, 50.658581459646761],
            [9.7373435814049891, 50.658472818336762],
            [9.7371001180978194, 50.658479547504285],
            [9.7369071972420045, 50.658484886400103],
            [9.7368607417620545, 50.658486169383707],
            [9.7366229525204009, 50.658483913469603],
            [9.736454648132705, 50.658501875321605],
            [9.7355778402479363, 50.658705847164576],
            [9.7343681223476768, 50.658774548906401],
            [9.7345228834323869, 50.658967565343609],
            [9.7344384397268264, 50.658957935245688],
            [9.7343489039276552, 50.658974687169625],
            [9.7340669333860337, 50.659004522265171],
            [9.7339373498349797, 50.659011633625141],
            [9.7338008807306871, 50.659011773520916],
            [9.7336534348378247, 50.659006595447785],
            [9.7330855271094858, 50.658946983113239],
            [9.7320835260645389, 50.658836802131148],
            [9.7314183846241633, 50.658751730219507],
            [9.730755640351715, 50.6586669002861],
            [9.730140858172831, 50.658599311790468],
            [9.7296060931883073, 50.658540554049289],
            [9.7294963172499394, 50.658528488928404],
            [9.7294750599685251, 50.658526193830532],
            [9.7287034129374348, 50.658441377343557],
            [9.7282766721667731, 50.658440402619313],
            [9.7280234308618283, 50.658444332082048],
            [9.7278258285069725, 50.65843059268397],
            [9.7277685956879658, 50.658426606398145],
            [9.7266691232522344, 50.658350154352831],
            [9.7263175988491355, 50.65832571689311],
            [9.725715801530729, 50.65828386152441],
            [9.7255760083614842, 50.65827380524307],
            [9.7248382781256559, 50.658220731728079],
            [9.7245182275731956, 50.658197693870306],
            [9.724447353954913, 50.658192594664229],
            [9.724400784693529, 50.658189241905262],
            [9.724386049155628, 50.658272430390454],
            [9.7230480712937659, 50.658172128641013],
            [9.7229765790136629, 50.658167266201517],
            [9.7229767825566782, 50.658189738870036],
          ],
        ],
      },
      properties: {
        FID: 12,
        OBJECTID: 33,
        gemarkungs: "Rückers",
        FIRST_geme: "Hünfeld",
        shape_Leng: 17308.753419799999,
        shape_Area: 8316482.16658,
      },
    },
    {
      type: "Feature",
      id: 13,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.6893120842509735, 50.678872907758198],
            [9.6888753931151559, 50.67913198237509],
            [9.6888342949559636, 50.679156497704668],
            [9.6888098666448101, 50.679171103011107],
            [9.6887853606413046, 50.679186203393321],
            [9.6885065626290601, 50.679352136957654],
            [9.6878769753691074, 50.679486668145465],
            [9.6864320059677329, 50.679435787077423],
            [9.6856646738743368, 50.679406849022577],
            [9.6848880736402201, 50.67921563450394],
            [9.683685983466285, 50.678874387955616],
            [9.6820765028064706, 50.67863435710268],
            [9.6819331778800244, 50.67862241648907],
            [9.6818714492717461, 50.678617273826234],
            [9.680972398248425, 50.678541978403103],
            [9.6801625646288887, 50.67868331239837],
            [9.6788678774390551, 50.678804524201439],
            [9.6787444260949353, 50.678327546490095],
            [9.6787331815557938, 50.677727527863283],
            [9.6783754485809421, 50.677080387843908],
            [9.6774045779483568, 50.677392609910164],
            [9.6762325349651377, 50.67757811794484],
            [9.6753798727446583, 50.677717311006887],
            [9.6749238281227257, 50.677778290971354],
            [9.6743705019235495, 50.6776840172029],
            [9.6734719597534564, 50.677396908980299],
            [9.6725344563627988, 50.677234024805173],
            [9.6720801104172569, 50.677721086923171],
            [9.6715384934197459, 50.677769480009601],
            [9.6710566964529594, 50.677566339344175],
            [9.671295167161766, 50.677407469638304],
            [9.6717230942890424, 50.677059245750328],
            [9.671849650312593, 50.676943026669811],
            [9.6719302982855027, 50.676871821882735],
            [9.6720132889255002, 50.676797186145471],
            [9.6720940619779174, 50.676735765038373],
            [9.6721996372320813, 50.676624226058372],
            [9.6723135710407835, 50.676489517448459],
            [9.6723607396693208, 50.676439711245827],
            [9.672433825125033, 50.676291029003899],
            [9.6724345679237302, 50.676272687740415],
            [9.6724567646226589, 50.676214572024051],
            [9.6725434282966951, 50.676017788088565],
            [9.6726161338202328, 50.67583072525867],
            [9.6726504431363569, 50.675753663033902],
            [9.6726659213581048, 50.675676070964258],
            [9.672665234366038, 50.675618105250095],
            [9.6724032947169043, 50.675091306545767],
            [9.6722521577953682, 50.674773291115997],
            [9.6721458804000005, 50.67454006478399],
            [9.672108336456235, 50.674281612001977],
            [9.6720752709365669, 50.673895718756299],
            [9.6720870893938109, 50.673822464543179],
            [9.6721229915970497, 50.67357751855787],
            [9.6721735186517233, 50.673344880739762],
            [9.6722935360407174, 50.673105564883748],
            [9.6724155385531265, 50.672942876879567],
            [9.672616883970889, 50.672608384812051],
            [9.6727735579253569, 50.672402239374577],
            [9.6729414915272898, 50.672335386243063],
            [9.673485072878675, 50.672133021731547],
            [9.6742021843437662, 50.671856923571909],
            [9.6747309098738636, 50.671676294604438],
            [9.6748822656122258, 50.671621225696988],
            [9.675032919916875, 50.671566610310791],
            [9.6760811076944915, 50.67126310504694],
            [9.6762186457711064, 50.671236406938974],
            [9.6767894075228806, 50.671158594985975],
            [9.6781852534349522, 50.670823976667492],
            [9.678182412403677, 50.670803587778323],
            [9.6782370367637878, 50.670790796448102],
            [9.6782678818522214, 50.670783566335388],
            [9.6784418557722649, 50.670723378960794],
            [9.6788382451207333, 50.670603179949957],
            [9.6790873278624101, 50.670528884562074],
            [9.679188603525601, 50.670486305496247],
            [9.6792290635136933, 50.670458928436105],
            [9.6792395818230794, 50.670387605539723],
            [9.6799111318354054, 50.669586034825656],
            [9.6799413738434961, 50.66958512995938],
            [9.6805367474743615, 50.669568066512049],
            [9.6806527014631509, 50.669556309864099],
            [9.6807246816482788, 50.66954509771984],
            [9.6807872111727082, 50.669527195880974],
            [9.6808515867550824, 50.669518546155153],
            [9.680870351751544, 50.669156606666526],
            [9.680871557302936, 50.669132336139846],
            [9.6808763284445067, 50.669038572797938],
            [9.6808789973054967, 50.668993177835631],
            [9.6809097916931695, 50.668774176910709],
            [9.6810363542639806, 50.668358287208989],
            [9.6812607919639291, 50.667434747104409],
            [9.6812124610708974, 50.667125251893673],
            [9.6811987119456244, 50.667005318794423],
            [9.6811153733732223, 50.666094279045488],
            [9.6809265783019409, 50.665676202866656],
            [9.6808899275467457, 50.665488190515084],
            [9.6809366259155851, 50.665188113279413],
            [9.6810542104558941, 50.665298626444169],
            [9.6811430852126179, 50.665351418419611],
            [9.6812455946260698, 50.665358814183108],
            [9.6820394777298144, 50.665348496417351],
            [9.6821173245043379, 50.665303183033124],
            [9.6821950054686301, 50.664817755095683],
            [9.6823360583620488, 50.664440539297196],
            [9.6824657243814727, 50.664350693991253],
            [9.6843957010270323, 50.664328827704651],
            [9.6844244040607634, 50.66432838935529],
            [9.6854711209941851, 50.664316104485245],
            [9.6858642369095911, 50.664252935096577],
            [9.6860596643729426, 50.664221477966123],
            [9.6861735643800433, 50.664176579138719],
            [9.6862830337790928, 50.664206313434299],
            [9.6874537685864794, 50.664202795160655],
            [9.6876813206212038, 50.664201812449008],
            [9.6875986338205298, 50.664157442490726],
            [9.6873850487660871, 50.663399671727589],
            [9.6873593989345448, 50.663308758385242],
            [9.6871556373755432, 50.662584662473982],
            [9.6869639005022119, 50.661898797079807],
            [9.6870682799559731, 50.661802089965548],
            [9.6883083217895365, 50.661957953685004],
            [9.6894936549982074, 50.66210683444973],
            [9.6895268248816269, 50.662111035859837],
            [9.6895467101543851, 50.662049620891111],
            [9.6918759152221075, 50.662345675804779],
            [9.6919402873195324, 50.662308709499548],
            [9.6918242906483858, 50.661428321003505],
            [9.691728801319675, 50.660702151064662],
            [9.691659985801321, 50.660638753058876],
            [9.6916488851209603, 50.660566909677087],
            [9.6917424562641781, 50.660544321099451],
            [9.6927008787308786, 50.659195635577305],
            [9.6926574359452768, 50.659126772378677],
            [9.6928219034609278, 50.659155436217013],
            [9.6929698217109159, 50.659173775158543],
            [9.6925124378550755, 50.658883685689531],
            [9.6926315939469649, 50.658889623530563],
            [9.6928187046313266, 50.658899554949791],
            [9.69270900064115, 50.658815806281133],
            [9.6927286964544166, 50.657961914764989],
            [9.6927536065039241, 50.656872982864925],
            [9.6927807955644028, 50.656835337749868],
            [9.6935209340570534, 50.655799384039909],
            [9.6935618093039455, 50.655742061186473],
            [9.6936897330319702, 50.655699310732331],
            [9.6937115153861182, 50.655767232333609],
            [9.6963842120939088, 50.657144557982988],
            [9.6964605261155423, 50.657130414357361],
            [9.6969700178602665, 50.656047514512373],
            [9.6972517465877885, 50.65544868322214],
            [9.697221093916621, 50.655386813778513],
            [9.6973776045218969, 50.655470197227281],
            [9.7007122569605038, 50.657180164880124],
            [9.7007418501247837, 50.657234143771596],
            [9.7007698289749911, 50.657285164618493],
            [9.7009647237895802, 50.657194861304717],
            [9.7023025907799365, 50.656530116886699],
            [9.702929453507652, 50.656286543824997],
            [9.7033658759899346, 50.656131044842802],
            [9.7038688180744614, 50.65598711237994],
            [9.7041643931060637, 50.655907697808885],
            [9.7041776091009133, 50.655904092632703],
            [9.7043572231939699, 50.655849470882515],
            [9.7055614385104789, 50.655636638673769],
            [9.7068566698704757, 50.655548615731824],
            [9.7075701419046556, 50.655540203178063],
            [9.7082017148630513, 50.655580550311022],
            [9.7087758556013863, 50.655645551864026],
            [9.709186780227494, 50.655737516203345],
            [9.7097057954754948, 50.655853345264262],
            [9.7106301915345803, 50.656024149176559],
            [9.710808498052236, 50.656127994062601],
            [9.7132324913596015, 50.656886704706906],
            [9.7132583256513332, 50.656894262735634],
            [9.7133363128095116, 50.656917149659279],
            [9.7133348715139913, 50.656921933853525],
            [9.7132791050189216, 50.657217242089629],
            [9.7132423279698852, 50.657411944418108],
            [9.7131700714639457, 50.657794605196834],
            [9.7131244530105949, 50.658036135028873],
            [9.712975999161003, 50.658862939677419],
            [9.7128213242017658, 50.659764182502634],
            [9.7126702561100604, 50.660621804067155],
            [9.7126604873403934, 50.66066905991746],
            [9.7125874223893387, 50.661018558268083],
            [9.7125791249997757, 50.66105834078764],
            [9.7125490430029426, 50.661202181469903],
            [9.7117041566160474, 50.665977647322947],
            [9.7117488586674696, 50.666062953188948],
            [9.7121220371403858, 50.666774729977483],
            [9.7122118635491219, 50.666803750582758],
            [9.71200613530055, 50.666846978614871],
            [9.7111327654410449, 50.667030663006351],
            [9.7109951150531817, 50.667048751672979],
            [9.7108610128498416, 50.667122836876189],
            [9.7109018842978898, 50.667159720306856],
            [9.7106986356369767, 50.667432984696084],
            [9.7105987775493894, 50.667479980242398],
            [9.7105262990604562, 50.66748562915302],
            [9.7097406450528219, 50.667730317398011],
            [9.7085146930951538, 50.668097727297983],
            [9.7065271629740622, 50.668705157028185],
            [9.7060741208129233, 50.668831423353218],
            [9.7058695747590829, 50.668888392861739],
            [9.7044106121134028, 50.669294815656734],
            [9.7038109061677549, 50.668858565862536],
            [9.7037126652110093, 50.668750297030599],
            [9.7033105825762753, 50.66830611539536],
            [9.7032278957063554, 50.668245218117789],
            [9.7029330095037132, 50.668023517294579],
            [9.7029092614665426, 50.667988236514091],
            [9.7028949469669143, 50.667966838214937],
            [9.7026407702269868, 50.66794957515939],
            [9.7025917223499771, 50.668195015032232],
            [9.702469994712974, 50.66848970774695],
            [9.7021648544626515, 50.669023365140966],
            [9.7021372509612611, 50.669070187873082],
            [9.7018533909733158, 50.669551942652554],
            [9.7007995365129389, 50.669570496713128],
            [9.7000108461159726, 50.669847000073979],
            [9.6999277913909676, 50.670239006352631],
            [9.6980654672997328, 50.670571329331182],
            [9.696949516620645, 50.670770254107069],
            [9.6962703246298325, 50.670891350221652],
            [9.6960039642031095, 50.670961172073156],
            [9.6955692435679346, 50.671075156260564],
            [9.6946865774677757, 50.671204301026023],
            [9.6938553519743973, 50.671475809015291],
            [9.6931923415956298, 50.671921416102023],
            [9.6933382959672958, 50.672648155691455],
            [9.6934780525172659, 50.673372719581771],
            [9.6933057048043079, 50.674290109331018],
            [9.6928848666412168, 50.675260165028782],
            [9.6921887176458679, 50.676151221896546],
            [9.6914784940182095, 50.676867369611912],
            [9.69144002900922, 50.676906151344191],
            [9.6907474659336703, 50.677479963384073],
            [9.6904399971528754, 50.677776229095031],
            [9.6904130473422043, 50.677802181020041],
            [9.6899867209494257, 50.678212975485422],
            [9.6893120842509735, 50.678872907758198],
          ],
        ],
      },
      properties: {
        FID: 13,
        OBJECTID: 34,
        gemarkungs: "Rudolphshan",
        FIRST_geme: "Hünfeld",
        shape_Leng: 10856.2807459,
        shape_Area: 3936696.31403,
      },
    },
    {
      type: "Feature",
      id: 14,
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.736749496195527, 50.675373980074319],
            [9.736667942757526, 50.675513835392522],
            [9.7365911242869299, 50.675631618519382],
            [9.7364408903267208, 50.675821531877439],
            [9.7360769837143835, 50.676225743356028],
            [9.7359137436100127, 50.676434956497808],
            [9.735677934944599, 50.676815973876543],
            [9.7354287958424326, 50.677312384967614],
            [9.735363441968433, 50.677321880382245],
            [9.7351089798595325, 50.677088323752919],
            [9.7346936950725063, 50.676760426063126],
            [9.7346419937602473, 50.676720579557859],
            [9.7345414371887458, 50.676640949480756],
            [9.7342006532772309, 50.676354182791016],
            [9.7339367207741851, 50.676167888267756],
            [9.7335301114636614, 50.675930627671555],
            [9.7331028693462596, 50.675613294546622],
            [9.7327591018988269, 50.675431096721326],
            [9.7326996735352811, 50.675385623253817],
            [9.7326505785617492, 50.675348439436185],
            [9.7325233775188043, 50.675375130202987],
            [9.7321832086017857, 50.675260230764728],
            [9.7310028490028841, 50.674823926723043],
            [9.7308797293804741, 50.674765272111927],
            [9.730673379295224, 50.674648350958591],
            [9.7306086000425527, 50.674611723253754],
            [9.7304514956399704, 50.674522632566635],
            [9.7301126321216849, 50.674397187934311],
            [9.7296790549809486, 50.67423663250365],
            [9.7294189457065254, 50.674050366760632],
            [9.7290278713895457, 50.673790060706168],
            [9.7280263257816895, 50.673043882150132],
            [9.7279715916019658, 50.673003125330318],
            [9.7273020642389962, 50.672503841667826],
            [9.7271422705562625, 50.672554013223717],
            [9.7262757125136705, 50.672907055357442],
            [9.72553847798814, 50.673086527675039],
            [9.7255818022636795, 50.673124451876518],
            [9.7258279599551454, 50.673339735017045],
            [9.7257626892467783, 50.673382840929762],
            [9.7253899587091279, 50.673628803607706],
            [9.7256563904200348, 50.674124430181323],
            [9.7258687081186306, 50.674407174635967],
            [9.7259260827019194, 50.674522783928815],
            [9.7260888488416608, 50.674850982165559],
            [9.7258799587525431, 50.675079728907789],
            [9.7258469178700935, 50.675115898260636],
            [9.7258221992963794, 50.675142923709402],
            [9.7256045218044456, 50.675091965915989],
            [9.7254149627328346, 50.675056381865005],
            [9.7244288898807305, 50.675144262464286],
            [9.7237114961497575, 50.675129410794568],
            [9.7235233356763473, 50.675084372184294],
            [9.7230385383711972, 50.675050715523994],
            [9.7227628146690339, 50.675034178526722],
            [9.7226495646022091, 50.675047111412304],
            [9.7221419660947763, 50.675030841240179],
            [9.7219688783306122, 50.675007820876942],
            [9.7214330427378517, 50.674773467918016],
            [9.7211588823504709, 50.674682400264082],
            [9.7208355202460606, 50.67457508887933],
            [9.7204834576854928, 50.674413203752792],
            [9.7204274031893618, 50.674374627911838],
            [9.7200994812554526, 50.674190118542555],
            [9.7196788226721438, 50.673899297377034],
            [9.7195853766496345, 50.67384855045578],
            [9.7195269621386906, 50.673811958249786],
            [9.7194512350461792, 50.673773233587291],
            [9.7190248626285118, 50.673644258979003],
            [9.7185489506007858, 50.673505434970799],
            [9.7180452450431662, 50.673380440836596],
            [9.7178839098143079, 50.673338735196907],
            [9.7176688160341005, 50.673307603550029],
            [9.7165428202261275, 50.673212782858201],
            [9.7156060148327814, 50.673045312369311],
            [9.7145345536608634, 50.672972593333199],
            [9.7143279460114869, 50.672958580406608],
            [9.7142592294468582, 50.672946869780347],
            [9.7131995105238484, 50.672766193761376],
            [9.7120380936290758, 50.672441753516033],
            [9.7120839878936227, 50.67240803662564],
            [9.7119437259403067, 50.671824114267793],
            [9.7118020510376901, 50.671203607210074],
            [9.7116946043336245, 50.670545137692777],
            [9.7116943982664257, 50.670016737609785],
            [9.7116329264673436, 50.669581565822483],
            [9.7114002338203598, 50.669114892986698],
            [9.7110894128161647, 50.668513853074984],
            [9.710884188309775, 50.66788915479021],
            [9.7108329868840269, 50.667749227889956],
            [9.7107763357506958, 50.667551058582973],
            [9.7107521938479309, 50.667466634219309],
            [9.7105987775493894, 50.667479980242398],
            [9.7106986356369767, 50.667432984696084],
            [9.7109018842978898, 50.667159720306856],
            [9.7108610128498416, 50.667122836876189],
            [9.7109951150531817, 50.667048751672979],
            [9.7111327654410449, 50.667030663006351],
            [9.71200613530055, 50.666846978614871],
            [9.7122118635491219, 50.666803750582758],
            [9.7121220371403858, 50.666774729977483],
            [9.7117488586674696, 50.666062953188948],
            [9.7117041566160474, 50.665977647322947],
            [9.7125758095890635, 50.665794148933223],
            [9.7135806101673783, 50.665595008147598],
            [9.7145720565778682, 50.665402235760887],
            [9.7151002949871295, 50.665313425977267],
            [9.7156986240949408, 50.66527730597366],
            [9.7171063245677516, 50.665121489013778],
            [9.7179768050573312, 50.665031450205596],
            [9.7183357791022651, 50.664993994050604],
            [9.718598179086996, 50.664967564339918],
            [9.7195854997007096, 50.664867170360182],
            [9.720469168412686, 50.66472416070475],
            [9.7206486671530605, 50.664698013989721],
            [9.7213596860946314, 50.664575993828905],
            [9.7225053239219381, 50.66434172113528],
            [9.7234641162149398, 50.664144920519213],
            [9.7236831784648246, 50.664107156166757],
            [9.7235930576777108, 50.663756218021405],
            [9.7235348838911886, 50.663494365572319],
            [9.7235202852049767, 50.663428293510101],
            [9.7234533509563033, 50.662873426211306],
            [9.7234385546991522, 50.662757641154407],
            [9.723432947634393, 50.662713177788916],
            [9.7234269685380479, 50.66266475974038],
            [9.7233828562291471, 50.66230680887341],
            [9.7233654255693729, 50.662165958214409],
            [9.723333717779056, 50.661933861586832],
            [9.7232747813735791, 50.661502375602524],
            [9.723269540115659, 50.661464205165501],
            [9.7232642322303029, 50.661422617738843],
            [9.7231775451237326, 50.660748416466397],
            [9.7231706376309148, 50.660694788114199],
            [9.7231646298131924, 50.660652755362804],
            [9.7231470163547602, 50.660527553877692],
            [9.7231420941643609, 50.660491719672486],
            [9.7230938994844234, 50.660151411517049],
            [9.7230479588507741, 50.659420677947821],
            [9.7230195389390506, 50.658884636606835],
            [9.7229799727161854, 50.658240872228795],
            [9.7229767825566782, 50.658189738870036],
            [9.7229765790136629, 50.658167266201517],
            [9.7230480712937659, 50.658172128641013],
            [9.724386049155628, 50.658272430390454],
            [9.724400784693529, 50.658189241905262],
            [9.724447353954913, 50.658192594664229],
            [9.7245182275731956, 50.658197693870306],
            [9.7248382781256559, 50.658220731728079],
            [9.7255760083614842, 50.65827380524307],
            [9.725715801530729, 50.65828386152441],
            [9.7263175988491355, 50.65832571689311],
            [9.7266691232522344, 50.658350154352831],
            [9.7277685956879658, 50.658426606398145],
            [9.7278258285069725, 50.65843059268397],
            [9.7280234308618283, 50.658444332082048],
            [9.7282766721667731, 50.658440402619313],
            [9.7287034129374348, 50.658441377343557],
            [9.7294750599685251, 50.658526193830532],
            [9.7294963172499394, 50.658528488928404],
            [9.7296060931883073, 50.658540554049289],
            [9.730140858172831, 50.658599311790468],
            [9.730755640351715, 50.6586669002861],
            [9.7314183846241633, 50.658751730219507],
            [9.7320835260645389, 50.658836802131148],
            [9.7330855271094858, 50.658946983113239],
            [9.7336534348378247, 50.659006595447785],
            [9.7338008807306871, 50.659011773520916],
            [9.7339373498349797, 50.659011633625141],
            [9.7340669333860337, 50.659004522265171],
            [9.7343489039276552, 50.658974687169625],
            [9.7344384397268264, 50.658957935245688],
            [9.7345228834323869, 50.658967565343609],
            [9.7343681223476768, 50.658774548906401],
            [9.7355778402479363, 50.658705847164576],
            [9.736454648132705, 50.658501875321605],
            [9.7366229525204009, 50.658483913469603],
            [9.7368607417620545, 50.658486169383707],
            [9.7369071972420045, 50.658484886400103],
            [9.7371001180978194, 50.658479547504285],
            [9.7373435814049891, 50.658472818336762],
            [9.7375916954529593, 50.658581459646761],
            [9.7377308864356795, 50.658561881814443],
            [9.7380141244106255, 50.658522056383518],
            [9.7380951964604332, 50.658510643228624],
            [9.7382692271845634, 50.658486176637908],
            [9.7388741261191285, 50.658401107793118],
            [9.7389434104990418, 50.658391360572402],
            [9.7390308083999724, 50.658371075356982],
            [9.7390947355188278, 50.658356226914869],
            [9.7392363352214417, 50.658318034117336],
            [9.7392937305275105, 50.658286355799412],
            [9.7393567818012166, 50.658248175477141],
            [9.7395652298464128, 50.658149681595305],
            [9.7405585081902135, 50.65768071108414],
            [9.7408624039843925, 50.657537208982291],
            [9.7416699340707211, 50.657190682587157],
            [9.742148650720365, 50.656986655463413],
            [9.74222443385041, 50.656940604400212],
            [9.7422691060084574, 50.656913349338147],
            [9.7423094511097776, 50.656888918698989],
            [9.7423637581530542, 50.657032220826189],
            [9.7427975509139308, 50.657557293400643],
            [9.743417714792713, 50.65830870301626],
            [9.7434434753293164, 50.658339906890269],
            [9.7434993816189479, 50.658407646578397],
            [9.7435621360520344, 50.658482582054496],
            [9.743603968316954, 50.658534367657886],
            [9.7436369753918015, 50.658573798974111],
            [9.7443531976767801, 50.659448363650313],
            [9.7445923955331768, 50.659738889046871],
            [9.7446662386545064, 50.659829482136907],
            [9.745114268861645, 50.660376578183332],
            [9.7451964614988249, 50.660477001048456],
            [9.7469374892542859, 50.661703919007685],
            [9.7475126535292613, 50.662136283344047],
            [9.7476487897637156, 50.662239353188681],
            [9.7480491472268138, 50.662543856054121],
            [9.7480860697790224, 50.662571947553737],
            [9.748272858795433, 50.662713083377454],
            [9.7491713180498394, 50.663391952627705],
            [9.749533814288041, 50.663665154691429],
            [9.7496235247582277, 50.663733798150737],
            [9.7484547353203403, 50.664330587472087],
            [9.7482318089652367, 50.664444559413141],
            [9.7481765490637926, 50.664472694072835],
            [9.748841863722399, 50.664851638109816],
            [9.7489026915639787, 50.664886212703884],
            [9.7489134114991156, 50.664892978628536],
            [9.7492379395020787, 50.665199061747934],
            [9.7496432360214822, 50.665576902573136],
            [9.7496827270851814, 50.665614132084457],
            [9.7502563165928962, 50.666150840608623],
            [9.7503523070645457, 50.666432904901889],
            [9.7503597787270717, 50.666466662116292],
            [9.75039223930189, 50.666605145757991],
            [9.7508700370155719, 50.668675748752932],
            [9.7508967963765816, 50.668810590734068],
            [9.7510039148367547, 50.669363807519716],
            [9.7510748016858617, 50.669727681771377],
            [9.7510982499518217, 50.669849163195352],
            [9.7511272251391343, 50.66999685104274],
            [9.7512792572706246, 50.670789805506189],
            [9.7524418010826928, 50.671325169289751],
            [9.7523805255936562, 50.67136331814968],
            [9.7524576183111709, 50.671412534661627],
            [9.7524417053941566, 50.671460822751442],
            [9.7504659540085168, 50.671950666253295],
            [9.7497411703938184, 50.672130391616406],
            [9.7482665725094204, 50.672495601198065],
            [9.747917385984902, 50.672582027388565],
            [9.7476340026647197, 50.672652247459808],
            [9.7475165294326569, 50.672639079528452],
            [9.7468585000893828, 50.672268930801188],
            [9.7465038833329025, 50.672056760762906],
            [9.74623235131439, 50.671946103084522],
            [9.7457473614848578, 50.671791233428912],
            [9.7448047614759616, 50.671519093366385],
            [9.7446647267605613, 50.671510833111768],
            [9.7444629615439222, 50.671741420576716],
            [9.7436887976933289, 50.67229917221438],
            [9.7436765848929259, 50.672358308197168],
            [9.7434407142870416, 50.672292040525704],
            [9.7433917405360866, 50.672289744882285],
            [9.7433733363150647, 50.672289322676221],
            [9.7433261470292631, 50.672480665253389],
            [9.7433439651867229, 50.672587345341029],
            [9.7432612434094388, 50.673269131374099],
            [9.743307414315332, 50.673791950733587],
            [9.743311038977911, 50.67383237882391],
            [9.7433150865676303, 50.674088657889854],
            [9.7428296423926941, 50.674164930039964],
            [9.7427860047725243, 50.674134100827864],
            [9.7428889624874486, 50.67396183729489],
            [9.7429186301624515, 50.673798026759798],
            [9.7429536329330499, 50.673604621707369],
            [9.7428366807676934, 50.673634397040772],
            [9.7426398951649755, 50.673411262865493],
            [9.742338994072119, 50.673214188186741],
            [9.7419882178003476, 50.672984452351308],
            [9.7411505567018697, 50.672435713688166],
            [9.7410632164788762, 50.67244148507362],
            [9.7408334814613013, 50.672665013509956],
            [9.7404748793481186, 50.673013861480385],
            [9.7402720126734117, 50.673211218870051],
            [9.740211685604903, 50.673269958584839],
            [9.7401948330671679, 50.673286334225509],
            [9.7397884084209618, 50.673681591615576],
            [9.7397570312517452, 50.673746334623594],
            [9.739678883525583, 50.673706829049756],
            [9.739630953440324, 50.673681871449148],
            [9.7396270489301173, 50.673739605286357],
            [9.7373912252675243, 50.674630253354607],
            [9.7372733863706156, 50.674630009562065],
            [9.7371492427739756, 50.674580541047561],
            [9.7370636077184063, 50.674514740143309],
            [9.7368567539514395, 50.674238204666608],
            [9.7367157497607497, 50.674294107109077],
            [9.7367814209076542, 50.674359404949158],
            [9.7368676420006999, 50.674481661353965],
            [9.7369049978514433, 50.674536346350621],
            [9.7369359269844971, 50.674668664868044],
            [9.7369435782991864, 50.674803900445795],
            [9.7369052556451088, 50.675011974430788],
            [9.7368278621570266, 50.675219495005052],
            [9.736749496195527, 50.675373980074319],
          ],
        ],
      },
      properties: {
        FID: 14,
        OBJECTID: 35,
        gemarkungs: "Sargenzell",
        FIRST_geme: "Hünfeld",
        shape_Leng: 10102.8326356,
        shape_Area: 3913323.2777499999,
      },
    },
  ],
};

export const selectableArea = [
  [
    [9.87842559814453, 50.74275597758379],
    [9.857826232910156, 50.74166968624287],
    [9.847183227539062, 50.747100890945305],
    [9.845466613769531, 50.75057653133419],
    [9.834480285644531, 50.75492071895524],
    [9.826927185058594, 50.759264503404594],
    [9.815940856933594, 50.76056756012278],
    [9.80804443359375, 50.759264503404594],
    [9.798431396484375, 50.75796141040209],
    [9.789505004882812, 50.75340029914929],
    [9.776802062988281, 50.74949042143186],
    [9.766159057617188, 50.74471123849254],
    [9.762039184570312, 50.74036610336896],
    [9.756889343261719, 50.73558598902108],
    [9.745216369628906, 50.728632224449335],
    [9.74006652832031, 50.72189478059448],
    [9.732513427734375, 50.717547528462205],
    [9.725990295410156, 50.71341726540009],
    [9.724617004394531, 50.70885181434989],
    [9.722557067871094, 50.70254641286197],
    [9.719810485839844, 50.698849748780205],
    [9.704360961914062, 50.698197366045534],
    [9.693031311035156, 50.693847915895624],
    [9.682044982910156, 50.69363043280042],
    [9.667968749999998, 50.694935316246315],
    [9.658699035644531, 50.697327508282626],
    [9.647369384765625, 50.7001544870247],
    [9.638786315917969, 50.69971957830983],
    [9.627113342285156, 50.691673039566766],
    [9.621963500976562, 50.68841053600173],
    [9.615097045898438, 50.681014687913105],
    [9.609947204589844, 50.67340009717013],
    [9.607200622558594, 50.66883074972512],
    [9.601364135742188, 50.66404333726616],
    [9.599647521972656, 50.65229034614585],
    [9.596214294433594, 50.641840773740235],
    [9.602394104003906, 50.63204218884234],
    [9.607887268066406, 50.623548429520824],
    [9.627799987792969, 50.615924518530534],
    [9.643936157226562, 50.61222103011396],
    [9.672775268554686, 50.61004237130965],
    [9.683418273925781, 50.60503107315905],
    [9.700927734375, 50.60198045643598],
    [9.711227416992188, 50.60568475101319],
    [9.723243713378906, 50.59675036765668],
    [9.731826782226562, 50.58759631374095],
    [9.754142761230469, 50.583672602863736],
    [9.777488708496094, 50.58345460933937],
    [9.803581237792969, 50.58890414471025],
    [9.819717407226562, 50.594135105314166],
    [9.829673767089842, 50.60023715808726],
    [9.846839904785156, 50.60982449987999],
    [9.850273132324219, 50.61788507082542],
    [9.838600158691406, 50.627251026081666],
    [9.844093322753906, 50.633131021375156],
    [9.856452941894531, 50.63421982868815],
    [9.867095947265625, 50.63879254400661],
    [9.870529174804688, 50.645759635740774],
    [9.870529174804688, 50.65185499369506],
    [9.867782592773438, 50.65882014875858],
    [9.863319396972656, 50.66186707932284],
    [9.86846923828125, 50.66643710451342],
    [9.87396240234375, 50.67252977956561],
    [9.8712158203125, 50.67601095316726],
    [9.866409301757812, 50.67927431894458],
    [9.86846923828125, 50.68841053600173],
    [9.878082275390625, 50.69254300219879],
    [9.886322021484375, 50.69993703317144],
    [9.886322021484375, 50.709504048865405],
    [9.890098571777342, 50.719503841826715],
    [9.890098571777342, 50.72558962783843],
    [9.889068603515625, 50.7342822367947],
    [9.885292053222654, 50.74275597758379],
    [9.87842559814453, 50.74275597758379],
  ],
];

const colors = {
  white: "#FFFFFF",
  grey: "#59637d",
  lightestGrey: "#f5f5f5",
  lightGrey: "#cfd3dd",
  primary: "#0069b3",
  primaryDark: "#57839b",
  lightBlue: "#009ee0",
  primaryFifth: "rgba(0, 158, 227, 0.2)",
  secondary: "#ED805C",
  rejected: "#F44336",
  yellow: "#FF9000",
  green: "#0C8346",
  backgroundDark: "#202b3f",
  backgroundLight: "#ffffff",
  //backgroundLight: "#f5f5f5",
  paperDark: "#2f3a4e",
  drawerDark: "#293244",
  drawerLight: "#FFFFFF",
  appBarDark: "-webkit-linear-gradient(71deg, #202b3f 0%, #2f3a4e 98%)",
  appBarLight: "-webkit-linear-gradient(270deg, #15214d 18%, #0069b3 100%)",
  drawerMenuIconDark: "#f0407e",
  drawerMenuIconLight: "#f7c0d8",
  drawerMenuBackgroundDark: "#f7c3d5",
  drawerMenuBackgroundLight: "#7a2048",
  lightBlueLight: "#0057A5",
  lightBlueDark: "#308fe8",
  linkColorLight: "#0066CC",
  linkColorDark: "#00FFFF",
  mapGrey: "#EAEBEB",
  redirection: "#E8C300",
  darkGreen: "#3F4C36",
};

export default colors;
